import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Config } from '../.env.js';
import logo from '../logo192.png';
const BrandFormInternal = ({ campaign }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        companyName: '',
        brandName: [''],
        websiteLink: '',
        brandUSP: '',
        gstNumber: '',
        shortDescription: '',
        uploadGstAttachment: null,
        uploadBrandPhoto: [],

        // Add more fields as needed
    });
    const updateFormData = (name, value) => {
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateGstNumber = (gstNumber) => {
        const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        if (gstNumber && !gstRegex.test(gstNumber)) {
            setErrorMessage('Invalid GST number');
        } else {
            setErrorMessage('');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === 'gstNumber') {
            validateGstNumber(value);
        }
    };

    const [uploadGstAttachment, setUploadGstAttachment] = useState(null);
    // const [uploadBrandPhoto, setUploadBrandPhoto] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState('');

    const [brandCount, setBrandCount] = useState(1);
    const [gstNumber, setgstNumber] = useState("");
    const [brandName, setbrandName] = useState(Array.from({ length: brandCount }, () => null));
    const [websiteLink, setwebsiteLink] = useState(Array.from({ length: brandCount }, () => null));
    const [brandUSP, setbrandUSP] = useState(Array.from({ length: brandCount }, () => null));
    const [companyName, setCompanyName] = useState("");
    const [uploadBrandPhoto, setUploadBrandPhoto] = useState(Array.from({ length: brandCount }, () => null));
    const [shortDescription, setshortDescription] = useState(Array.from({ length: brandCount }, () => null));
    const [errorMessage, setErrorMessage] = useState('');

    const handleGstCertificateChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedExtensions = ["jpg", "jpeg", "png", "docx", "doc", "pdf"];
            const extension = file.name.split('.').pop().toLowerCase();
            if (!allowedExtensions.includes(extension)) {
                toast.error('Only JPG, JPEG, PNG, DOCX, PDF, and DOC files are allowed for GST certificate upload');
                e.target.value = null;
            } else {
                updateFormData('uploadGstAttachment', file);
                setSelectedFileName(file.name);
            }
        }
    };




    const handleBrandPhotoChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const allowedExtensions = ["jpg", "jpeg", "png"];
            const extension = file.name.split('.').pop().toLowerCase();
            const allowedSize = 25 * 1024 * 1024; // 25 MB in bytes
            if (!allowedExtensions.includes(extension)) {
                toast.error('Only JPG, JPEG, and PNG files are allowed for brand photo upload');
                e.target.value = null;
            } else if (file.size > allowedSize) {

                toast.error('File size exceeds the limit of 25 MB');
                e.target.value = null;
            } else {
                const newPhoto = file ? file.name : '';
                setUploadBrandPhoto(prevPhotos => [...prevPhotos.slice(0, index), newPhoto, ...prevPhotos.slice(index + 1)]);

                const fileLabel = document.getElementById(`file-label-${index}`);
                if (fileLabel) {
                    fileLabel.textContent = file ? file.name : "Choose a file...";
                }
            }
        }
    };


    const handleBrandNameChange = (e, index) => {
        const { value } = e.target;
        setFormData(prevState => {
            const brandName = [...prevState.brandName];
            brandName[index] = value;
            return { ...prevState, brandName };
        });
    };

    const handlewebChange = (e, index) => {
        const { value } = e.target;
        setFormData(prevState => {
            const websiteLink = [...prevState.websiteLink];
            websiteLink[index] = value;
            return { ...prevState, websiteLink };
        })
    }
    const handleUSPChange = (e, index) => {
        const { value } = e.target;
        setFormData(prevState => {
            const brandUSP = [...prevState.brandUSP];
            brandUSP[index] = value;
            return { ...prevState, brandUSP };
        })
    }

    const handleshortDescription = (e, index) => {
        const { value } = e.target;
        setFormData(prevState => {
            const shortDescription = [...prevState.shortDescription];
            shortDescription[index] = value;
            return { ...prevState, shortDescription };
        })
    }

    const isValidGstNumber = (gstNumber) => {
        const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        return gstRegex.test(gstNumber);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValidGstNumber(formData.gstNumber)) {
            setErrorMessage('Invalid GST number');
            return;
        }

        setErrorMessage('');
        try {
            // Prepare form data to send to the backend
            const data = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                // If the value is an array, convert it to a string
                if (Array.isArray(value)) {
                    data.append(key, value.join()); // Join array values into a single string
                } else {
                    data.append(key, value);
                }
            });
            data.append('uploadGstAttachment', uploadGstAttachment);
            data.append('uploadBrandPhoto', uploadBrandPhoto.join());

            // Make a POST request to the backend endpoint to save the form data
            const response = await fetch(`${Config.API_URL}/saveBrandData`, {
                method: 'POST',
                body: data,
            });

            if (!response.ok) {
                throw new Error('Failed to submit form');
            }
            const responseData = await response.json();
            const { brandName } = responseData;
            console.log(brandName);

            // Log individual brand names from formData
            // formData.brandName.forEach((brand, index) => {
            //     console.log(`Brand ${index + 1} Name: ${brand}`);
            // });
            // Handle successful form submission
            toast.success('Form submitted successfully');
            navigate('/gst', {
                replace: true,
                state: {
                    brandName: formData.brandName
                }
            });
            // Navigate to success page or any other appropriate page
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Error submitting form');
        }
    };



    const generateBrandFields = () => {
        const brandFields = [];
        for (let i = 1; i <= brandCount; i++) {
            brandFields.push(
                <div key={i}>
                    <h2 style={{marginTop:"20px", marginBottom:"20px"}}>Details of Your Brand {i}</h2>
                    <div className="field">
                        <label className="label has-text-left">Name of Brand {i}</label>
                        <div className="control">
                            <input
                                className="input is-rounded"
                                type="text"
                                value={formData.brandName[i]}
                                onChange={(e) => handleBrandNameChange(e, i)}
                                placeholder={` e.g. Himalaya`}
                            />

                        </div>
                    </div>
                    <div className="field">
                        <label className="label has-text-left">Website URL of Brand {i}</label>
                        <div className="control">
                            <input
                                className="input is-rounded"
                                type="text"
                                value={websiteLink[i - 1]}
                                onChange={(e) => handlewebChange(e, i)}
                                placeholder={`e.g. https://himalaya.com`}
                            />

                        </div>
                    </div>
                    <div className="field">
                        <label className="label has-text-left "> USP of Brand{i}</label>
                        <div className="control">
                            <textarea
                                className="textarea is-rounded" style={{ borderRadius: "20px" }}
                                value={brandUSP[i - 1]}
                                onChange={(e) => handleUSPChange(e, i)}
                                placeholder={`Write detailed USPs of your ${i} brand`}
                            ></textarea>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label has-text-left ">Upload Logo of Brand {i}</label>
                        <div className="file mt-4">
                            <label className="file-label">
                                <input
                                    className="file-input has-text-left"
                                    type="file"
                                    name={`uploadBrandPhoto${i}`}
                                    onChange={(e) => handleBrandPhotoChange(e, i)}
                                />
                                <span className="file-cta" style={{ borderRadius: "20px",paddingRight: "280px" }}>
                                    <span className="file-icon">
                                        <i className="fas fa-upload"></i>
                                    </span>
                                    <span id={`file-label-${i}`} className="file-label">Choose a file…{i}</span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label has-text-left ">Short description about the Brand or Company{i}</label>
                        <div className="control">
                            <textarea
                                className="textarea" style={{ borderRadius: "20px" }}
                                value={shortDescription[i - 1]} // Use shortDescription[i - 1] for the i-th brand
                                onChange={(e) => handleshortDescription(e, i)}
                                placeholder={`Short description of ${i} Brand`}
                            ></textarea>
                        </div>
                    </div>
                    {/* Add more fields as needed */}


                </div>
            );
        }
        return brandFields;
    };


    return (
        // <div className='imgfull'>

        <div className="container1 mt-3">
            <div className='container has-text-centered mt-5'>
                <figure className="image is-64x64" > {/* Adjust size as needed */}
                    <img src={logo} alt="Logo" />
                </figure>
            </div>
            <h1 className='title mt-5 ' >Registration as More Details About You</h1>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className='form'>
                    <div className='control'>

                        <div className='colomn '>
                            <div className='has-text-left  my-1 has-text-weight-bold'>
                                <label >Legal Name of Your company</label>
                            </div>
                            <input className='input is-rounded'
                                type="text"
                                placeholder="Company Name"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                            />


                        </div>

                    </div>

                </div>
                <div className="field mt-4" >
                    <label className="label has-text-left">Upload GST Certificate</label>
                    <div className="file mt-4">
                        <label className="file-label">
                            <input
                                className="file-input mr-2"
                                type="file"
                                name="uploadGstAttachment"
                                onChange={handleGstCertificateChange}
                            />
                            <span className="file-cta"style={{ borderRadius: "20px",paddingRight: "280px" }}>
                                <span className="file-icon">
                                    <i className="fas fa-upload"></i>
                                </span>
                                <span className="file-label">
                                    {selectedFileName || 'Choose a file…'}
                                </span>
                            </span>
                        </label>
                    </div>
                </div>




                <div className="field">
                    <label className="label has-text-left  ">Gst Number</label>
                    <div className="control">
                        <input className='input is-rounded'
                            type="text"
                            placeholder="Gst Number"
                            name='gstNumber'
                            value={formData.gstNumber}
                            onChange={handleChange}
                        />
                        {errorMessage && <p className="has-text-danger">{errorMessage}</p>}
                    </div>
                </div>
                <div className="field">
                    <div className="field ">
                        <label className="label has-text-left ">How Many Brands Do You Have</label>
                        <div className="control" >
                            <div className="select is-rounded">
                                <select style={{ paddingRight: "21.5em" }}
                                    value={brandCount}
                                    onChange={(e) => setBrandCount(Number(e.target.value))}
                                >
                                    {[...Array(10)].map((_, index) => (
                                        <option key={index} value={index + 1}> {index + 1} Brand </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* Render additional form sections for each brand */}
                    {generateBrandFields()}


                    <div className="control">
                        <button className="button is-primary mt-3 is-rounded is-fullwidth" type="submit">
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
        // </div>
    );
};

export default BrandFormInternal;