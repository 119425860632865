import React, { useState, useEffect } from 'react';
import { getInfluencerCampaigns, approveCampaignExecutive } from '../components/infApi'; // Import your API functions
import { useParams } from 'react-router-dom';

import { getInfluencerDeliverables } from '../components/api'; // Import the API function for approving influencers
import { approvedExecutive } from '../components/infApi';
import { Config } from '../.env.js';

import { getInfluencerConformation } from '../components/api'; // Import the API function for approving influencers
import { approvelExecutive } from '../components/infApi';

const InfluencersTablesExecutive = () => {
  const [influencerData, setInfluencerData] = useState({
    campaigns: [],
    deliverables: [],
    confirmations: []
  });
  const [error, setError] = useState(null);
  const { campaignName } = useParams();
  const API_URL = `${Config.API_URL}`;
  useEffect(() => {
    fetchData();
  }, [campaignName]);

  const fetchData = async () => {
    try {
      const campaignsResponse = await getInfluencerCampaigns();
      const deliverablesResponse = await getInfluencerDeliverables();
      const confirmationsResponse = await getInfluencerConformation();

      if (campaignsResponse.ok && deliverablesResponse.ok && confirmationsResponse.ok) {
        const campaignsData = await campaignsResponse.json();
        const deliverablesData = await deliverablesResponse.json();
        const confirmationsData = await confirmationsResponse.json();

        const filteredCampaigns = campaignsData.filter(campaign => {
            return campaign.isExecutive &&  campaign.campaignName === campaignName;
        });
        // filteredCampaigns = filteredCampaigns.filter(campaign => {
        //   if (campaign.isExecutive && !campaign.approvedbyExecutive) {
        //     return false;
        //   }
        //   if (campaign.isLead && !campaign.approvedbyLead) {
        //     return false;
        //   }

        //   return true;
        // });

        const filteredDeliverables = deliverablesData.filter(deliverables => deliverables.isexecutiveContent && deliverables.campaignName === campaignName)

        
        // filteredDeliverables = filteredDeliverables.filter(deliverables => {
        //   if (deliverables.isexecutiveContent) {
        //     if (!deliverables.approvedbyExecutive) {
        //       return false;
        //     }
        //   }
        //   if (deliverables.isleadContent) {
        //     if (!deliverables.approvedbyLead) {
        //       return false;
        //     }
        //   }
          // if (deliverables.isadminContent) {
          //   if (!deliverables.approvedbyAdmin) {
          //     return false;
          //   }
          // }
        //   return true;
        // });
        // Merge deliverables data into campaigns data if platform and platform username match

        const filteredConfirmations = confirmationsData.filter(conformation => conformation.isexecutiveContent && conformation.campaignName === campaignName)
        // filteredConfirmations = confirmationsData.filter(confirmation => {
        //   if (confirmation.isexecutiveContent) {
        //     if (!confirmation.approvedbyExecutive) {
        //       return true;
        //     }
        //   }
        //   if (confirmation.isleadContent) {
        //     if (!confirmation.approvedbyLead) {
        //       return true;
        //     }
        //   }
        //   return true;
        // });
        const mergedData = filteredCampaigns.map(campaign => {
          const deliverable = filteredDeliverables.find(d => d.FullName === campaign.FullName);
          const confirmation = filteredConfirmations.find(c => c.FullName === campaign.FullName);

          return {
            ...campaign,
            ...(deliverable ? { deliverable } : {}),
            ...(confirmation ? { confirmation } : {})
          };
        });

        setInfluencerData({
          campaigns: mergedData,
          deliverables: filteredDeliverables,
          confirmations: filteredConfirmations
        });
      } else {
        setError('Failed to fetch influencer data');
      }
    } catch (error) {
      setError('Error fetching influencer data: ' + error.message);
    }
  };

  // const handleSelect = async (campaignId) => {
  //   try {
  //     const response = await approveCampaignBrand(campaignId);
  //     if (response.ok) {
  //       const updatedCampaigns = influencerData.campaigns.map(campaign => {
  //         if (campaign._id === campaignId) {
  //           return { ...campaign, approvedbybrand: true };
  //         }
  //         return campaign;
  //       });
  //       setInfluencerData({ ...influencerData, campaigns: updatedCampaigns });
  //     } else {
  //       console.error('Failed to approve campaign:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error approving campaign:', error.message);
  //   }
  // };

  const handleApprove = async (campaignId) => {
    try {
      console.log('Campaign approved:', campaignId);
      // Call the API function to approve the campaign
      const response = await approveCampaignExecutive(campaignId);
      const influencerData = await response.json();

      if (response.ok) {
        // Display the message "Campaign approved"
        alert('Campaign selected successfully');
        // Update the campaigns state to reflect the change
        const updatedCampaigns = influencerData.campaigns.map(campaign => {
          if (campaign._id === campaignId) {
            return { ...campaign, approvedbybrand: true };
          }
          return campaign;
        });
        setInfluencerData({ ...influencerData, campaigns: updatedCampaigns });
      } else {
        console.error('Failed to approve campaign:', response.statusText);
      }
    } catch (error) {
      console.error('Error approving campaign:', error.message);
    }
  };

  // const handleReject = async (campaignId) => {
  //   try {
  //     const remarks = prompt('Campaign rejected. Please provide remarks:');
  //     if (remarks === null) {
  //       return;
  //     }
  //     const response = await rejectCampaignBrand(campaignId, remarks);
  //     if (response.ok) {
  //       console.log('Campaign rejected successfully');
  //     } else {
  //       console.error('Failed to reject campaign:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error rejecting campaign:', error.message);
  //   }
  // };

  const handleApproveDeliverable = async (influencerId) => {
    try {
      const response = await approvedExecutive(influencerId);
      if (response.ok) {
        const updatedDeliverables = influencerData.deliverables.map(deliverable => {
          if (deliverable._id === influencerId) {
            return { ...deliverable, approvedbyBrand: true };
          }
          return deliverable;
        });
        setInfluencerData({ ...influencerData, deliverables: updatedDeliverables });
        window.alert('Approved successfully');
      } else {
        console.error('Failed to approve deliverable:', response.statusText);
      }
    } catch (error) {
      console.error('Error approving deliverable:', error.message);
    }
  };

  const handleApproveConformation = async (influencerId) => {
    try {
      const response = await approvelExecutive(influencerId);
      if (response.ok) {
        const updatedConfirmations = influencerData.confirmations.map(confirmation => {
          if (confirmation._id === influencerId) {
            return { ...confirmation, approvedbyBrand: true };
          }
          return confirmation;
        });
        setInfluencerData({ ...influencerData, confirmations: updatedConfirmations });
        window.alert('Approved successfully');
      } else {
        console.error('Failed to approve confirmation:', response.statusText);
      }
    } catch (error) {
      console.error('Error approving confirmation:', error.message);
    }
  };

  return (
    <div className="container" style={{ overflowY: "auto" }} >
      <h2>Influencer Data</h2>
      {error && <p>Error: {error}</p>}
      <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable is-responsive">
        <thead>
          <tr>
            <th className="customStyle">Full Name </th>
            <th className="customStyle">Phone Number</th>
            <th className="customStyle">WhatsappNumber</th>
            <th className="customStyle">Email</th>
            <th className="customStyle">Gender</th>
            <th className="customStyle">Age</th>
            <th className="customStyle">Selected Category</th>
            <th className="customStyle">Languages</th>
            <th className="customStyle">Celebrity Type</th>
            <th className="customStyle">Deal Type</th>
            <th className="customStyle">Campaign Type</th>
            <th className='customStyle'>Social Media Platform</th>
            <th className="customStyle">Action</th>
            {/* <th className="customStyle">Full Name</th> */}
            <th className="customStyle">Uploaded Videos</th>
            <th className="customStyle">Uploaded Raw Videos</th>
            <th className="customStyle">Action</th>
            {/* <th className="customStyle">Full Name</th> */}
            <th className="customStyle">Pincode</th>
            <th className="customStyle">Full Address</th>
            <th className="customStyle">Landmark</th>
            <th className="customStyle">City</th>
            <th className="customStyle">State</th>
            <th className="customStyle">Revert or pyment </th>

            <th className="customStyle">Action</th>
          </tr>
        </thead>
        <tbody>
          {influencerData.campaigns.map(influencer => (
            <tr key={influencer._id}>
              <th className="customStyle">{influencer.FullName}</th>
              {influencer.confirmation ? (
                <>
                  <td className="customStyle">{influencer.PhoneNumber}</td>
                  <td className="customStyle">{influencer.WhatsappNumber}</td>
                  <td className="customStyle">{influencer.Email}</td>
                </>
              ) : (
                <>
                  <td className="customStyle">Hidden</td>
                  <td className="customStyle">Hidden</td>
                  <td className="customStyle">Hidden</td>
                </>
              )}
              {/* <td className="customStyle">{influencer.PhoneNumber}</td>
              <td className="customStyle">{influencer.WhatsappNumber}</td> 
              <td className="customStyle">{influencer.Email}</td> */}
              <td className="customStyle">{influencer.Gender}</td>
              <td className="customStyle">{influencer.Age}</td>
              <td className="customStyle">{influencer.selectedCategory}</td>
              <td className="customStyle">{influencer.Languages}</td>
              <td className="customStyle">{influencer.CelebrityType}</td>
              <td className="customStyle">{influencer.DealType}</td>
              <td className="customStyle">{influencer.CampaignType}</td>
              <td className="customStyle">
                {influencer.platforms && influencer.platforms.length > 0 ? (
                  influencer.platforms.map((platform, index) => (
                    <div key={index}>
                      <strong>{platform.platformName}: </strong>
                      (username: {platform.username}) (Followers: {platform.followerRange})
                    </div>
                  ))
                ) : (
                  <span>No platforms selected</span>
                )}
              </td>
              <td className="customStyle">
                <button onClick={() => handleApprove(influencer._id)} className="button is-primary is-rounded" 
                >Approve</button>
                {/* <button onClick={() => handleReject(influencer._id)} className="button is-primary">Reject</button> */}
              </td>
              {/* <td className="customStyle">{influencer.FullName}</td> */}
              <td className="customStyle">
                {influencer.infDeliverables && influencer.infDeliverables.uploadVideoPath.map((video, index) => (
                  <span key={index}>
                    <a href={`${API_URL}/${video}`} target="_blank" rel="noopener noreferrer">Video {index + 1}</a>
                    {index !== influencer.infDeliverables.uploadVideoPath.length - 1 && ', '}
                  </span>
                ))}
              </td>
              <td className="customStyle">
                {influencer.infDeliverables && influencer.infDeliverables.uploadRawVideoPath.map((video, index) => (
                  <span key={index}>
                    <a href={`${API_URL}/${video}`} target="_blank" rel="noopener noreferrer">Video {index + 1}</a>
                    {index !== influencer.infDeliverables.uploadRawVideoPath.length - 1 && ', '}
                  </span>
                ))}
              </td>
              <td className="customStyle">
                <button onClick={() => handleApproveDeliverable(influencer.infDeliverables._id)} className="button is-primary is-rounded">Approve</button>
              </td>
              {/* <td className="customStyle">{influencer.confirmation?.FullName}</td> */}
              <td className="customStyle">{influencer.confirmation?.PinCode}</td>
              <td className="customStyle">{influencer.confirmation?.FullAddress}</td>
              <td className="customStyle">{influencer.confirmation?.Landmark}</td>
              <td className="customStyle">{influencer.confirmation?.State}</td>
              <td className="customStyle">{influencer.confirmation?.City}</td>
              <td className="customStyle">{influencer.confirmation?.InstagramUsername}</td>



              <td className="customStyle">

                <button onClick={() => handleApproveConformation(influencer.confirmation._id)} className="button is-primary is-rounded">Approve</button>

                {/* // ) : (
                //   <span>No confirmation</span>
                )} */}
              </td>
            </tr>
          ))}
        </tbody>

      </table>
    </div>
  );
};

export default InfluencersTablesExecutive
