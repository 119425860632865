import React, { useState, useEffect } from 'react';
import { getBrandCampaigns } from '../components/api';
import { useNavigate } from 'react-router-dom';
import { createCampaign } from '../components/campaignApi';
import {updateCampaign} from "../components/apiEdit";
const BrandDashboard2 = () => {
    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState([]);
    const [editingCampaign, setEditingCampaign] = useState(null);
    const [approvedCampaigns, setApprovedCampaigns] = useState([]); // State to hold approved campaigns
    const [prevApprovedCampaigns, setPrevApprovedCampaigns] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchBrandCampaigns();
        // fetchAllCampaigns();
    }, []);

    const fetchBrandCampaigns = async () => {
        try {
            const response = await getBrandCampaigns();
            const data = await response.json();
            if (response.ok) {
                setCampaigns(data);
            } else {
                setError(data.message || 'Failed to fetch brand campaigns');
            }
        } catch (error) {
            setError('Error fetching brand campaigns: ' + error.message);
        }
    };





    const handleApprove = async (campaignId) => {
        try {
            const approved = campaigns.find(campaign => campaign._id === campaignId);
            setApprovedCampaigns(campaignId); // Update the approved campaign ID
            await createCampaign(approved)
            alert('Campaign approved successfully'); // Show alert for successful approval
        } catch (error) {
            setError('Error approving campaign: ' + error.message);
            console.error('Error approving campaign:', error);
        }
    };
    const handleAssign = (campaignId) => {
        // Logic to handle approval action for the campaign with the given ID
        console.log('Campaign approved:', campaignId);
        // You can perform additional actions here, such as sending a request to your backend to update the approval status
    };
    const constructViewPhotoLink = (filename) => {
        return `/uploads/${encodeURIComponent(filename)}`;
    };



    const handleEdit = (campaign) => {
        // Copy the campaign object to avoid mutating the original data
        const editedCampaign = { ...campaign };
        setEditingCampaign(editedCampaign);
    };


    const handleUpdate = async () => {
        try {
            const { _id, ...updatedData } = editingCampaign; // Remove _id field before updating
            await updateCampaign(_id, updatedData); // Send updated data to backend
            // If update successful, reset editingCampaign state and fetch updated campaigns
            setEditingCampaign(null);
            fetchBrandCampaigns();
            alert('Campaign updated successfully');
        } catch (error) {
            setError('Error updating campaign: ' + error.message);
            console.error('Error updating campaign:', error);
        }
    };

    const handleCancelEdit = () => {
        setEditingCampaign(null); // Reset editingCampaign state on cancel
    };
        return (
        <div className="container mt-3" >

            {error && <p>Error: {error}</p>} {/* Display error message if there's an error */}

            <table className="table is-fullwidth">
                <thead>
                    <tr>
                        <th className="customStyle">Brand Name</th>
                        <th className="customStyle">Your Name</th>
                        <th className="customStyle">Contact</th>
                        <th className="customStyle">Brand Info</th>
                        <th className="customStyle">Email</th>
                        <th className="customStyle">Followers Critera</th>
                        <th className="customStyle">Deliverables</th>
                        <th className="customStyle">Give Product Worth Rs</th>
                        <th className="customStyle">Short Description About the brand or company</th>
                        <th className="customStyle">Upload Brand photo</th>
                        <th className="customStyle">Brand USP</th>
                        <th className="customStyle">Action</th>
                        <th className="customStyle">Edit</th> {/* Add column for action buttons */}
                        {/* Add more table headers as needed */}
                    </tr>
                </thead>
                <tbody>
                    {campaigns.map(campaign => (
                        <tr key={campaign._id}>
                            <td className="customStyle">{campaign.brandName}</td>
                            <td className="customStyle">{campaign.yourName}</td>
                            <td className="customStyle">{campaign.contact}</td>
                            <td className="customStyle">{campaign.brandInfo}</td>
                            <td className="customStyle">{campaign.email}</td>
                            <td className="customStyle">{campaign.followersCriteria}</td>
                            <td className="customStyle">{campaign.deliverables}</td>
                            <td className="customStyle">{campaign.giveproductsworthRs}</td>
                            <td className="customStyle">{campaign.shortdescriptionaboutthebrandorcompany}</td>
                            <td className="customStyle">
                                <a
                                    href={constructViewPhotoLink(campaign.uploadBrandPhoto)}
                                    // target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    View Photo
                                </a>
                            </td>

                            <td className="customStyle">{campaign.brandUSP}</td>
                            <td className="customStyle">
                                <button onClick={() => handleApprove(campaign._id)} className="button is-primary">Approve</button>

                            </td>
                            <td className="customStyle">
                                {/* Edit button */}
                                <button onClick={() => handleEdit(campaign)} className="button is-primary">Edit</button>
                            </td>

                            {/* Add more table cells to display other campaign data */}
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Edit campaign form */}
            {editingCampaign && (
                <div className='container'>
                    <div className='subtitle'>
                        <h2>Edit Campaign</h2>
                    </div>
                    {/* Input fields for editing campaign attributes */}
                    <div className='field'>
                        <label className='label'>Brand Name:</label>
                        <div className='control'>
                            <input className='input'
                                type="text"
                                id="brandName"
                                value={editingCampaign.brandName}
                                onChange={e => setEditingCampaign({ ...editingCampaign, brandName: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Your Name:</label>
                        <div className='control'>
                            <input className='input'
                                type="text"
                                id="yourName"
                                value={editingCampaign.yourName}
                                onChange={e => setEditingCampaign({ ...editingCampaign, yourName: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Contact:</label>
                        <div className='control'>
                            <input className='input'
                                type="text"
                                id="contact"
                                value={editingCampaign.contact}
                                onChange={e => setEditingCampaign({ ...editingCampaign, contact: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'> BrandInfo:</label>
                        <div className='control'>
                            <input className='input'
                                type="text"
                                id="brandinfo"
                                value={editingCampaign.brandInfo}
                                onChange={e => setEditingCampaign({ ...editingCampaign, brandInfo: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Email:</label>
                        <div className='control'>
                            <input className='input'
                                type="text"
                                id="email"
                                value={editingCampaign.email}
                                onChange={e => setEditingCampaign({ ...editingCampaign, email: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'> Followers Criteria:</label>
                        <div className='control'>
                            <input className='input'
                                type="text"
                                id=" followersCriteria"
                                value={editingCampaign.followersCriteria}
                                onChange={e => setEditingCampaign({ ...editingCampaign, followersCriteria: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'> Deliverables:</label>
                        <div className='control'>
                            <input className='input'
                                type="text"
                                id="deliverables"
                                value={editingCampaign.deliverables}
                                onChange={e => setEditingCampaign({ ...editingCampaign, deliverables: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Give Products Worth Rs:</label>
                        <div className='control'>
                            <input className='input'
                                type="text"
                                id="giveproductsworthRs"
                                value={editingCampaign.giveproductsworthRs}
                                onChange={e => setEditingCampaign({ ...editingCampaign, giveproductsworthRs: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className='field'>
                        <label className='field'>Upload Brand Photo:</label>
                        <div className='control'>
                            <input className='input'
                                type="text"
                                id="uploadBrandPhoto"
                                value={editingCampaign.uploadBrandPhoto}
                                onChange={e => setEditingCampaign({ ...editingCampaign, uploadBrandPhoto: e.target.value })}
                            />
                        </div>

                    </div>
                    <div>
                        <label className='label'>Reupload Brand Photo:</label>
                        <input
                            type="file"
                            id="uploadBrandPhoto"
                            name='uploadBrandPhoto'
                            onChange={e => setEditingCampaign({ ...editingCampaign, uploadBrandPhoto: e.target.value })}
                        />
                    </div>
                    <div className='field'>
                        <label className='label'> Brand USP :</label>
                        <div className='control'>
                            <input className='input'
                                type="text"
                                id=" brandUSP"
                                value={editingCampaign.brandUSP}
                                onChange={e => setEditingCampaign({ ...editingCampaign, brandUSP: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Short Description About the Brand or Company:</label>
                        <div className='control'>
                            <textarea
                                className="textarea"
                                type="text"
                                id="shortdescriptionaboutthebrandorcompany"
                                value={editingCampaign.shortdescriptionaboutthebrandorcompany}
                                onChange={e => setEditingCampaign({ ...editingCampaign, shortdescriptionaboutthebrandorcompany: e.target.value })}
                            />
                        </div>
                    </div>
                    

                    {/* Add similar input fields for other campaign attributes */}

                    {/* Buttons for updating and canceling */}
                    <div className='field is-grouped'>
                        <div className='control'>
                            <button onClick={handleUpdate}>Update</button>
                        </div>
                        <div className='control'>
                            <button onClick={handleCancelEdit}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}


        </div>

    );
};

export default BrandDashboard2;
