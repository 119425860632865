import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Config } from '../.env.js';
import Select from 'react-select';
import { toast } from 'react-toastify';
const ApplicationPage = ({ campaign }) => {
    const navigate = useNavigate();
    const [FullName, setFullName] = useState();
    // const [ExactFollowers, setExactFollowers] = useState("");
    const [otherCategory, setOtherCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [WhatsappNumber, setWhatsappNumber] = useState("");
    const [UserName, setUserName] = useState("")
    const [Email, setEmail] = useState("");
    const [Age, setAge] = useState("");
    const [CampaignType, setSelectedCampaign] = useState("");
    const [Gender, setSelectedGender] = useState("");
    const [CelebrityType, setSelectedCelebrityType] = useState("");
    const [DealType, setSelectedDealType] = useState('');
    const [Languages, setSelectedLanguages] = useState('');

    // State for selected platforms
    const [selectedPlatforms, setSelectedPlatforms] = useState({
        YouTube: false,
        Instagram: false,
        Moj: false,
        Chingari: false,
        Facebook: false,
        TikTok: false,
        MxTakaTak: false,
        Linkedin: false,
        Quora: false,
        Snapchat: false,
        Koo: false,
        Tumblr: false,
        Trell: false,
        Rizzle: false,
        Lumi: false,
        Kutumb: false,
        Roposo: false,
        '9GAG': false,
        Whatsapp: false,
        Offline: false,
        Sharechat: false,
        X: false,
        Own: false
    });

    // State for platform details
    const [platformDetails, setPlatformDetails] = useState({});

    const handlePlatformChange = (platform) => {
        if (platform === 'GAG9') { // Change 'GAG9' to '9GAG'
            platform = '9GAG';
        }
        setSelectedPlatforms({
            ...selectedPlatforms,
            [platform]: !selectedPlatforms[platform]
        });
    };

    const handlePlatformDetailChange = (platform, field, value) => {
        setPlatformDetails({
            ...platformDetails,
            [platform]: {
                ...platformDetails[platform],
                [field]: value
            }
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if any required field is empty
        if (
            !FullName ||
            !selectedCategory ||
            !PhoneNumber ||
            !WhatsappNumber ||

            !Email ||
            !Age ||
            !CampaignType ||
            !Gender ||
            !CelebrityType ||
            !DealType ||
            Languages.length === 0 ||
            Object.values(selectedPlatforms).every(value => !value)
        ) {
            // Show warning toast if any required field is empty
            toast.warning('Please fill in all the required fields.', { autoClose: 3000 });
            return;
        }

        const platforms = Object.keys(selectedPlatforms).filter(platform => selectedPlatforms[platform]).map(platform => ({
            platformName: platform,
            username: platformDetails[platform]?.username || '',
            followerRange: platformDetails[platform]?.followerRange || ''
        }));

        const payload = {
            FullName,
            //   ExactFollowers,
            selectedCategory,
            PhoneNumber,
            WhatsappNumber,
            // UserName,
            Email,
            Gender,
            Age,
            CampaignType,
            DealType,
            Languages: Languages.map(lang => lang.value),
            platforms,
            CelebrityType,
            campaign: {
                isExecutive: campaign.isExecutive,
                isLead: campaign.isLead,
                isAdmin: campaign.isAdmin,
                isBrand: campaign.isBrand,
                brandName: campaign.brandName,
                campaignName : campaign.campaignName
            }
        };

        console.log('Submitting payload:', payload);

        try {
            const response = await axios.post(`${Config.API_URL}/submit`, payload);
            console.log(response.data);
            navigate('/success-page');
        } catch (error) {
            console.error('Error submitting application:', error);
        }
    };
    const languageOptions = [
        { value: 'English', label: 'English' },
        { value: 'Hindi', label: 'Hindi' },
        { value: 'Marathi', label: 'Marathi' },
        { value: 'Gujrati', label: 'Gujrati' },
        { value: 'Kannada', label: 'Kannada' },
        { value: 'Manipuri', label: 'Manipuri' },

        { label: 'Mizo', value: 'Mizo' },
        { label: 'Kokborok', value: 'Kokborok' },
        { label: 'Assamese', value: 'Assamese' },
        { label: 'Bhojpuri', value: 'Bhojpuri' },
        { label: 'Konkani', value: 'Konkani' },
        { label: 'Dogri', value: 'Dogri' },
        { label: 'Gujarati', value: 'Gujarati' },
        { label: 'Marathi', value: 'Marathi' },
        { label: 'Sindhi', value: 'Sindhi' },
        { label: 'Nepali', value: 'Nepali' },
        { label: 'Urdu', value: 'Urdu' },
        { label: 'Maithli', value: 'Maithli' },
        { label: 'Malayalam', value: 'Malayalam' },
        { label: 'Odia', value: 'Odia' },
        { label: 'Sanskrit', value: 'Sanskrit' },
        { label: 'Italian', value: 'Italian' },
        { label: 'Hindi', value: 'Hindi' },
        { label: 'Bengali', value: 'Bengali' },
        { label: 'Tamil', value: 'Tamil' },
        { label: 'Telugu', value: 'Telugu' },
        { label: 'Rajasthani', value: 'Rajasthani' },
        { label: 'Chhattisgarhi', value: 'Chhattisgarhi' },
        { label: 'Magahi', value: 'Magahi' },
        { label: 'Khortha/Khotta', value: 'Khortha/Khotta' },
        { label: 'Marwari', value: 'Marwari' },
        { label: 'Bundeli/Bundel khandi', value: 'Bundeli/Bundel khandi' },
        { label: 'Lamani/Lambadi', value: 'Lamani/Lambadi' },
        { label: 'Wagdi', value: 'Wagdi' },
        { label: 'Awadhi', value: 'Awadhi' },
        { label: 'Mewari', value: 'Mewari' },
        { label: 'Sadan/Sadri', value: 'Sadan/Sadri' },
        { label: 'Malvi', value: 'Malvi' },
        { label: 'Sambalpuri', value: 'Sambalpuri' },
        { label: 'Gondi', value: 'Gondi' },
        { label: 'Hara/Harauti', value: 'Hara/Harauti' },
        { label: 'Bhili/Bhilodi', value: 'Bhili/Bhilodi' },
        { label: 'Pahari', value: 'Pahari' },
        { label: 'Surgujia', value: 'Surgujia' },
        { label: 'Tulu', value: 'Tulu' },
        { label: 'Kurukh/Oraon', value: 'Kurukh/Oraon' },
        { label: 'Kumauni', value: 'Kumauni' },

        { label: 'Bagri', value: 'Bagri' },
        { label: 'Ahirani', value: 'Ahirani' },
        { label: 'Banjari', value: 'Banjari' },
        { label: 'Brajbhasha', value: 'Brajbhasha' },
        { label: 'Dhundhari', value: 'Dhundhari' },
        { label: 'Bodo/Boro', value: 'Bodo/Boro' },
        { label: 'Ho', value: 'Ho' },
        { label: 'Gojri/Gujjari/Gujar', value: 'Gojri/Gujjari/Gujar' },
        { label: 'Mundari', value: 'Mundari' },
        { label: 'Garo', value: 'Garo' },
        { label: 'Kangri', value: 'Kangri' },
        { label: 'Khasi', value: 'Khasi' },
        { label: 'Kachchhi', value: 'Kachchhi' },
        { label: 'Surjapuri', value: 'Surjapuri' },
        { label: 'Nimadi', value: 'Nimadi' },
        { label: 'Garhwali', value: 'Garhwali' },

        { label: 'Santali', value: 'Santali' },
        { label: 'Kui', value: 'Kui' },
        { label: 'Lushai/Mizo', value: 'Lushai/Mizo' },
        { label: 'Halabi', value: 'Halabi' },
        { label: 'Korku', value: 'Korku' },
        { label: 'Miri/Mishing', value: 'Miri/Mishing' },
        { label: 'Munda', value: 'Munda' },
        { label: 'Karbi/Mikir', value: 'Karbi/Mikir' },
        { label: 'Koya', value: 'Koya' },
        { label: 'Ao', value: 'Ao' },
        { label: 'Savara', value: 'Savara' },
        { label: 'Konyak', value: 'Konyak' },
        { label: 'Kharia', value: 'Kharia' },
        { label: 'Malto', value: 'Malto' },

        { label: 'Nissi/Dafla', value: 'Nissi/Dafla' },
        { label: 'Kashmiri', value: 'Kashmiri' },
        { label: 'Adi', value: 'Adi' },
        { label: 'Thado', value: 'Thado' },
        { label: 'Lotha', value: 'Lotha' },
        { label: 'Coorgi/Kodagu', value: 'Coorgi/Kodagu' },
        { label: 'Rabha', value: 'Rabha' },
        { label: 'Tangkhul', value: 'Tangkhul' },
        { label: 'Kisan', value: 'Kisan' },
        { label: 'Angami', value: 'Angami' },
        { label: 'Phom', value: 'Phom' },
        { label: 'Kolami', value: 'Kolami' },
        { label: 'Khond/Kondh', value: 'Khond/Kondh' },
        { label: 'Dimasa', value: 'Dimasa' },
        { label: 'Ladakhi', value: 'Ladakhi' },
        { label: 'Sema', value: 'Sema' },

        { value: 'Other', label: 'Other' }
    ];

    const customStyles1 = {
        control: (provided) => ({
            ...provided,
            borderRadius: '20px',
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '20px',
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: state.isFocused ? '20px' : '0',
        }),
        multiValue: (provided) => ({
            ...provided,
            borderRadius: '20px',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            borderRadius: '20px',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            borderRadius: '20px',
        }),
    };
    const handleDealType = (event) => {
        const selectedValue = event.target.value;
        setSelectedDealType(selectedValue);
    }
    const handleCelebrityType = (event) => {
        const selectedValue = event.target.value;
        setSelectedCelebrityType(selectedValue);
        console.log('Selected Celebrity Type:', selectedValue); 

    }
    const handleCampaign = (event) => {
        const selectedValue = event.target.value;
        setSelectedCampaign(selectedValue);
    }

    return (
        <div className='container has-text-centered mt-3'>
            <h1 className='title mt-5'>Influencer Form: {campaign.campaignName}</h1>
            <div className='my-2 has-text-left'>
                <strong>Please don't use @ or put spaces or add your profile link. Only usernames are accepted. For Example: virat.kohli, norafatehi etc.</strong>
            </div>

            {/* <div className='field '>
                <label className="label has-text-left ml-3">Please Enter Exact Followers</label>
                <div className="control">
                    <div className='colomn'>
                        <input className='input is-rounded'
                            type="number"
                            placeholder="Exact Followers"
                            value={ExactFollowers}
                            onChange={(e) => setExactFollowers(e.target.value)}
                        />
                    </div>
                </div>
            </div> */}
            <div className='field '>
                <label className="label has-text-left ml-3">Your Full Name</label>
                <div className="control">
                    <div className='colomn'>
                        <input className="input is-rounded"
                            type="text"
                            placeholder="User Name"
                            value={FullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        {FullName && !/^[a-zA-Z_ ]{3,30}$/.test(FullName) && (
                            <small className="has-text-danger">
                                Please Enter valid Username
                            </small>
                        )}
                    </div>
                </div>
            </div>

            <div className='field '>
                <label className="label has-text-left ml-3">Your Mobile Number (Calling number to contact)</label>
                <div className="control">
                    <div className='colomn'>
                        <input className="input is-rounded"
                            type="text"
                            placeholder="Phone Number"
                            value={PhoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        {PhoneNumber && !/^[0-9]{1,10}$/.test(PhoneNumber) && (
                            <small className="has-text-danger">
                                Please Enter valid Mobile number
                            </small>
                        )}
                    </div>
                </div>
            </div>

            <div className='field '>
                <label className="label has-text-left ml-3">Your WhatsApp no (Should be available)</label>
                <div className="control">
                    <div className='colomn'>
                        <input className="input is-rounded"
                            type="text"
                            placeholder="Whatsapp Number"
                            value={WhatsappNumber}
                            onChange={(e) => setWhatsappNumber(e.target.value)}
                        />
                        {WhatsappNumber && !/^[0-9]{1,10}$/.test(WhatsappNumber) && (
                            <small className="has-text-danger">
                                Please Enter valid Whatsapp Number
                            </small>
                        )}
                    </div>
                </div>
            </div>

            <div className='field '>
                <label className="label has-text-left ml-3">Your Email ID</label>
                <div className="control">
                    <div className='colomn'>
                        <input className="input is-rounded"
                            type="text"
                            placeholder="Email ID"
                            value={Email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {Email && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email) && (
                            <small className="has-text-danger">
                                Please Enter valid Email
                            </small>
                        )}
                    </div>
                </div>
            </div>


            <div className='field'>
                <div className="control">
                    <div className='colomn'>
                        <div className='my-2 has-text-left'>
                            <strong> Your Gender? </strong>
                        </div>
                        <div className="select is-rounded is-fullwidth">
                            <select
                                value={Gender}
                                onChange={(e) => setSelectedGender(e.target.value)}
                                className='dropdown is-rounded'
                            >
                                <option>Select</option>
                                <option>Male</option>
                                <option>Female</option>
                                <option>other</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className='field '>
                <label className="label has-text-left ml-3">Your Age</label>
                <div className="control">
                    <div className='colomn'>
                        <input className="input is-rounded"
                            type="number"
                            placeholder="Your Age"
                            value={Age}
                            onChange={(e) => setAge(e.target.value)}
                        />
                        {Age && (Age < 18 || Age > 65) && (
                            <small className="has-text-danger">
                                Please Enter Age between 18 and 65
                            </small>
                        )}
                    </div>
                </div>
            </div>

            <div className='field'>
                <div className="control">
                    <div className='colomn'>
                        <div className='my-2 has-text-left'>
                            <strong>What is Your Influencer Category? </strong>
                        </div>
                        <div className="select is-rounded is-fullwidth">
                            <select
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className='dropdown is-rounded'
                            >
                                <option>Select</option>
                                <option>Skincare</option>
                                <option>Dermatologist</option>
                                <option>Beauty and Makeup</option>
                                <option>Hair Care</option>
                                <option>Body Care</option>
                                <option>Personal Care</option>
                                <option>Health & Wellness</option>
                                <option>Fashion</option>
                                <option>LifeStyle</option>
                                <option>Travel</option>
                                <option>other</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>


            {selectedCategory === 'other' && (
                <div className="field">
                    <label className="label has-text-left ml-3">Other Category</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            placeholder="Enter other category"
                            value={otherCategory}
                            onChange={(e) => setOtherCategory(e.target.value)}
                        />
                    </div>
                </div>
            )}

            <div className='field'>
                <div className="control">
                    <div className='colomn'>
                        <div className='my-2 has-text-left'>
                            <strong>Which Languages do you Speak?</strong>
                        </div>
                        <Select
                            isMulti
                            value={Languages}
                            onChange={setSelectedLanguages}
                            options={languageOptions}
                            className="basic-multi-select is-rounded "
                            classNamePrefix="select"
                            styles={customStyles1}

                        />
                    </div>
                </div>
            </div>


            <div className='field'>
                <div className="control">
                    <div className='colomn'>
                        <div className='my-2 has-text-left'>
                            <label className="label has-text-left ">Are you a TV/movies/OTT celebrity</label>
                        </div>
                        <div className="select is-rounded is-fullwidth">
                            <select
                                value={CelebrityType}
                                onChange={handleCelebrityType}
                                className='dropdown is-rounded'
                            >
                                <option  >Select</option>
                                <option value={"TV celebrity"} >TV celebrity</option>
                                <option value={"movies celebrity"} >movies celebrity</option>
                                <option value={"OTT celebrity"}>OTT celebrity</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className='field'>
                <div className="control">
                    <div className='colomn'>
                        <label className="label has-text-left ml-3">What kind of deal do you participate in ?</label>
                        <div className="select  is-rounded is-fullwidth">

                            <select value={DealType}
                                onChange={handleDealType}>
                                <option >Select</option>
                                <option >All Marketplace Review</option>
                                <option >Amazon Reviews</option>
                                <option >Flipkart Reviews</option>

                            </select>

                        </div>

                    </div>
                </div>
            </div>

            <div className='field'>
                <label className="label has-text-left ">What Type of Campaigns are you Interested in?</label>
                <div className="control">
                    <div className='colomn'>

                        <div className="select  is-rounded is-fullwidth">

                            <select value={CampaignType}
                                onChange={handleCampaign}>
                                <option >Select</option>

                                <option >Barter Campaign</option>
                                <option >Paid</option>
                                <option >review</option>
                                <option >ad-video shoot</option>
                            </select>

                        </div>

                    </div>
                </div>
            </div>


            <div className='field'>
                <div className="control">
                    <div className='colomn'>
                        <div className='my-2 has-text-left'>
                            <strong>Which Social Media Platforms do you Use?</strong>
                        </div>
                        <div className='checkbox-group'>
                            {Object.keys(selectedPlatforms).map((platform) => (
                                <div key={platform} className='checkbox-item'>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={selectedPlatforms[platform]}
                                            onChange={() => handlePlatformChange(platform)}
                                        />
                                        {platform}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Render platform details inputs after all checkboxes */}
            {Object.keys(selectedPlatforms).map((platform) => (
                selectedPlatforms[platform] && (
                    <div className='field platform-details' key={`${platform}-details`}>
                        <div className="control">
                            <div className='colomn'>
                                <label className='label has-text-left ml-3'>
                                    Please Enter {platform} Username
                                </label>
                                <input
                                    className='input is-rounded is-fullwidth'
                                    type='text'
                                    placeholder='Username'
                                    value={platformDetails[platform]?.username || ''}
                                    onChange={(e) => handlePlatformDetailChange(platform, 'username', e.target.value)}
                                />
                                <label className='label has-text-left ml-3'>
                                    Please Enter {platform} Followers Range
                                </label>
                                <input
                                    className='input is-rounded is-fullwidth'
                                    type='text'
                                    placeholder='Follower Range'
                                    value={platformDetails[platform]?.followerRange || ''}
                                    onChange={(e) => handlePlatformDetailChange(platform, 'followerRange', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                )
            ))}

            <button className='button is-primary is-rounded is-fullwidth mt-5' onClick={handleSubmit}>Submit</button>
        </div>
    );
};

export default ApplicationPage;
