import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { adminsignin } from "../Dashboard/user";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo192.png';
const AdminSignin = () => {
    const navigate = useNavigate();
    const [email, setemail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword); // Toggle showPassword state
    };

    const handleadminsignin = async (e) => {
        e.preventDefault();

        try {
            const res = await adminsignin({ email, password });
            if (!res.error) {
                console.log(res.message);

                // redirect to internal team dashboard
                navigate("/admin-dashboard", { replace: true });
            } else {
                toast.error(res.error);

            }
        } catch (err) {
            // alert(err);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <div className='imgfull'>
            {/* <div className='control my-input' style={{ marginLeft: "29rem" }}> */}
            <div className='container'>
                <div className='logo-card'>
                    <figure className="image is-64x64">
                        <img src={logo} alt="Logo" />
                    </figure>
                </div>


                <h1 className='title mt-5 has-text-white has-text-centered'>Sign In
                </h1>
                <div className='my-2 has-text-left my-input'>
                    <label className='label  has-text-white'>Email</label>
                </div>
                <div className='field '>
                    <div className="control has-icons-left my-input">
                        <div className='colomn'>
                            <input className="input  is-rounded "
                                type="text"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setemail(e.target.value)

                                    }
                                />
                                <span className="icon is-small is-left">
                                    <i className="fas fa-envelope"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='my-2 has-text-left my-input'>
                        <label className='label has-text-white '>Password</label>
                    </div>
                    <div className='field'>
                    <div className="control has-icons-left has-icons-right is-flex my-input">
                        <input
                            className="input is-rounded"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-key"></i>
                        </span>
                        <span className="icon is-small is-right" onClick={togglePasswordVisibility} style={{ cursor: 'pointer','pointer-events': 'all'}}>
                            <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
                        </span>
                    </div>
                </div>
                    <div className='Link  mb-3 my-input'>
                        <Link to="/forgetinternal">
                            <p className='has-text-right'>Forget Your Password?</p>
                        </Link>
                    </div>

                <div className="field">
                    <div className="control my-input">

                        <button className="button is-primary is-rounded  is-focused is-medium is-fullwidth " disabled={!email || !password || loading}
                            onClick={handleadminsignin}>
                            {loading ? 'Loading...' : 'SIGN IN'}
                        </button>


                    </div>
                </div>

                <div className='is-flex has-text-left  mr-5 mt-3 my-input' style={{ marginLeft: "33rem" }}>
                    <p className='has-text-white' >Don't have an account ? </p>
                    <Link to="/admin-signup">
                        <span className='has-text-weight-bold ml-2 '>SignUp</span>
                    </Link>

                </div>
            </div>
        </div>

    )
}

export default AdminSignin
