import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Config } from '../.env.js';
import ApplicationPage from '../pages/AppicationPage.js';
import ConformationPage from '../pages/confirmationPage.js';
import InfDeliverables from '../pages/influencrDeliverables.js';

const AllCamp = ({ campaign }) => {
    const navigate = useNavigate();
    const [isApplicationPageOpen, setIsApplicationPageOpen] = useState(false);
    const [isConfirmationPageOpen, setIsConfirmationPageOpen] = useState(false);
    const [isInfDeliverablesFormOpen, setIsInfDeliverablesFormOpen] = useState(false);

    const handleApply = () => {
        setIsApplicationPageOpen(true);
    };

    const handleApplyDeliverables = () => {
        setIsInfDeliverablesFormOpen(true);
    };

    const handleConfirmation = () => {
        setIsConfirmationPageOpen(true);
    };

    const handleCloseForms = () => {
        setIsApplicationPageOpen(false);
        setIsConfirmationPageOpen(false);
        setIsInfDeliverablesFormOpen(false);
    };

    const { brandName, followersCriteria, infDeliverables, cashValue, campaignName, uploadBrandLogo, approve, rejected } = campaign;

    let statusLabel = '';
    let statusColor = '';
    if (approve) {
        statusLabel = 'Approved Successfully';
        statusColor = 'green';
    } else if (rejected) {
        statusLabel = 'This campaign is Rejected';
        statusColor = 'red';
    } else {
        statusLabel = 'Pending';
        statusColor = 'orange';
    }

    const backgroundImageStyle = {
        backgroundImage: `url(${Config.API_URL}/uploads/${encodeURIComponent(uploadBrandLogo)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 500,
        width: 550,
        display: 'flex',
        flex: 1,
        backgroundBlendMode: 'multiply'
    };

    
    const modalContentStyle = {
        backgroundColor: 'white',  // Set background color to white
        padding: '10px',           // Add padding for better appearance
        borderRadius: '8px',       // Add border-radius for rounded corners
        width: '90%',              // Adjust width for mobile view
        maxWidth: '500px',         // Maximum width for larger screens
        margin: '0 auto',          // Center the modal
    };

    return (
        <div className='is-max-desktop'>
            <div className="card mt-6 mr-5" style={backgroundImageStyle}>
                <div className="card-content" style={{ flex: 1, height: 500, width: 100 }}>
                    <div className="box" style={{ marginLeft: 146, width: 380, height: 450, overflowX: 'hidden' }}>
                        <br />
                        <h1 className='subtitle'><b>Campaign Details</b></h1>
                        <p>Campaign Title: {campaignName}</p>
                        <p>Brand Name: {brandName}</p>
                        <p>Followers Criteria: {followersCriteria}</p>
                        <p>Deliverables: {infDeliverables}</p>
                        <p>Give Product Worth Rs: {cashValue}</p>
                        <div>
                            <button onClick={handleApply} className="button is-medium mt-3 ml-1 has-text-info is-rounded">Apply Now</button>
                            <button onClick={handleApplyDeliverables} className="button is-medium mt-3 ml-1 has-text-info is-rounded">Deliverables</button>
                            <button onClick={handleConfirmation} className="button is-medium mt-2 ml-1 has-text-info is-rounded">Confirmation</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for ApplicationPage */}
            {isApplicationPageOpen && (
                <div className="modal is-active">
                    <div className="modal-background" onClick={handleCloseForms}></div>
                    <div className="modal-content" style={modalContentStyle}>
                        <ApplicationPage campaign={campaign} onClose={handleCloseForms} />
                    </div>
                    <button className="modal-close is-large" aria-label="close" onClick={handleCloseForms}></button>
                </div>
            )}

            {/* Modal for ConformationPage */}
            {isConfirmationPageOpen && (
                <div className="modal is-active">
                    <div className="modal-background" onClick={handleCloseForms}></div>
                    <div className="modal-content" style={modalContentStyle}>
                        <ConformationPage campaign={campaign} onClose={handleCloseForms} />
                    </div>
                    <button className="modal-close is-large" aria-label="close" onClick={handleCloseForms}></button>
                </div>
            )}

            {/* Modal for InfDeliverables */}
            {isInfDeliverablesFormOpen && (
                <div className="modal is-active">
                    <div className="modal-background" onClick={handleCloseForms}></div>
                    <div className="modal-content" style={modalContentStyle}>
                        <InfDeliverables campaign={campaign} onClose={handleCloseForms} />
                    </div>
                    <button className="modal-close is-large" aria-label="close" onClick={handleCloseForms}></button>
                </div>
            )}
        </div>
    );
};

export default AllCamp;
