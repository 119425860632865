import React, { useState } from 'react';


const InviteForm = () => {
    const [MobileNumber, setmobilenumber] = useState("");
    const [Email, setemail] = useState();
    //mobile number validation regx
    let hasValNumber = /^[0-9]{1,10}$/.test(MobileNumber);

    //email validatin regx
    let hasValEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(Email);

  
    return (
        <div className='is-child box has-text-centered'>
            <h1 className='subtitle mt-5'>Invite form campaign
            </h1>
            <div className='form'>

                <div className='field'>
                    <div className="control has-icons-left">
                        <div className='colomn'>
                            <input className="input is-rounded "
                                type="text"
                                placeholder="Email ID"
                                value={Email}
                                onChange={(e) => setemail(e.target.value)}
                            />
                            {Email && !hasValEmail && (
                                <small
                                    className={`ml-1 mt-1  ${!hasValEmail && Email !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                    {!hasValEmail && Email !== '' ? ('Enter a valid email address') : ''}
                                </small>
                            )}
                            <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                            </span>
                        </div>
                    </div>
                </div>
                {/* mobile number */}
                <div className='field '>
                    <div className="control has-icons-left ">
                        <div className='colomn'>
                            <input className="input  is-rounded "
                                type="text"
                                placeholder="Mobile Number"
                                value={MobileNumber}
                                onChange={(e) => setmobilenumber(e.target.value)}
                            />

                            {/* mobile number validation */}
                            {MobileNumber && !hasValNumber && (
                                <div>
                                    <small
                                        className={`ml-1 mt-1  ${!hasValNumber && MobileNumber !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                        {!hasValNumber && MobileNumber !== '' ? 'Please Enter valid Mobile number' : ''}
                                    </small>
                                </div>
                            )}

                            <span className='icon is-small is-left'>
                                <i className='fas fa-phone'></i>
                            </span>
                        </div>

                    </div>
                </div>

                <div className="field">
                    <div className="control">

                        <button className="button is-primary is-rounded  is-focused is-medium is-fullwidth " disabled={!MobileNumber || !Email || !hasValNumber || !hasValEmail}>
                            SIGN UP
                        </button>

                    </div>
                </div>
            </div>
        </div>

    );
};
export default InviteForm;
