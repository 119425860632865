// api.js
import { Config } from '../.env.js';
// Define the URL of your backend API endpoint
const API_URL = `${Config.API_URL}/submitFormBrand`; // Update this with your actual API URL
const API_URL1 = `${Config.API_URL}/submitInfForm`;
const API_URL2 = `${Config.API_URL}/submitForm1`;

export const getBrandCampaigns1 = async () => {
    try {
        // Make a GET request to the backend API endpoint that returns brand campaigns
        const response = await fetch( `${API_URL2}/brand-campaigns`);
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to fetch brand campaigns: ${error.message}`);
    }
};
// Function to fetch brand campaigns from the backend
export const getBrandCampaigns = async () => {
    try {
        // Make a GET request to the backend API endpoint that returns brand campaigns
        const response = await fetch(`${API_URL}/brand-campaigns`);
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to fetch brand campaigns: ${error.message}`);
    }
};

export const approveInfluencer = async (influencerId) => {
    try {
        const BASE_URL = `${Config.API_URL}`;
        const response = await fetch(`${BASE_URL}/influencers/${influencerId}/approve`, {
            method: 'PUT',
        });

        if (!response.ok) {
            throw new Error(`Failed to approve influencer: ${response.statusText}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error approving influencer:', error);
        throw new Error('Failed to approve influencer');
    }
};
export const getInfluencerConformation = async () => {
    try {
        // Make a GET request to the backend API endpoint that returns brand campaigns
        const response = await fetch(`${API_URL1}/influencer-conformation`);
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error('Failed to fetch influencer campaigns: ${error.message}');
    }
};
export const getInfluencerDeliverables = async () => {
    try {
        // Make a GET request to the backend API endpoint that returns brand campaigns
        const response = await fetch(`${API_URL1}/influencer-deliverables`);
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error('Failed to fetch influencer campaigns: ${error.message}');
    }
};