import React, { useState, useEffect } from 'react';
import { getInfluencerCampaigns } from '../components/infApi';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
const ShortlistedInfluencer = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [error, setError] = useState(null); // State to hold API request error
    const location = useLocation();
    const { brandName } = useParams()
    console.log(brandName);
    useEffect(() => {
        console.log('Fetching brand campaigns...');
        // Fetch brand campaigns from the backend when the component mounts
        fetchInfluencerCampaigns();
    }, [brandName]);

    const fetchInfluencerCampaigns = async () => {
        try {
            // Call your API function to fetch brand campaigns
            const response = await getInfluencerCampaigns();
            const data = await response.json();
            if (response.ok) {
                let filteredCampaigns = data.filter(campaign => brandName.includes(campaign.brandName));

                filteredCampaigns = filteredCampaigns.filter(campaign => {
                    // Check conditions for each property
                    if (campaign.isExecutive && !campaign.approvedbyExecutive) {
                        return false; // Skip if isExecutive is true but approvedbyExecutive is false
                    }
                    if (campaign.isLead && !campaign.approvedbyLead) {
                        return false; // Skip if isLead is true but approvedbyLead is false
                    }
                    if (campaign.isAdmin && !campaign.approvedbyAdmin) {
                        return false; // Skip if isAdmin is true but approvedbyAdmin is false
                    }
                    if (campaign.isBrand && !campaign.approvedbyBrand) {
                        return false; // Skip if isBrand is true but approvedbyBrand is false
                    }
                    // If none of the above conditions match, include the campaign
                    return true;
                });

                // Update state with the filtered campaigns
                setCampaigns(filteredCampaigns);
                console.log('Campaigns state after update:', filteredCampaigns);
            } else {
                // Handle non-200 response status (e.g., 404, 500)
                setError(data.message || 'Failed to fetch brand campaigns');
            }
        } catch (error) {
            // Handle network errors or other exceptions
            setError('Error fetching brand campaigns: ' + error.message);
        }
    };

    return (
        <div className="container " >
            <h2>Shortlisted Influencer</h2>
            {error && <p>Error: {error}</p>} {/* Display error message if there's an error */}

            <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable is-responsive">
                <thead>
                    <tr>
                        <th className="customStyle">Instagram Username</th>
                        <th className="customStyle">Instagram Followers Range</th>
                        <th className="customStyle">Exact Followers</th>
                        <th className="customStyle">Selected Category</th>
                        <th className="customStyle">Phone Number</th>
                        <th className="customStyle">Whatsapp Number</th>
                        {/* <th className="customStyle">UserName</th> */}
                        <th className="customStyle">Email</th>
                        <th className="customStyle">Gender</th>
                        <th className="customStyle">Age</th>
                        <th className="customStyle">Campaign Type</th>
                        <th className="customStyle">Deal Type</th>
                        <th className="customStyle">Languages</th>
                        <th className="customStyle">Selected Platform</th>
                        <th className="customStyle">YouTube Channel Link</th>
                        <th className="customStyle">YouTube Subscribers Range</th>
                        <th calssName="customStyle">Approve By</th>
                    </tr>
                </thead>
                <tbody>
                    {campaigns.map(campaign => (
                        <tr key={campaign._id}>
                            <td className="customStyle">{campaign.InstagramUsername}</td>
                            <td className="customStyle">{campaign.InstagramFollowersRange}</td>
                            <td className="customStyle">{campaign.ExactFollowers}</td>
                            <td className="customStyle">{campaign.selectedCategory}</td>
                            <td className="customStyle">{campaign.PhoneNumber}</td>
                            <td className="customStyle">{campaign.WhatsappNumber}</td>
                            {/* <td className="customStyle">{campaign.UserName}</td> */}
                            <td className="customStyle">{campaign.Email}</td>
                            <td className="customStyle">{campaign.Gender}</td>
                            <td className="customStyle">{campaign.Age}</td>
                            <td className="customStyle">{campaign.CampaignType}</td>
                            <td className="customStyle">{campaign.DealTyper}</td>
                            <td className="customStyle">{campaign.Languages}</td>
                            <td className="customStyle">{campaign.selectedPlatform}</td>
                            <td className="customStyle">{campaign.YouTubeChannelLink}</td>
                            <td className="customStyle">{campaign.YouTubeSubscribersRange}</td>
                            <td className="customStyle">

                                {(campaign.approvedbyExecutive && campaign.approvedbyLead && campaign.approvedbyAdmin && campaign.approvedbyBrand) ? (
                                    <span style={{ color: 'orange' }}>Executive,Lead, Admin and Brand</span>
                                ) : (
                                    <>
                                        {campaign.approvedbyExecutive && (
                                            <span style={{ color: 'green' }}>Executive</span>
                                        )}
                                        {campaign.approvedbyLead && (
                                            <span style={{ color: 'blue' }}> Lead</span>
                                        )}
                                        {campaign.approvedbyAdmin && (
                                            <span style={{ color: 'gray' }}> Admin</span>
                                        )}
                                        {campaign.approvedbyBrand && (
                                            <span style={{ color: 'black' }}> Brand</span>
                                        )}
                                    </>
                                )}
                            </td>

                            {/* Add more table cells to display other campaign data */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
};

export default ShortlistedInfluencer
