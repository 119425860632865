import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Config } from '../.env.js';

const InfDelverablesForm = ({ campaign }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        // instagramUsername: '',
        // Email: '',
        // phone: '',
        FullName: '',
    });
    const [uploadVideo, setUploadVideo] = useState(null);
    const [uploadRawVideo, setUploadRawVideo] = useState(null);
    const [videoNames, setVideoNames] = useState([]);
    const [rawVideoNames, setRawVideoNames] = useState([]);
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const validFiles = files.filter(file => file.type.startsWith('video/'));
        const invalidFiles = files.filter(file => !file.type.startsWith('video/'));

        if (invalidFiles.length > 0) {
            setError('Only video files are allowed!');
            setUploadVideo(null);
            setVideoNames([]);
        } else {
            setError('');
            setUploadVideo(validFiles);
            setVideoNames(validFiles.map(file => file.name));
        }
    };
    const handleRawFileChange = (e) => {
        const files = Array.from(e.target.files);
        const validFiles = files.filter(file => file.type.startsWith('video/'));
        const invalidFiles = files.filter(file => !file.type.startsWith('video/'));

        if (invalidFiles.length > 0) {
            setError('Only video files are allowed!');
            setUploadRawVideo(null);
            setRawVideoNames([]);
        } else {
            setError('');
            setUploadRawVideo(validFiles);
            setRawVideoNames(validFiles.map(file => file.name));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);

        // Log uploaded files
        console.log('Uploaded Video:', uploadVideo);
        console.log('Uploaded Raw Video:', uploadRawVideo);

        try {
            const bodyData = new FormData();
            for (const key in formData) {
                bodyData.append(key, formData[key]);
            }
            if (uploadVideo) {
                for (const file of uploadVideo) {
                    bodyData.append('uploadVideo', file);
                }
            }
            if (uploadRawVideo) {
                for (const file of uploadRawVideo) {
                    bodyData.append('uploadRawVideo', file);
                }
            }


            bodyData.append('campaign', JSON.stringify(campaign));
            bodyData.append('isexecutiveContent', campaign.isexecutiveContent);
            bodyData.append('isleadContent', campaign.isleadContent);
            bodyData.append('isadminContent', campaign.isadminContent);
            bodyData.append('isbrandContent', campaign.isbrandContent);
            bodyData.append("brandName", campaign.brandName);
            bodyData.append("campaignName", campaign.campaignName);
            const response = await fetch(`${Config.API_URL}/api/influencer/submitInfForm`, {
                method: 'POST',
                body: bodyData
            });

            if (response.ok) {
                console.log('Form submitted successfully');
                navigate('/success-page');
                // Reset form data
                setFormData({
                    // instagramUsername: '',
                    Email: '',
                    // phone: '',
                    FullName: '',
                    campaign: {  // Include the campaign object
                        isexecutiveContent: campaign.isexecutiveContent,
                        isleadContent: campaign.isleadContent,
                        isadminContent: campaign.isadminContent,
                        isbrandContent: campaign.isbrandContent,
                        brandName: campaign.brandName,
                        campaignName: campaign.campaignName
                    }
                });


            } else {
                console.error('Form submission failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="container mt-3">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <h1 className='title mt-5'> Deliverables Form</h1>
                <h1 className='mt-5'>Deliverables</h1>
                <h1 className='mt-5'> Dear Creators,</h1>
                <h1 className='mt-5'>We will be needing HD video and the raw videos as well.</h1>
                <h1 className='mt-5'> Please do not upload any brand deliverables without any approval.</h1>
                <h1 className='mb-2'><b> Cheers! <br />Hobo Team</b></h1>

                {/* Render input fields with a common handleChange function */}
                {Object.entries(formData).map(([fieldName, value]) => (
                    <div className="field" key={fieldName}>
                        {/* {fieldName === 'instagramUsername' ? (
                            <label className="label has-text-left ml-4">Instagram Username</label>
                        */}
                        {/* {fieldName === 'email' ? (
                            <label className="label has-text-left ml-4">Email</label> */}
                        {/* ) : fieldName === 'phone' ? (
                              <label className="label has-text-left ml-4">Phone</label>  */}
                        {fieldName === 'FullName' ? (
                            <label className="label has-text-left"> Full Name</label>

                        ) : (

                            <label className="label has-text-left ml-4">{fieldName}</label>
                        )}
                        <div className="control">
                            <input
                                className="input is-rounded"
                                type="text"
                                name={fieldName}
                                value={value}
                                onChange={handleChange}
                                placeholder={`Enter ${fieldName}`}
                            />
                        </div>
                    </div>
                ))}
                <div className="field">
                    <label className="label">Upload your HD video(s) here for brand approval</label>
                    <div className="file mt-4">
                        <label className="file-label">
                            <input
                                className="file-input mr-2"
                                type="file"
                                name="uploadVideo"
                                onChange={handleFileChange}
                                multiple
                                accept="video/*"
                            />
                            <span className="file-cta" style={{borderRadius:"20px", paddingRight: "315px"}}>
                                <span className="file-icon">
                                    <i className="fas fa-upload"></i>
                                </span>
                                <span className="file-label">
                                    Choose file(s)…
                                </span>
                            </span>
                        </label>
                    </div>
                    {videoNames.length > 0 && (
                        <div>
                            <p>Selected files:</p>
                            <ul>
                                {videoNames.map((name, index) => (
                                    <li key={index}>{name}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                <div className="field">
                    <label className="label">Upload your RAW HD video(s) here for Brand Approval*</label>
                    <div className="file mt-4">
                        <label className="file-label">
                            <input
                                className="file-input mr-2"
                                type="file"
                                name="uploadRawVideo"
                                onChange={handleRawFileChange}
                                multiple
                                accept="video/*"
                            />
                            <span className="file-cta" style={{borderRadius:"20px", paddingRight: "315px"}}>
                                <span className="file-icon">
                                    <i className="fas fa-upload"></i>
                                </span>
                                <span className="file-label">
                                    Choose file(s)…
                                </span>
                            </span>
                        </label>
                    </div>
                    {rawVideoNames.length > 0 && (
                        <div>
                            <p>Selected files:</p>
                            <ul>
                                {rawVideoNames.map((name, index) => (
                                    <li key={index}>{name}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                {error && (
                    <div className="notification is-danger">
                        {error}
                    </div>
                )}
                {campaign.isexecutiveContent && (
                    <div>
                        <p>isexecutiveContent: true</p>
                    </div>
                )}

                {campaign.isleadContent && (
                    <div>
                        <p>isleadContent: true</p>
                    </div>
                )}

                {campaign.isadminContent && (
                    <div>
                        <p>isadminContent: true</p>
                    </div>
                )}

                {campaign.isbrandContent && (
                    <div>
                        <p>isbrandContent: true</p>
                    </div>
                )}
                <div className="field">
                    <div className="control">
                        <button className="button is-primary is-rounded is-fullwidth" type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default InfDelverablesForm;