import React, { useState, useEffect } from 'react';
import { getInfluencerCampaigns, approveCampaignExecutive } from '../components/infApi'; // Import your API functions
import { useParams } from 'react-router-dom';
const BrandDashboard1 = ({ campaign }) => {
    const [campaigns, setCampaigns] = useState([]);
    const [error, setError] = useState(null); // State to hold API request error
    const [filterStatus, setFilterStatus] = useState('all');
    // const {brandName}= campaign
    const { brandName } = useParams();
    useEffect(() => {
        console.log('Fetching brand campaigns...');
        // Fetch brand campaigns from the backend when the component mounts
        fetchInfluencerCampaigns();
    }, [brandName]);
 
    const fetchInfluencerCampaigns = async () => {
        try {
            // Call your API function to fetch brand campaigns
            const response = await getInfluencerCampaigns();
            const data = await response.json();
            if (response.ok) {
                // Filter campaigns where isExecutive is true
                const executiveCampaigns = data.filter(campaign => {
                    return campaign.isExecutive && brandName.includes(campaign.brandName);
                });
                // Update state with the filtered campaigns
                setCampaigns(executiveCampaigns);
                console.log('Campaigns state after update:', executiveCampaigns);
            } else {
                // Handle non-200 response status (e.g., 404, 500)
                setError(data.message || 'Failed to fetch brand campaigns');
            }
        } catch (error) {
            // Handle network errors or other exceptions
            setError('Error fetching brand campaigns: ' + error.message);
        }
    };

    const handleApprove = async (campaignId) => {
        try {
            console.log('Campaign approved:', campaignId);
            // Call the API function to approve the campaign
            const response = await approveCampaignExecutive(campaignId);
            const data = await response.json();

            if (response.ok) {
                // Display the message "Campaign approved"
                alert('Campaign approved successfully');
                // Update the campaigns state to reflect the change
                const updatedCampaigns = campaigns.map(campaign => {
                    if (campaign._id === campaignId) {
                        return { ...campaign, approvedbyExecutive: true };
                    }
                    return campaign;
                });
                setCampaigns(updatedCampaigns);
            } else {
                console.error('Failed to approve campaign:', data.message);
            }
        } catch (error) {
            console.error('Error approving campaign:', error.message);
        }
    };
    const getStatus = (campaign) => {
        if (campaign.rejected) {
            return 'Rejected';
        }
        if (campaign.isExecutive && !campaign.approvedbyExecutive) {
            return 'On-Hold';
        }
        if (campaign.isLead && !campaign.approvedbyLead) {
            return 'On-Hold';
        }
        if (campaign.isAdmin && !campaign.approvedbyAdmin) {
            return 'On-Hold';
        }
        if (campaign.isBrand && !campaign.approvedbyBrand) {
            return ' On-Hold';
        }
        return 'Selected';
    };
    const filteredCampaigns = campaigns.filter(campaign => {
        if (filterStatus.length === 0 || filterStatus.includes('all')) {
            return true;
        } else {
            const campaignStatus = getStatus(campaign);
            return filterStatus.includes(campaignStatus) || 
                   (filterStatus.includes('Selected') && filterStatus.includes('Rejected') && campaignStatus === 'Selected');
        }
    });

    const toggleFilterStatus = (status) => {
        if (filterStatus.includes(status)) {
            setFilterStatus(filterStatus.filter(item => item !== status));
        } else {
            setFilterStatus([...filterStatus, status]);
        }
    };


    const handleAssign = (campaignId) => {
        // Logic to handle assignment action
    };

    return (
        <div className="container mt-3" >

            {error && <p>Error: {error}</p>} {/* Display error message if there's an error */}
            <div className="filter-buttons my-4 mr-3">
                <button
                    className={filterStatus.includes('all') ? 'mr-2 selected' : 'mr-2'}
                    onClick={() => toggleFilterStatus('all')}
                >
                    All
                </button>
                <button
                    className={filterStatus.includes('Selected') ? 'mr-2 selected' : 'mr-2'}
                    onClick={() => toggleFilterStatus('Selected')}
                >
                    Selected
                </button>
                <button
                    className={filterStatus.includes('Rejected') ? 'mr-2 selected' : 'mr-2'}
                    onClick={() => toggleFilterStatus('Rejected')}
                >
                    Rejected
                </button>
                <button
                    className={filterStatus.includes('On-Hold') ? 'mr-2 selected' : 'mr-2'}
                    onClick={() => toggleFilterStatus('On-Hold')}
                >
                    On-Hold
                </button>
            </div>
            <div className='subtitle' style={{ fontSize: '15px' }}>
                <h1 className='underline'>Influencer Applications</h1>
            </div>
            <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable is-responsive">
                <thead>
                    <tr>

                        <th className="customStyle">Instagram Username</th>
                        <th className="customStyle">Instagram Followers Range</th>
                        <th className="customStyle">Exact Followers</th>
                        <th className="customStyle">Selected Category</th>
                        {/* <th className="customStyle">Phone Number</th> */}
                        {/* <th className="customStyle">Whatsapp Number</th> */}
                        {/* <th className="customStyle">User Name</th> */}
                        {/* <th className="customStyle">Email</th> */}
                        <th className="customStyle">Gender</th>
                        <th className="customStyle">Age</th>
                        <th className="customStyle">Campaign Type</th>
                        <th className="customStyle">Deal Type</th>
                        <th className="customStyle">Languages</th>
                        <th className="customStyle">Selected Platform</th>
                        <th className="customStyle">YouTube Channel Link</th>
                        <th className="customStyle">YouTube Subscribers Range</th>
                        <th className="customStyle">Action</th>
                        {/* <th className="customStyle">Assign To</th> */}
                        <th className='customStyle'>Status</th> {/* Add column for action buttons */}
                        {/* Add more table headers as needed */}
                    </tr>
                </thead>
                <tbody>
                
                {filteredCampaigns.map(campaign => (
                        <tr key={campaign._id}>
                            <td className="customStyle">{campaign.InstagramUsername}</td>
                            <td className="customStyle">{campaign.InstagramFollowersRange}</td>
                            <td className="customStyle">{campaign.ExactFollowers}</td>
                            <td className="customStyle">{campaign.selectedCategory}</td>
                            {/* <td className="customStyle">{campaign.PhoneNumber}</td> */}
                            {/* <td className="customStyle">{campaign.WhatsappNumber}</td> */}
                            {/* <td className="customStyle">{campaign.UserName}</td> */}
                            {/* <td className="customStyle">{campaign.Email}</td> */}
                            <td className="customStyle">{campaign.Gender}</td>
                            <td className="customStyle">{campaign.Age}</td>
                            <td className="customStyle">{campaign.CampaignType}</td>
                            <td className="customStyle">{campaign.DealTyper}</td>
                            <td className="customStyle">{campaign.Languages}</td>
                            <td className="customStyle">{campaign.selectedPlatform}</td>
                            <td className="customStyle">{campaign.YouTubeChannelLink}</td>
                            <td className="customStyle">{campaign.YouTubeSubscribersRange}</td>

                            <td className="customStyle">
                                <button onClick={() => handleApprove(campaign._id)} className="button is-primary">Approve</button>
                            </td>
                            {/* <td className="customStyle">
                                <button onClick={() => handleAssign(campaign._id)} className="button is-primary">Assign To</button>
                            </td> */}
                            {/* for status */}
                            <td className='customStyle'>{getStatus(campaign)}</td>
                            {/* Add more table cells to display other campaign data */}
                        </tr>
                    ))}

                </tbody>
            </table>
            {/* <InfluencerTableLead approvedCampaignId={approvedCampaignId} onApprove={handleApprove} /> */}
        </div>

    );
};

export default BrandDashboard1;
