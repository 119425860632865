import React, { useState, useEffect } from 'react';
import { Config } from '../.env.js';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo192.png';
const SignupForm = () => {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    // const [brandName, setBrandName] = useState([]);
    // const location = useLocation();
    // const { brandName } = location.state;
    const { brandName } = useParams();
    useEffect(() => {
        console.log('Brand Name:', brandName);
    }, [brandName]);


    console.log("brand Name ", brandName);
    // console.log(email);
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${Config.API_URL}/api/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userName: userName, email: email, password: password, brandName: brandName }),
            });

            if (!response.ok) {
                throw new Error('Failed to sign up');
            }

            setUserName('');
            setEmail('');
            setPassword('');
            setError('');
            toast.success("User Registered Successfully");
            // setSuccess(() => 'User registered successfully'); 
            // const { brandName } = useParams();
            // console.log("brand",brandName)
            navigate("/userSignIn", { replace: true, state: { brandName: brandName } });
        } catch (error) {
            setError('Error signing up user');
            setSuccess('');
        }
        finally {
            setLoading(false);
        }
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className='imgfull'>
            <div className='container1'>
                <div className='logo-card'>
                    <figure className="image is-64x64">
                        <img src={logo} alt="Logo" />
                    </figure>
                </div>
                <h1 className='title  has-text-white'>Sign Up
                </h1>
                <form onSubmit={handleSubmit}>
                    <div className='form'>
                        <div className=' has-text-left '>
                            <label className='label has-text-white '>Name</label>
                        </div>
                        <div className='control'>
                            <input
                                className='input is-rounded'
                                type='text'
                                placeholder='User Name'
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                style={{ width: '300px' }}
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <div className=' has-text-left '>
                            <label className='label has-text-white'>Email Id</label>
                        </div>
                        <div className='control '>
                            <input
                                className='input is-rounded'
                                type='email'
                                placeholder='Email ID'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{ width: '300px' }}
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <div className=' has-text-left  '>
                            <label className='label has-text-white'>Password</label>
                        </div>
                        <div className='control has-icons-right'>
                            <input
                                className='input is-rounded'
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{ width: '300px' }}
                            />
                            <span className="icon is-small is-right" onClick={togglePasswordVisibility} style={{ cursor: 'pointer', 'pointer-events': 'all' }}>
                            <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
                        </span>
                        </div>
                        
                    </div>

                    {/* <div>
                    <label>Brand Name:</label>
                    <input
                        type='text'
                        value={brandName}
                        readOnly
                    />
                </div> */}
                    <div className='field'>
                        <div className='control'>
                            <button
                                className='button is-primary is-rounded is-fullwidth'
                                disabled={!userName || !email || !password}
                            >
                                Sign Up
                            </button>
                        </div>
                    </div>
                    {error && <p className='has-text-danger'>{error}</p>}
                    {success && <p className='has-text-success'>{success}</p>}
                </form>
            </div>
        </div>
    );
};

export default SignupForm;
