import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { adminsignup } from "../Dashboard/user";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo192.png';
const AdminSignup = () => {
    const navigate = useNavigate();
    const [username, setusername] = useState("");
    const [email, setemail] = useState("");
    const [password, setPassword] = useState("");
    const [ConfirmPassword, setonfirmpassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword); // Toggle showPassword state
    };

    // Email validation regex
    let hasValEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u.test(email);


    // Password validations regex


    const handleadminsignup = async (e) => {
        e.preventDefault();
        try {
            const res = await adminsignup({ username, email, password });
            if (res.error) {
                toast.error(res.error);
            }
            else {
                toast.success(res.message);
                // Redirect to the admin signin page
                navigate(`/otpAdmin/${email}`, { replace: true });
            }
        } catch (err) {
            // alert(err)
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <div className='imgfull'>
            <div className='container1 '>
                <div className='logo-card'>
                    <figure className="image is-64x64">
                        <img src={logo} alt="Logo" />
                    </figure>
                </div>
                <h1 className='title  has-text-white'>Sign Up</h1>
                <div className='form'>
                    <div className='my-2 has-text-left my-input'>
                        <label className='label has-text-white'>User Name</label>
                    </div>
                    <div className='field'>
                        <div className="control has-icons-left my-input">
                            <div className='colomn'>
                                <input
                                    className="input is-rounded"
                                    type="text"
                                    placeholder="User Name"
                                    value={username}
                                    onChange={(e) => setusername(e.target.value)}
                                />
                                <span className='icon is-small is-left'>
                                    <i className='fas fa-user'></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='my-2 has-text-left my-input'>
                        <label className='label has-text-white'>Email</label>
                    </div>
                    <div className='field'>
                        <div className="control has-icons-left my-input">
                            <div className='colomn'>
                                <input
                                    className="input is-rounded "
                                    type="text"
                                    placeholder="Email ID"
                                    value={email}
                                    onChange={(e) => setemail(e.target.value)}
                                />
                                <span className="icon is-small is-left">
                                    <i className="fas fa-envelope"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    {email && !hasValEmail && (
                        <small className={`ml-1 mt-1 ${!hasValEmail && email !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                            {!hasValEmail && email !== '' ? 'Enter a valid email address' : ''}
                        </small>
                    )}
                    <div className='my-2 has-text-left my-input'>
                        <label className='label has-text-white'>Password</label>
                    </div>
                    <div className='field'>
                    <div className="control has-icons-left has-icons-right is-flex my-input">
                        <input
                            className="input is-rounded"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-key"></i>
                        </span>
                        <span className="icon is-small is-right" onClick={togglePasswordVisibility} style={{ cursor: 'pointer','pointer-events': 'all'}}>
                            <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
                        </span>
                    </div>
                </div>
                    {/* Password validation */}


                    <div className="field">
                        <div className="control my-input">
                            <Link to="/otp">
                                <button
                                    className="button is-primary is-rounded is-focused is-medium is-fullwidth"
                                    disabled={!username || !email || !password || loading}
                                    onClick={handleadminsignup}
                                >
                                    {loading ? 'Loading...' : 'SIGN UP'}
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className='is-flex justify-content:flex-start text has-text-left ml-5' >
                        <p className='has-text-white'  style={{marginLeft:"184px"}}>Have an account ?</p>
                        <Link to='/admin-signin'>
                            <span className='has-text-weight-bold ml-2'>Sign in</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminSignup;
