import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getAllCampaigns } from '../components/campaignApi';
import AllCamp from './allCampaign';

const ITEMS_PER_PAGE = 10;
const PAGES_DISPLAYED = 5;

const Live = () => {
  const location = useLocation();
  const [campaigns, setCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const brandName = location.state ? location.state.brandName : [];

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const allCampaigns = await getAllCampaigns();
        const approvedCampaigns = allCampaigns.filter(campaign => campaign.approve === true);
        setCampaigns(approvedCampaigns);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    };

    if (brandName) {
      fetchCampaigns();
    }
  }, [brandName]);

  const totalPages = Math.ceil(campaigns.length / ITEMS_PER_PAGE);
  const startPage = Math.max(1, Math.min(currentPage - Math.floor(PAGES_DISPLAYED / 2), totalPages - PAGES_DISPLAYED + 1));
  const endPage = Math.min(totalPages, startPage + PAGES_DISPLAYED - 1);
  const paginatedCampaigns = campaigns.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className='container'>
      <h1 className='title is-1 has-text-centered'><b>Brand Collabs For You</b></h1>
      <h2 className='subtitle is-3 has-text-centered'>BROUGHT TO YOU WITH LOVE FROM HOBO.VIDEO</h2>
      <div className="columns is-multiline is-centered">
        {paginatedCampaigns.length > 0 ? (
          paginatedCampaigns.map((campaign, index) => (
            <div key={index} className="column is-half">
              <AllCamp campaign={campaign} alignLeft={index % 2 === 0} />
            </div>
          ))
        ) : (
          <div>No campaigns to display</div>
        )}
      </div>
      <div className="pagination is-centered" role="navigation" aria-label="pagination">
        {currentPage > 1 && (
          <button className="pagination-previous" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
          <button key={page} className={`pagination-link ${page === currentPage ? 'is-current' : ''}`} aria-label={`Goto page ${page}`} onClick={() => handlePageChange(page)}>
            {page}
          </button>
        ))}
        {currentPage < totalPages && (
          <button className="pagination-next" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
        )}
      </div>
    </div>
  );
};

export default Live;
