import React, { useState, useEffect } from 'react';
import { getInfluencerCampaigns, approveCampaign } from '../components/infApi';
import { Config } from '../.env.js';

// import ShortlistedInfluencers from './ShortlistedInfluencers';
const BrandInfDashboard = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [selectedInfluencers, setSelectedInfluencers] = useState([]);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetchInfluencerCampaigns();
    }, []);

    const fetchInfluencerCampaigns = async () => {
        try {
            const response = await getInfluencerCampaigns();
            const data = await response.json();
            if (response.ok) {
                setCampaigns(data);
            } else {
                setError(data.message || 'Failed to fetch brand campaigns');
            }
        } catch (error) {
            setError('Error fetching brand campaigns: ' + error.message);
        }
    };

    const handleSelect = async (campaignId) => {
        try {
            const selectedCampaign = campaigns.find(campaign => campaign._id === campaignId);
              setSelectedInfluencers([...selectedInfluencers,selectedCampaign])
            const updatedSelectedInfluencers = [...selectedInfluencers, selectedCampaign];

            // const updatedCampaigns = campaigns.filter(campaign => campaign._id !== campaignId);
            // setCampaigns(updatedCampaigns);

            const response = await fetch(`${Config.API_URL}/selected-influencers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedCampaign),
            });
            const responseData = await response.json();
            console.log('Response from Backend:', responseData);
            console.log('Selected Influencers:', updatedSelectedInfluencers);
           
            // Use updatedSelectedInfluencers directly when navigating
        } catch (error) {
            setError('Error selecting campaign: ' + error.message);
        }
    };

    const handleReject = async (campaignId) => {
        try {
            console.log('Campaign rejected:', campaignId);
            // Call your API function to reject the campaign
            // This functionality is similar to handleApprove
        } catch (error) {
            setError('Error rejecting campaign: ' + error.message);
        }
    };
    const handleRemarks = async (campaignId) => {
        try {
            console.log('Remarks added for campaign:', campaignId);
            // Call your API function to add remarks for the rejected campaign
            // This functionality is similar to handleApprove
        } catch (error) {
            setError('Error adding remarks: ' + error.message);
        }
    };


    return (
        <div className="container mt-3" >

            {error && <p>Error: {error}</p>} {/* Display error message if there's an error */}

            <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable is-responsive">
                <thead>
                    <tr>
                        <th className="customStyle">InstagramUsername</th>
                        <th className="customStyle">InstagramFollowersRange</th>
                        <th className="customStyle">ExactFollowers</th>
                        <th className="customStyle">selectedCategory</th>
                        {/* <th>PhoneNumber</th>
                        <th>WhatsappNumber</th> */}
                        <th className="customStyle">UserName</th>
                        {/* <th>Email</th> */}
                        <th className="customStyle">Gender</th>
                        <th className="customStyle">Age</th>
                        <th className="customStyle">CampaignType</th>
                        <th className="customStyle">DealType</th>
                        <th className="customStyle">Languages</th>
                        <th className="customStyle">selectedPlatform</th>
                        <th className="customStyle">YouTubeChannelLink</th>
                        <th className="customStyle">YouTubeSubscribersRange</th>
                        <th className="customStyle">Action</th>
                        <th className="customStyle">Remarks(If Rejected)</th> {/* Add column for action buttons */}
                        {/* Add more table headers as needed */}
                    </tr>
                </thead>
                <tbody>
                    {campaigns.map(campaign => (
                        <tr key={campaign._id}>
                            <td className="customStyle">{campaign.InstagramUsername}</td>
                            <td className="customStyle">{campaign.InstagramFollowersRange}</td>
                            <td className="customStyle">{campaign.ExactFollowers}</td>
                            <td className="customStyle">{campaign.selectedCategory}</td>
                            {/* <td>{campaign.PhoneNumber}</td>
                            <td>{campaign.WhatsappNumber}</td> */}
                            <td className="customStyle">{campaign.UserName}</td>
                            {/* <td>{campaign.Email}</td> */}
                            <td className="customStyle">{campaign.Gender}</td>
                            <td className="customStyle">{campaign.Age}</td>
                            <td className="customStyle">{campaign.CampaignType}</td>
                            <td className="customStyle">{campaign.DealTyper}</td>
                            <td className="customStyle">{campaign.Languages}</td>
                            <td className="customStyle">{campaign.selectedPlatform}</td>
                            <td className="customStyle">{campaign.YouTubeChannelLink}</td>
                            <td className="customStyle">{campaign.YouTubeSubscribersRange}</td>

                            <td className="customStyle">
                                <button onClick={() => handleSelect(campaign._id)} className="button is-primary">Select</button>
                                <button onClick={() => handleReject(campaign._id)} className="button is-primary">Reject</button>
                            </td>
                            <td className="customStyle">
                                <button onClick={() => handleRemarks(campaign._id)} className="button is-primary">Remarks(If Rejected)</button>
                            </td>
                            {/* Pass selectedInfluencers as a prop to SuccessPage */}
                           
                        </tr>
                    ))}
                </tbody>
            </table>
           {/* <ShortlistedInfluencers selectedInfluencers={selectedInfluencers}/> */}
        </div>

    );
};

export default BrandInfDashboard;
