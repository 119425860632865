import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Config } from '../.env.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo192.png';

const Otp2Lead = () => {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [expirationTime, setExpirationTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { email } = useParams();
  const location = useLocation(); // Move this inside the component
  // const { from } = location.state || {}; //

  // console.log(from);
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${Config.API_URL}/verifyotp1`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          numOtp: otp,
        }),
      });

      const data = await response.json();

      if (data.success) {
        toast.success('OTP verified successfully');
        navigate('/brand-application1', { replace: true });
      } else {
        toast.error(`Failed to verify OTP: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error during OTP verification: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };



  const handleResendOtp = async () => {
    try {
      const response = await fetch(`${Config.API_URL}/resendotp1`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      });

      const data = await response.json();

      if (data.success) {
        toast.success('OTP resent successfully');
        setAttempts(prevAttempts => prevAttempts + 1);

        let newTimer;
        if (attempts === 0) {
          newTimer = 30;
        } else if (attempts === 1) {
          newTimer = 120;
        } else if (attempts === 2) {
          newTimer = 600;
        } else if (attempts === 3) {
          newTimer = 1800;
        } else {
          toast.error('Maximum resend attempts reached');
          return;
        }

        setTimer(newTimer);
        setExpirationTime(Date.now() + newTimer * 1000); // Set expiration time
      } else {
        toast.error('You have exceeded limit for OTP attempts or resends. Please try after few hours or contact us at contact@hobo.video.', data.message);
      }
    } catch (error) {
      toast.error('Error during OTP resend:', error.message);
    }
  };


  return (
    <div className='imgfull'>
      <div className='container1 mt-5 pt-5'>
        <div className='logo-card'>
          <figure className="image is-64x64">
            <img src={logo} alt="Logo" />
          </figure>
        </div>
        <div className='container'>
          <h1 className='title has-text-white has-text-centered'>OTP Verification</h1>
          <div className='control pt-4'>
            <div className='field'>
              <label className='label has-text-white has-text-left'>6 Digit OTP Received on Your Registered Email</label>
              <div className='control has-icons-left my-input'>
                <input
                  className='input is-rounded'
                  type='text'
                  placeholder='e.g.123456'
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <span className='icon is-small is-left'>
                  <i className='fas fa-key'></i>
                </span>
              </div>
            </div>

            <div className='field is-grouped' style={{ marginLeft: '1rem' }}>
              <div className='control'>
                <button
                  className='button is-primary is-rounded'
                  onClick={handleResendOtp}
                  disabled={timer > 0}
                >
                  {timer > 0 ? `Resend OTP in ${timer}s` : 'Resend OTP'}
                </button>
              </div>
              <div className='control'>
                <button className='button is-success is-rounded' onClick={handleVerifyOtp}>
                {loading ? 'Loading...' : 'Verify OTP'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Otp2Lead
