import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CampaignLive from './CampaignLive';
import { getAllCampaigns } from '../components/campaignApi';
import BrandPage from './BrandForm';

const ITEMS_PER_PAGE = 10;
const PAGES_DISPLAYED = 5;

const ApproveCampaignPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('all');
  const [loading, setLoading] = useState(true); // Loading state
  const [showBrandPage, setShowBrandPage] = useState(false);
  const brandName = location.state ? location.state.brandName : [];
  console.log('Brand Names in GST Page:', brandName);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const allCampaigns = await getAllCampaigns();
        console.log('All Campaigns:', allCampaigns);

        // Ensure brandName is an array and not empty
        if (Array.isArray(brandName)) {
          const validBrandNames = brandName
            .filter(bn => typeof bn === 'string' && bn.trim() !== '')
            .map(bn => bn.trim().toLowerCase());
          console.log('Valid Brand Names:', validBrandNames);

          const filteredCampaigns = allCampaigns.filter(campaign => {
            if (Array.isArray(campaign.brandName) && campaign.brandName.length > 0) {
              return campaign.brandName.some(bn => {
                const cleanedBrandName = (bn || '').replace(/^,/, '').trim().toLowerCase();

                return validBrandNames.includes(cleanedBrandName);
              });
            } else {
              console.error('Campaign brandName is not a valid array:', campaign);
              return false;
            }
          });
          filteredCampaigns.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

          console.log('Filtered and Sorted Campaigns:', filteredCampaigns);
          setCampaigns(filteredCampaigns.reverse());
          setLoading(false); // Data loaded, update loading state
        } else {
          console.error('brandName is not an array:', brandName);
        }
      } catch (error) {
        console.error('Error fetching campaigns:', error);
        setLoading(false); // Error occurred, update loading state
      }
    };

    if (brandName) {
      fetchCampaigns();
    }
  }, [brandName]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (filterValue) => {
    setFilter(filterValue);
    setCurrentPage(1); // Reset page when filter changes
  };

  const filteredCampaigns = campaigns.filter((campaign) => {
    if (filter === 'all') return true;
    if (filter === 'approved') return campaign.approve;
    if (filter === 'pending') return !campaign.approve && !campaign.rejected;
    if (filter === 'rejected') return campaign.rejected;
    return true;
  });

  const totalPages = Math.ceil(filteredCampaigns.length / ITEMS_PER_PAGE);

  const startPage = Math.max(1, Math.min(currentPage - Math.floor(PAGES_DISPLAYED / 2), totalPages - PAGES_DISPLAYED + 1));
  const endPage = Math.min(totalPages, startPage + PAGES_DISPLAYED - 1);

  const renderCampaigns = () => {
    if (filteredCampaigns.length === 0) {
      if (filter === 'all') {
        return (
          <div className="columns is-ancestor">
            {showBrandPage ? (
              <div className="is-child has-text-centered" style={{ height: '100vh', width: '50vw', marginLeft:"250px"}}>
                <BrandPage brandName={brandName} />
              </div>
            ) : (
              <div className="is-child has-text-centered" style={{ width: '70vw', marginTop: "10vw" }}>
                <h1 className='subtitle mt-5'> Create New Campaign</h1>
                <button className="button is-large is-rounded is-primary" onClick={() => setShowBrandPage(true)}> Create New Campaign</button>
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div>No {filter} campaigns available.</div>
        );
      }
    } else {
      return (
        <>
          <div className="filter-buttons">
            {['all', 'approved', 'pending', 'rejected'].map((value) => (
              <button
                key={value}
                className={`mr-2 ${filter === value ? 'is-primary' : 'has-border-primary'}`}
                style={{
                  cursor: "pointer",
                  borderRadius: '20px',
                  padding: '10px 20px',
                  border: filter === value ? 'none' : '1px solid #4a4a4a', // Dark gray border
                  backgroundColor: filter === value ? '#4a4a4a' : 'transparent', // Dark gray background
                  color: filter === value ? '#fff' : '#4a4a4a', // Text color
                  transition: 'background-color 0.3s, color 0.3s'
                }}
                onClick={() => handleFilterChange(value)}
              >
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </button>
            ))}
          </div>
          <div className="campaigns-container">
            {filteredCampaigns.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE).map((campaign, index) => (
              <div key={index} className="campaign-item is-half ml-2">
                <CampaignLive key={index} campaign={campaign} alignLeft={index % 2 === 0} />
              </div>
            ))}
          </div>
          <div className="pagination-container" role="navigation" aria-label="pagination">
            {currentPage > 1 && (
              <button className="pagination-previous" style={{ cursor: 'pointer' }} onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
            )}
            {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
              <button key={page} style={{ cursor: 'pointer' }} className={`pagination-link ${page === currentPage ? 'is-current' : ''}`} aria-label={`Goto page ${page}`} onClick={() => handlePageChange(page)}>
                {page}
              </button>
            ))}
            {currentPage < totalPages && (
              <button className="pagination-next"  style={{ cursor: 'pointer' }} onClick={() => handlePageChange(currentPage + 1)}>Next</button>
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className="dual-campaign-container">
      {loading ? (
        <div>Loading...</div>
      ) : (
        renderCampaigns()
      )}
    </div>
  );
};

export default ApproveCampaignPage;
