import React, { useState, useEffect } from 'react';
import { getInfluencerConformation } from '../components/api'; // Import the API function for approving influencers
import { approvelBrand } from '../components/infApi';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
const InfluencerConformationTableBrand = () => {
  const [conformation, setConformation] = useState([]);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { brandName } = useParams()
  console.log(brandName);

  useEffect(() => {
    fetchData();
  }, [brandName]);

  const fetchData = async () => {
    try {
      const response = await getInfluencerConformation();
      if (response.ok) {
        const data = await response.json();
        const brandConformation = data.filter(conformation => conformation.isbrandContent && brandName.includes(conformation.brandName));
        setConformation(brandConformation);
      } else {
        setError('Failed to fetch influencer deliverables');
      }
    } catch (error) {
      setError('Error fetching influencer deliverables: ' + error.message);
    }
  };
  

  const handleApprove = async (influencerId) => { // Update handleApprove to accept email address as parameter
    try {
      await approvelBrand(influencerId); // Call the API function to approve the influencer
      const response = await approvelBrand(influencerId);
      const data = await response.json();

      if (response.ok) {
        // Display the message "Campaign approved"
        alert('Approved successfully');
        // Update the campaigns state to reflect the change
        const updatedCampaigns = conformation.map(conformation => {
          if (conformation._id === influencerId) {
            return { ...conformation, approvedbyBrand: true };
          }
          return conformation;
        });
        setConformation(updatedCampaigns);
      } else {
        console.error('Failed to approve campaign:', data.message);
      }
    } catch (error) {
      console.error('Error approving campaign:', error.message);
    }
  };


  return (
    <div>
      <h2>Influencer Conformation Data</h2>
      {error && <p>{error}</p>}
      <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable is-responsive">
        <thead>
          <tr>
            {/* <th className="customStyle">Instagram Username</th> */}
            <th className="customStyle">Instgram Profile link</th>
            <th className="customStyle">Phone</th>
            <th className="customStyle">Email</th>
            {/* <th className="customStyle">Full Name</th> */}
            <th className="customStyle">Whatsapp number</th>
            <th className="customStyle">Pincode</th>
            <th className="customStyle">Full Adress</th>
            <th className="customStyle">Landmark</th>
            <th className="customStyle">State</th>
            <th className="customStyle">City</th>
            
            <th className="customStyle">Revert or pyment </th>
            <th className="customStyle"> Action</th>
          </tr>
        </thead>
        <tbody>
          {conformation.map(influencer => (
            <tr key={influencer._id}>
              {/* <td className="customStyle">{influencer.InstagramUsername}</td> */}
              <td className="customStyle">{influencer.InstagramUrlLink}</td>
              {/* <td className="customStyle">{influencer.FullName}</td> */}
              
              <td className="customStyle">{influencer.PhoneNumber}</td>
              <td className="customStyle">{influencer.Email}</td>
              <td className="customStyle">{influencer.WhatsappNumber}</td>
              <td className="customStyle">{influencer.PinCode}</td>
             
              <td className="customStyle">{influencer.FullAddress}</td>
              <td className="customStyle">{influencer.Landmark}</td>
              <td className="customStyle">{influencer.State}</td>
              <td className="customStyle">{influencer.City}</td>
             
             
              <td className="customStyle">{influencer.RevertOrPayment}</td>

              <td>
                <button onClick={() => handleApprove(influencer._id)} className="button is-primary">Approve</button>

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InfluencerConformationTableBrand;
