import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { signIn } from '../components/api2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo192.png';

const UserSignIn = () => {
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    //    const{brandName} = useParams();
    //    console.log(brandName);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    // console.log("brandName", brandName);
    const handleSignin = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);

            const res = await signIn({ email: Email, password: Password, });

            if (!res.error) {
                const role = res.role || 'staff'; // Assuming role is part of the response, default to 'staff'

                // Improved redirect logic using a lookup object for cleaner routing:
                const roleToPathMap = {
                    admin: '/administration',
                    staff: '/staffdashboard',
                    // Add other roles and paths here as needed
                };

                const redirectPath = roleToPathMap[role] || '/default-dashboard'; // Fallback for unknown roles
                const { brandName } = res;
                console.log(brandName);
                navigate(redirectPath, { replace: true, state: { brandName, role: role } });
            } else {
                toast.error(res.error);
            }
        } catch (err) {
            // Handle error (consider logging to a service or displaying a user-friendly error message)
            console.error('Error during sign-in:', err); // Example error handling
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className='imgfull'>
            <div className='container1'>
             
                <div className='logo-card '>
                    <figure className="image is-64x64">
                        <img src={logo} alt="Logo" />
                    </figure>
                </div>
                <h1 className='title has-text-white ' style={{marginBottom:"-20px"}}>Sign In</h1>
                <form>
                    <div className='my-2 has-text-left'>
                        <label className='label has-text-white'>Email</label>
                    </div>
                    <div className='field'>
                        <div className='control has-icons-left'>
                            <input
                                className='input is-rounded'
                                type='text'
                                placeholder='Email'
                                name='email'
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                            </span>
                        </div>
                    </div>
                    <div className='my-2 has-text-left'>
                        <label className='label has-text-white'>Password</label>
                    </div>
                    <div className='field'>
                        <div className="control has-icons-left has-icons-right is-flex">
                            <input
                                className="input is-rounded"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                value={Password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-key"></i>
                            </span>
                            <span className="icon is-small is-right" onClick={togglePasswordVisibility} style={{ cursor: 'pointer', 'pointer-events': 'all' }}>
                                <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
                            </span>
                            {/* <div className="control">
                                <button
                                    className={`button ${showPassword ? 'is-primary' : 'is-light'}`}
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                >
                                    <span className="icon is-small">
                                        <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
                                    </span>
                                </button>
                            </div> */}
                        </div>
                    </div>
                    {/* <div className='Link mb-3'>
                        <Link to='/forget-password'>
                            <p className='has-text-right has-text-white'>Forgot Your Password?</p>
                        </Link>
                    </div> */}
                    <div className='field'>
                        <div className='control'>
                            <button
                                className='button is-primary is-rounded is-focused is-medium is-fullwidth'
                                disabled={!Email || !Password || loading}
                                type='submit'
                                onClick={handleSignin}
                            >
                                {loading ? 'Loading...' : 'SIGN IN'}
                            </button>
                        </div>
                    </div>
                </form>
                {/* <div className='is-flex has-text-left mr-5 mt-3'>
                    <p className='has-text-white'>Don't have an account?</p>
                    <Link to='/sign-up'>
                        <span className='has-text-weight-bold ml-2'>Sign Up</span>
                    </Link>
                </div> */}
            </div>
        </div>
    );
};

export default UserSignIn;
