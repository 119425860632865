import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import BrandDashboard1 from './influencerTable';
import InfluencerDeliverablesTable from './infDeliverables';
import InfluencerConformationTableExecutive from './infConfTableExecutive';
const ViewMoreExecutive = ({ campaign }) => {
    // const [showInfluencerTable, setShowInfluencerTable] = useState(false);
    // const [showInfluencerDeliverables, setShowInfluencerDeliverables] = useState(false);
    // const [ShowInfluencerConformationTable, setShowInfluencerConformationTable] = useState(false);
    // const [brandName, setBrandName] = useState("");

    // const handleInfluencerApplicationsClick = () => {
    //     setShowInfluencerTable(true);
    //     setShowInfluencerDeliverables(false);
    //     setShowInfluencerConformationTable(false);
    //     // setBrandName(brandName);
    // };

    // const handleInfluencerDeliverablesTableAdmin = () => {
    //     setShowInfluencerDeliverables(true);
    //     setShowInfluencerTable(false);
    //     setShowInfluencerConformationTable(false);
    // };
    // const handleInfluencerConformationTable = () => {
    //     setShowInfluencerConformationTable(true);
    //     setShowInfluencerDeliverables(false);
    //     setShowInfluencerTable(false);
    // }
    // useEffect(() => {
    //     if (campaign) {
    //         setBrandName(campaign.brandName);
    //     }
    // }, [campaign]);
    const { brandName } = useParams();

    return (
        <div className='container has-text-centered mt-3'>
            <div style={{ position: 'relative', overflowY: "auto" }}>
                {/* <button className="mr-2" onClick={handleInfluencerApplicationsClick} campaign={campaign} brandName={brandName}>Influencer Applications</button> */}
                <div>
                    <BrandDashboard1 campaign={campaign} brandName={brandName} />

                </div>
                {/* <button className="mr-2" onClick={handleInfluencerDeliverablesTableAdmin} campaign={campaign} brandName={brandName}>Influencer Deliverables</button> */}
                <div style={{ position: 'absolute', top: '4.7rem', left: '119rem' }}>
                    <InfluencerDeliverablesTable campaign={campaign} brandName={brandName} />
                </div>
                {/* <button className="mr-2 " onClick={handleInfluencerConformationTable}>Influencer Conformation</button> */}
                <div style={{ position: 'absolute', top: '4.7rem', left: '153rem' }}>
                    <InfluencerConformationTableExecutive campaign={campaign} brandName={brandName} />
                </div>
            </div>
        </div>
    );
}
export default ViewMoreExecutive