import React, { useState } from 'react';
import ShortlistedInfluencer from './ShortlistedInfluencer';
import { useParams,useNavigate } from 'react-router-dom';
import InfluencerTableBrand from './InfluencerTableBrand';
import InfluencerDeliverablesTableBrand from './infDeliverablesBrand';
import InfluencerConformationTableBrand from './infConfTableBrand';
const ViewMore = ({ campaign }) => {
    const navigate = useNavigate();

    
    const handleShortlistedInfluencerClick = () => {
        navigate(`/shortlistedinfluencer/${brandName}`);
    };
    const { brandName } = useParams();

    return (
        <div className='container has-text-centered mt-3'>

            <div style={{ position: 'relative', overflowY: "auto" }}>
                
                <div style={{ top: '8rem' }}>
                    <InfluencerTableBrand campaign={campaign} brandName={brandName} />
                </div>

               
                <div style={{ position: 'absolute', top: '0px', left: '126rem' }}>
                    <InfluencerDeliverablesTableBrand campaign={campaign} brandName={brandName} />
                </div>
               
                <div style={{ position: 'absolute', top: '0rem', left: '156rem' }}>
                    <InfluencerConformationTableBrand campaign={campaign} brandName={brandName} />
                  
                </div>
                <div style={{ position: 'absolute', top: '0rem', left: '220.5rem' }}>
                {/* <button onClick={handleShortlistedInfluencerClick} style={{cursor: "pointer" }}>
                        Open Shortlisted Influencer
                    </button> */}
                        <ShortlistedInfluencer campaign={campaign} brandName={brandName} />
                </div>
            </div>
        </div>
    );
}

export default ViewMore;
