import React, { useState, useEffect } from 'react'
// import { getInfluencerConformation } from '../components/api';
import { Config } from '../.env.js';
import { useNavigate } from 'react-router-dom';
const InfluencerconfirmationPage = ({ campaign }) => {
    const navigate = useNavigate();
    const [InstagramUsername, setInstagramUsername] = useState("");
    const [InstagramUrlLink, setInstagramUrlLink] = useState("")
    const [FullName, setFullName] = useState("")
    const [Email, setemail] = useState("");
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [WhatsappNumber, setWhatsappNumber] = useState("");
    const [FullAddress, setFullAddress] = useState("");
    const [Landmark, setLandmark] = useState("")
    const [City, setCity] = useState("")
    const [State, setState] = useState("")
    const [PinCode, setPinCode] = useState("")
    const [ Mode, setMode] = useState("");
    const [Radio1, setRadio1] = useState("")
    const [radio2, setradio2] = useState("")
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    // const [brandName, setBrandName] = useState(campaign.brandName || "");




    //mobile number validation regx
    let hasValNumber = /^[0-9]{1,10}$/.test(PhoneNumber);
    let hasWhatsappVal = /^[0-9]{1,10}$/.test(WhatsappNumber);
    // username validations regx

    let hasValFullName = /^[a-zA-Z_ ]{3,30}$/.test(FullName);

    //email validatin regx
    let hasValEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(Email);

    // pin code regx
    let hasValPinCode = /^\d{6}$/.test(PinCode)
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create an object with form data
        const formData = {
            InstagramUsername,
            InstagramUrlLink,
            FullName,
            Email,
            PhoneNumber,
            WhatsappNumber,
            FullAddress,
            Landmark,
            City,
            State,
            PinCode,
            // Radio1,
            // radio2,
            Mode,
            isexecutiveContent: campaign.isexecutiveContent,
            isleadContent: campaign.isleadContent,
            isadminContent: campaign.isadminContent,
            isbrandContent: campaign.isbrandContent,
            brandName: campaign.brandName,
            campaignName: campaign.campaignName

        };



        try {
            // Call your API endpoint to submit the form data
            const response = await fetch(`${Config.API_URL}/api/influencers/conformation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                // Handle success
                console.log('Form submitted successfully!');
                navigate('/success-page');
            } else {
                // Handle error
                console.error('Error submitting form:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (

        <div className='container subtitle has-text-centered my-5 ' >
            <div className='is-child box'>
                <h1 className='underline mt-3'>Deliverables</h1>
                <h1 className='underline mt-3'> 1 Instagram Reel + 2 Stories</h1>

                <h1 className='my-3'>( YOU CANNOT BACKOUT AFTER FILLING THIS FORM )</h1>
                <form onSubmit={handleSubmit}>
                    <div className='form my-3'>
                        <div className='control'>
                            <div className='colomn'>
                                <label className="label has-text-left ml-3">Full Name</label>
                                <input className='input is-rounded'
                                    type="text"
                                    placeholder="Full Name"


                                    value={FullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                />

                                {/* Useraname validation */}
                                {FullName && !hasValFullName && (
                                    <div>
                                        <small
                                            className={`ml-1 mt-1  ${!hasValFullName && FullName !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                            {!hasValFullName && FullName !== '' ? 'Please Enter valid Fullanme' : ''}
                                        </small>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <div className='form'>
                        <div className='control'>

                            <div className='colomn'>
                                <div className='has-text-left ml-2 my-1 has-text-weight-bold'>
                                    <label>Instagram Username</label>
                                </div>
                                <div className='my-1 ml-2 has-text-left'>
                                    <p >(Please don't use @ or put spaces or add your<br /> profile link. Only usernames are accepted.<br /> For Example: virat.kohli, norafatehi etc.)</p>
                                </div>

                                <input className='input is-rounded'
                                    type="text"
                                    placeholder="Instagram Username"

                                    value={InstagramUsername}
                                    onChange={(e) => setInstagramUsername(e.target.value)}
                                />


                            </div>

                        </div>

                    </div> */}

                    {/* <div className='form my-3'>
                        <div className='control'>
                            <div className='colomn'>
                                <label className="label has-text-left ml-3">Instagram Profile Link</label>
                                <div className='has-text-left ml-2 my-1'>
                                    <p className='ml-4'>(Kindly copy the URL link of your profile and paste here)
                                    </p>
                                </div>
                                <input className='input is-rounded'
                                    type="text"
                                    placeholder="Instagram Profile Link"


                                    value={InstagramUrlLink}
                                    onChange={(e) => setInstagramUrlLink(e.target.value)}
                                />

                            </div>
                        </div>
                    </div> */}

                    {/* <div className='field '>
                        <label className="label has-text-left ml-3">Email
                        </label>
                        <div className="control ">
                            <div className='colomn'>
                                <input className="input is-rounded "
                                    type="text"
                                    placeholder="Email ID"
                                    value={Email}
                                    onChange={(e) => setemail(e.target.value)}
                                />
                                {Email && !hasValEmail && (
                                    <small
                                        className={`ml-1 mt-1  ${!hasValEmail && Email !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                        {!hasValEmail && Email !== '' ? ('Enter a valid email address') : ''}
                                    </small>
                                )}

                            </div>

                        </div>
                    </div> */}
                    {/* <div className='field '>
                        <label className="label has-text-left ml-3">Phone number (PLS DONT ADD +91 OR 0) </label>
                        <p className='my-1 ml-2 has-text-left'>(Make sure to put only the 10 digits of your <br />number while submitting the form)</p>
                        <div className="control  ">
                            <div className='colomn'>
                                <input className="input  is-rounded "
                                    type="text"
                                    placeholder="Phone Number"
                                    value={PhoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />

                                
                                {PhoneNumber && !hasValNumber && (
                                    <div>
                                        <small
                                            className={`ml-1 mt-1  ${!hasValNumber && PhoneNumber !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                            {!hasValNumber && PhoneNumber !== '' ? 'Please Enter valid Mobile number' : ''}
                                        </small>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div> */}
                    {/* whatsapp */}
                    {/* <div className='field '>
                        <label className="label has-text-left ml-3">WhatsApp number (PLS DONT ADD +91 OR 0) </label>
                        <p className='my-1 ml-2 has-text-left'>(Make sure to put only the 10 digits of your <br />number while submitting the form)</p>
                        <div className="control  ">
                            <div className='colomn'>
                                <input className="input  is-rounded "
                                    type="text"
                                    placeholder="WhatsApp Number"
                                    value={WhatsappNumber}
                                    onChange={(e) => setWhatsappNumber(e.target.value)}
                                />

                                
                                {WhatsappNumber && !hasWhatsappVal && (
                                    <div>
                                        <small
                                            className={`ml-1 mt-1  ${!hasWhatsappVal && WhatsappNumber !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                            {!hasWhatsappVal && WhatsappNumber !== '' ? 'Please Enter valid Whatsapp Number' : ''}
                                        </small>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div> */}
                    <div className='form'>
                        <div className='control'>
                            <label className="label has-text-left ml-3">Your Full Address (with House Number)</label>
                            <input className='input is-rounded'
                                type="text"
                                placeholder="Your Full Address"
                                value={FullAddress}
                                onChange={(e) => setFullAddress(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='form'>
                        <div className='control'>
                            <label className="label has-text-left ml-3">Landmark</label>
                            <input className='input is-rounded'
                                type="text"
                                placeholder="Landmark"
                                value={Landmark}
                                onChange={(e) => setLandmark(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='form'>
                        <div className='control'>
                            <label className="label has-text-left ml-3">City</label>
                            <input className='input is-rounded'
                                type="text"
                                placeholder="City"
                                value={City}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='form'>
                        <div className='control'>
                            <label className="label has-text-left ml-3">State</label>
                            <input className='input is-rounded'
                                type="text"
                                placeholder="State"
                                value={State}
                                onChange={(e) => setState(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='form'>
                        <div className='control'>
                            <label className="label has-text-left ml-3">Pin Code</label>
                            <input className='input is-rounded'
                                type="number"
                                placeholder="Pin Code"
                                value={PinCode}
                                onChange={(e) => setPinCode(e.target.value)}
                            />
                            {PinCode && !hasValPinCode && (
                                <div>
                                    <small
                                        className={`ml-1 mt-1  ${!hasValPinCode && PinCode !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                        {!hasValPinCode && PinCode !== '' ? 'Please Enter valid pin code' : ''}
                                    </small>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='form'>
                        <div className="control">
                            <label className="label has-text-left ml-3 my-2">If you are not able to submit the deliverbles with 3 days.</label>
                            <div className="control">
                                <label className="checkbox">
                                <input type="checkbox" name="InstagramUsername" value="revert" checked={InstagramUsername === 'revert'} onChange={(e) => setInstagramUsername(e.target.checked ? 'revert' : '')} />

                                    You will revert the product back by shipping them <br/>on your own to the location which we will be providing
                                </label>
                                <label className="checkbox">
                                    <input type="checkbox" name="InstagramUsername" value="payment" checked={InstagramUsername === 'payment'} onChange={(e) => setInstagramUsername(e.target.checked ? 'payment' : '')} />
                                    You will be doing the payment for product cost
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* <div className='form'>
                        <div className="control">

                            <label className="label has-text-left ml-3 my-2">If you are not able to submit the deliverbles with 3 days.
                            </label>
                            <div className='my-1 ml-2 has-text-left'>
                                <label
                                    className="radio mb-2">
                                    <input className="mr-2"
                                        type='radio'
                                        name="option"
                                        value="revert"
                                        checked={Radio1 === 'revert'}
                                        onChange={(e) => setRadio1(e.target.value)} />
                                    You will revert the product back by shipping them on your own to the<br />location which we will be providing
                                </label>
                            </div>
                            <div className='my-1 ml-2 has-text-left'>
                                <label className="radio">
                                    <input type="radio" className='mr-2' name="option"
                                        value="payment"
                                        checked={radio2 === 'payment'}
                                        onChange={(e) => setradio2(e.target.value)} />
                                    You will be doing the payment for product cost
                                </label>
                            </div>
                        </div>
                    </div> */}
                    {campaign.isexecutiveContent && (
                        <div>
                            <p>isexecutiveContent: true</p>
                        </div>
                    )}

                    {campaign.isleadContent && (
                        <div>
                            <p>isleadContent: true</p>
                        </div>
                    )}

                    {campaign.isadminContent && (
                        <div>
                            <p>isadminContent: true</p>
                        </div>
                    )}

                    {campaign.isbrandContent && (
                        <div>
                            <p>isbrandContent: true</p>
                        </div>
                    )}

                    <div className='form'>
                        <div className='control'>
                            <button className="button is-primary is-rounded  is-focused is-medium is-fullwidth my-4" disabled={!FullName || !hasValFullName || !FullAddress || !Landmark || !City || !State || !PinCode || !hasValPinCode }>
                                SUBMIT
                            </button>

                        </div>
                    </div>
                </form>
            </div>
        </div>

    )
}

export default InfluencerconfirmationPage