// campaignService.js
import { Config } from '../.env.js';
export const updateCampaign = async (campaignId, updatedData) => {
    try {
        const response = await fetch(`${Config.API_URL}/campaigns/${campaignId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedData)
        });
        if (response.ok) {
            const data = await response.json();
            console.log(data.message);
            // Handle success
        } else {
            throw new Error('Failed to update campaign');
        }
    } catch (error) {
        console.error('Error updating campaign:', error);
        // Handle error
    }
};

export default updateCampaign;
