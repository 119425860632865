import React, { useState, useEffect } from 'react';
import { getBrandCampaigns1 } from '../components/api';
import { useNavigate } from 'react-router-dom';
import { approveCampaign, createCampaign, updateApprove } from '../components/campaignApi';
import { updateCampaign } from "../components/apiEdit";
import { approve, rejectedCampaignBrand } from '../components/infApi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const BrandTable1 = ({ campaign }) => {


    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState([]);
    const [editingCampaign, setEditingCampaign] = useState(null);
    const [approvedCampaigns, setApprovedCampaigns] = useState([]); // State to hold approved campaigns
    const [prevApprovedCampaigns, setPrevApprovedCampaigns] = useState("");
    const [error, setError] = useState(null);

    const [uploadBrandLogo, setUploadBrandLogo] = useState(null);

    useEffect(() => {
        fetchBrandCampaigns();
        // fetchAllCampaigns();
    }, []);

    const fetchBrandCampaigns = async () => {
        try {
            const response = await getBrandCampaigns1();
            const data = await response.json();
            if (response.ok) {
                setCampaigns(data);
            } else {
                setError(data.message || 'Failed to fetch brand campaigns');
            }
        } catch (error) {
            setError('Error fetching brand campaigns: ' + error.message);
        }
    };

    const handleApprove = async (campaignId) => {
        try {
            const approved = campaigns.find(campaign => campaign._id === campaignId);

            console.log('Approved campaign:', approved);
            toast.success("Approve successfully");
            // Check if the campaign has already been rejected
            if (approved.rejected) {
                toast.warning('This campaign has already been rejected and cannot be approved.');
                return;
            }

            const existingCampaign = campaigns.find(campaign =>
                campaign.brandName === approved.brandName &&
                // campaign.yourName === approved.yourName &&
                // campaign.contact === approved.contact &&
                // campaign.brandInfo === approved.brandInfo &&
                // campaign.email === approved.email &&
                campaign.followersCriteria === approved.followersCriteria &&
                campaign.deliverables === approved.infDeliverables &&
                campaign.giveproductsworthRs === approved.campaignName &&
                // campaign.brandUSP === approved.brandUSP &&
                campaign.uploadBrandPhoto === approved.uploadBrandLogo &&
                campaign.approve === approved.approve
            );

            // Create the campaign
            // await createCampaign(existingCampaign);

            //    await updateApprove(campaignId);

            // Proceed with approval
            await approve(campaignId); // Call the approveCampaign API function
            await updateApprove(campaignId);
            // Update the local campaigns array to mark the campaign as approved
            const updatedCampaigns = campaigns.map(campaign => {
                if (campaign._id === campaignId) {
                    return { ...campaign, approve: true };
                }
                return campaign;
            });

            // Set the updated campaigns array
            setCampaigns(updatedCampaigns);

            // Show alert for successful approval
            toast.success('Campaign approved successfully');
        } catch (error) {
            setError('Error approving campaign: ' + error.message);
            console.error('Error approving campaign:', error);
        }
    };

    const handleReject = async (campaignId) => {
        try {
            console.log('Campaign rejected:', campaignId);
            // Prompt user for remarks


            // Check if the campaign has already been approved
            const rejectedCampaign = campaigns.find(campaign => campaign._id === campaignId);

            // Check if the campaign has already been approved
            if (rejectedCampaign && rejectedCampaign.approved) {
                // If the campaign is already approved, show an alert message
                toast.warning('This campaign has already been approved and cannot be rejected.');
                return;
            }
            const remarks = prompt('Campaign rejected. Please provide remarks:');
            if (remarks === null) {
                // If user cancels, do nothing
                return;
            }
            const existingCampaign = campaigns.find(campaign =>
                campaign.brandName === rejectedCampaign.brandName &&
                // campaign.yourName === approved.yourName &&
                // campaign.contact === approved.contact &&
                // campaign.brandInfo === approved.brandInfo &&
                // campaign.email === approved.email &&
                campaign.followersCriteria === rejectedCampaign.followersCriteria &&
                campaign.deliverables === rejectedCampaign.deliverables &&
                campaign.giveproductsworthRs === rejectedCampaign.giveproductsworthRs &&
                // campaign.brandUSP === approved.brandUSP &&
                campaign.uploadBrandPhoto === rejectedCampaign.uploadBrandPhoto &&
                campaign.rejacted === rejectedCampaign.rejacted
            );
            // await createCampaign({ ...existingCampaign, rejected: true });
            // Call the rejectedCampaignBrand API function and pass remarks
            const response = await rejectedCampaignBrand(campaignId, remarks);

            // Check if the response is successful
            if (response && response.success) {
                // If successful, create a new campaign with the rejected campaign's data
                const rejectedCampaign = campaigns.find(campaign => campaign._id === campaignId);
                // Set the "rejected" field to true in the rejected campaign data
                rejectedCampaign.rejected = true;
                // Update the campaign in the campaign database
                await updateCampaign(campaignId, rejectedCampaign);
                // Show success message
                toast.success('Campaign rejected and updated successfully');
                console.log('Remarks:', remarks); // Display remarks
            } else {
                // If not successful, log error message
                console.error('Failed to reject campaign:', response.message);
            }
        } catch (error) {
            // Handle any errors that occur during the rejection process
            toast.error('Error rejecting campaign: ' + error.message);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        // Extracting just the file name
        const fileName = file.name;
        setUploadBrandLogo(file);
        setEditingCampaign({ ...editingCampaign, uploadBrandLogo: fileName });
    };



    const handleAssign = (campaignId) => {
        // Logic to handle approval action for the campaign with the given ID
        console.log('Campaign approved:', campaignId);
        // You can perform additional actions here, such as sending a request to your backend to update the approval status
    };
    const constructViewPhotoLink = (filename) => {
        return `/uploads/${encodeURIComponent(filename)}`;
    };

    const [isadmin, setisAdmin] = useState(false);
    const [islead, setisLead] = useState(false);
    const [isexecutive, setisExecutive] = useState(false);
    const [isbrand, setisBrand] = useState(false);

    const [isadminContent, setisAdminContent] = useState(false);
    const [isleadContent, setisLeadContent] = useState(false);
    const [isexecutiveContent, setisExecutiveContent] = useState(false);
    const [isbrandContent, setisBrandContent] = useState(false);

    const [products, setProducts] = useState(false);
    const [other, setOther] = useState(false);
    const [CashMoney, setCashMoney] = useState(false);

    const [male, setMale] = useState(false);
    const [female, setfemale] = useState(false);
    const [transgender, setTransgender] = useState(false);
    const handleEdit = (campaign) => {
        // Copy the campaign object to avoid mutating the original data
        const editedCampaign = { ...campaign };
        setEditingCampaign(editedCampaign);

        setisAdmin(editedCampaign.isAdmin);
        setisLead(editedCampaign.isLead);
        setisExecutive(editedCampaign.isExecutive);
        setisBrand(editedCampaign.isBrand);
        setisAdminContent(editedCampaign.isadminContent);
        setisLeadContent(editedCampaign.isleadContent);
        setisExecutiveContent(editedCampaign.isexecutiveContent);
        setisBrandContent(editedCampaign.isbrandContent);

        setProducts(editedCampaign.products);
        setCashMoney(editedCampaign.CashMoney);
        setOther(editedCampaign.other);

        setMale(editedCampaign.male);
        setfemale(editedCampaign.female);
        setTransgender(editedCampaign.transgender);
    };
    // const [isadmin, setisAdmin] = useState(false);
    // const [islead, setisLead] = useState(false);
    // const [isexecutive, setisExecutive] = useState(false);
    // const [isbrand, setisBrand] = useState(false);
    // const [filteredCampaigns, setFilteredCampaigns] = useState([]);
    // const [selectedOptions, setSelectedOptions] = useState([]);
    // useEffect(() => {
    //     if (editingCampaign) {
    //         const options = [];
    //         if (editingCampaign.products) {
    //             options.push('Products');
    //         } if (editingCampaign.other) {
    //             options.push('Other');
    //         } if (editingCampaign.CashMoney) {
    //             options.push('Cash/Money');
    //         }
    //         setSelectedOptions(options);
    //     }
    // }, [editingCampaign]);


    const handleCheckboxChange2 = (e) => {
        const { name } = e.target;
        switch (name) {
            case 'products':
                setProducts(prevState => !prevState);
                break;
            case 'CashMoney':
                setCashMoney(prevState => !prevState);
                break;
            case 'other':
                setOther(prevState => !prevState);
                break;
            default:
                break;
        }
    };

    const handleCheckboxgenderchange = (e) => {
        const { name } = e.target;
        switch (name) {
            case 'male':
                setMale(prevState => !prevState);
                break;
            case 'female':
                setfemale(prevState => !prevState);
                break;
            case 'transgender':
                setTransgender(prevState => !prevState);
                break;
            default:
                break;
        }
    };


    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        switch (name) {
            case 'admin':
                setisAdmin(checked);
                break;
            case 'lead':
                setisLead(checked);
                break;
            case 'executive':
                setisExecutive(checked);
                break;
            case 'brand':
                setisBrand(checked);
                break;
            default:
                break;
        }
    };

    const handlechackboxesChange = (e) => {
        const { name, checked } = e.target;
        setEditingCampaign(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };


    const handleCheckboxChange1 = (e) => {
        const { name, checked } = e.target;
        switch (name) {
            case 'adminContent':
                setisAdminContent(checked);
                break;
            case 'leadContent':
                setisLeadContent(checked);
                break;
            case 'executiveContent':
                setisExecutiveContent(checked);
                break;
            case 'brandContent':
                setisBrandContent(checked);
                break;
            default:
                break;
        }
    };


    const handleUpdate = async () => {
        try {
            const { _id, ...updatedData } = editingCampaign;
            // Remove _id field before updating
            const updatedCampaign = {
                ...updatedData,
                isAdmin: isadmin,
                isLead: islead,
                isExecutive: isexecutive,
                isBrand: isbrand,
                isadminContent: isadminContent,
                isleadContent: isleadContent,
                isexecutiveContent: isexecutiveContent,
                isbrandContent: isbrandContent,

                products: products,
                CashMoney: CashMoney,
                other: other,

                male: male,
                female: female,
                transgender: transgender
            };
            await updateCampaign(_id, updatedCampaign); // Send updated data to backend
            // If update successful, reset editingCampaign state and fetch updated campaigns
            setEditingCampaign(null);
            fetchBrandCampaigns();
            toast.success('Campaign updated successfully');
        } catch (error) {
            setError('Error updating campaign: ' + error.message);
            console.error('Error updating campaign:', error);
        }
    };

    const handleCancelEdit = () => {
        setEditingCampaign(null); // Reset editingCampaign state on cancel
    };
    const handleReuploadBrief = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        // Check if the selected file type is allowed
        if (!allowedTypes.includes(file.type)) {
            toast.error('Only PDF, DOC, and DOCX files are allowed please upload Other file');
            return;
        }
        
        setEditingCampaign(prevState => ({
            ...prevState,
            uploadBrief: file.name // Update the file name
        }));
    };

    const examplePdfUrl = 'https://docs.google.com/presentation/d/1Yf2C23GdKI1w4XxiM8MdxNGk7EBWB6LOAHjGitV_IOQ/edit#slide=id.gf9808550a8_0_6';

    const handleFollowersCriteriaChange = (value) => {
        let updatedCriteria = [...editingCampaign.followersCriteria];

        if (updatedCriteria.includes(value)) {
            updatedCriteria = updatedCriteria.filter(criteria => criteria !== value);
        } else {
            updatedCriteria.push(value);
        }

        setEditingCampaign(prevState => ({
            ...prevState,
            followersCriteria: updatedCriteria
        }));
    };
    return (
        <div className="container mt-3" >

            {error && <p>Error: {error}</p>} {/* Display error message if there's an error */}

            <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable is-responsive">
                <thead>
                    <tr>
                        <th className="customStyle">Brand Name</th>
                        <th className="customStyle">How many influencers are you looking forward to work with?</th>
                        <th className="customStyle"> Please specify the exact deliverables you want from Influencers</th>
                        <th className="customStyle">What would you be giving to influencers in exchange for
                            deliverables?</th>
                        <th className="customStyle">Target influencers' gender*</th>
                        <th className="customStyle">Followers criteria</th>
                        <th className="customStyle">What is your target audience age group?</th>
                        <th className="customStyle">Influencers Categories You Want to Target</th>
                        <th className="customStyle">Product Link</th>
                        <th className="customStyle"> For how many weeks are you planning to run the campaign?
                        </th>
                        <th className='customStyle'>Social Media Platform For Influencer Marketing</th>
                        <th className="customStyle">Campaign Name</th>
                        <th className="customStyle">Cover Image For The Campaign</th>
                        <th className='customStyle' > Brand Brief pdfs</th>
                        <th className="customStyle">Write location/city/state</th>
                        <th className="customStyle">Any other eligibility criteria to select Influencers?</th>
                        <th className="customStyle">Any message for us?</th>
                        <th className="customStyle">Action</th>
                        <th className="customStyle">Edit</th> {/* Add column for action buttons */}
                        {/* Add more table headers as needed */}
                    </tr>
                </thead>
                <tbody>
                    {campaigns.slice().reverse().map(campaign => (
                        <tr key={campaign._id}>
                            <td className="customStyle">{campaign.brandName}</td>
                            <td className="customStyle">{campaign.influencer}</td>
                            <td className="customStyle">{campaign.infDeliverables}</td>
                            <td>
                                {campaign.products && (
                                    <>
                                        {/* <input type="checkbox" checked readOnly /> */}
                                        <label htmlFor="products">Products</label>
                                    </>
                                )}
                                {campaign.CashMoney && (
                                    <>
                                        {/* <input type="checkbox" checked readOnly /> */}
                                        <label htmlFor="cashMoney">Cash/Money</label>
                                    </>
                                )}
                                {campaign.other && (
                                    <>
                                        {/* <input type="checkbox" checked readOnly /> */}
                                        <label htmlFor="other">Other</label>
                                    </>
                                )}
                            </td>
                            <td>{campaign.male ? 'Male' : campaign.female ? 'Female' : campaign.transgender ? 'Transgender' : 'Not specified'}</td>
                            <td className='customStyle'>{campaign.followersCriteria}</td>
                            <td className="customStyle">{campaign.ageGroup}</td>

                            <td>{campaign.cosmetics ? 'Cosmetics' : campaign.lifeStyle ? 'Lifestyle' : campaign.fashion ? 'Fashion' : campaign.food ? 'Food' : campaign.HealthFitness ? 'HealthFitnesss' : campaign.skincare ? 'Skincare' : campaign.haircare ? 'Haircare' : campaign.beauty ? 'Beauty' : campaign.travel ? 'Travel' : campaign.student ? 'Student' : campaign.houseWife ? 'Housewife' : campaign.gaming ? 'gaming' : campaign.dance ? 'Dance' : campaign.yoga ? "yoga" : campaign.gadgets ? 'Gadgets' : campaign.tech ? 'tech' : campaign.cooking ? 'Cooking' : campaign.workingClass ? 'WorkingClass' : campaign.smallEntrepreneurs ? 'smallEntrepreneurs' : campaign.diy ? 'diy' : campaign.electronics ? 'electronics' : 'not specified'}</td>
                            <td className="customStyle">{campaign.productLink}</td>
                            <td className="customStyle">{campaign.campainTime}</td>
                            <td className='customStyle'> {campaign.SocialmediaPlatform}</td>
                            <td className="customStyle">{campaign.campaignName}</td>
                            <td className="customStyle">
                                <a
                                    href={constructViewPhotoLink(campaign.uploadBrandLogo)}
                                    // target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    View Photo
                                </a>
                            </td>
                            <td className="customStyle">
                                <a
                                    href={constructViewPhotoLink(campaign.uploadBrief)}
                                    // target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    View Brief
                                </a>
                            </td>
                            <td className="customStyle">{campaign.location1}</td>
                            <td className="customStyle">{campaign.selectionCriteria}</td>

                            <td className="customStyle">{campaign.message}</td>
                            <td className="customStyle">
                                <button onClick={() => handleApprove(campaign._id)} disabled={campaign.rejected} className="button is-primary">Approve</button>
                                <button className="button is-danger ml-2" onClick={() => handleReject(campaign._id)} disabled={campaign.approve}>Reject</button>
                            </td>
                            <td className="customStyle">
                                {/* Edit button */}
                                <button onClick={() => handleEdit(campaign)} disabled={campaign.approve || campaign.rejected} className="button is-primary">Edit</button>
                            </td>

                            {/* Add more table cells to display other campaign data */}
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Edit campaign form */}
            {editingCampaign && (
                <div className="modal is-active" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-content" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '80%', maxWidth: '800px' }}>
                        <button className="delete" aria-label="close" onClick={handleCancelEdit}></button>
                        <h2 className="title is-4">Edit Campaign</h2>
                        {/* Input fields for editing campaign attributes */}
                        <div className='field'>
                            <label className='label'>Brand Name:</label>
                            <div className='control'>
                                <input className='input is-rounded'
                                    type="text"
                                    id="brandName"
                                    value={editingCampaign.brandName}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, brandName: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='field'>
                            <label className='label '> How many influencers are you looking forward to work with?:</label>
                            <div className='control'>
                                <input className='input is-rounded'
                                    type="text"
                                    id="influencer"
                                    value={editingCampaign.influencer}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, influencer: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='field'>
                            <label className='label'>Please specify the exact deliverables you want from Influencers:</label>
                            <div className='control'>
                                <input className='input is-rounded'
                                    type="text"
                                    id="infDeliverables"
                                    value={editingCampaign.infDeliverables}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, infDeliverables: e.target.value })}
                                />
                            </div>
                        </div>
                        {/* <div className="field">
                            <label className="label">What would you be giving to influencers in exchange for deliverables?:</label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="text"
                                    value={selectedOptions.join(', ')}
                                    readOnly
                                />
                            </div>
                        </div> */}
                        {/* <div className="field mr-6">
                            <label className="label has-text-left ml-3">What would you be giving to influencers in exchange for deliverables?</label>
                            {editingCampaign.products && (
                                <div>
                                    <input
                                        type="checkbox"
                                        name="products"
                                        checked={products}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label>Products</label>
                                </div>
                            )}
                            // {/* Checkbox for Cash/Money */}
                        {/* // {editingCampaign.CashMoney && ( */}
                        {/* //     <div>
                            //         <input
                            //             type="checkbox"
                            //             name="cashMoney"
                            //             checked={CashMoney}
                            //             onChange={handleCheckboxChange}
                            //         />
                            //         <label>Cash/Money</label>
                            //     </div>
                            // )} */}
                        {/* Checkbox for Other */}
                        {/* {editingCampaign.other && (
                                <div>
                                    <input
                                        type="checkbox"
                                        name="other"
                                        checked={other}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label>Other</label>
                                </div>
                            )} */}
                        {/* </div> */}
                        <div className="field mr-6">
                            <label className="label ">What would you be giving to influencers in exchange for deliverables?</label>

                            <div className="field has-text-left">
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        name='products'
                                        checked={products}
                                        onChange={handleCheckboxChange2}
                                    />
                                    Products
                                </label>
                            </div>
                            <div className="field has-text-left">
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        name='CashMoney'
                                        checked={CashMoney}
                                        onChange={handleCheckboxChange2}
                                    />
                                    Cash/Money
                                </label>
                            </div>
                            <div className="field has-text-left">
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        name='other'
                                        checked={other}
                                        onChange={handleCheckboxChange2}
                                    />
                                    Other
                                </label>
                            </div>
                        </div>


                        <div className="additionalQuestionContainer">
                            <label className="label">
                                Value (in Rs.) of the cash/products being given to influencers? *
                            </label>
                            <input
                                type="text"
                                className="input is-rounded"
                                placeholder="Your answer"
                                placeholderTextColor="#999"
                                value={editingCampaign.cashValue}
                                onChange={e => setEditingCampaign({ ...editingCampaign, cashValue: e.target.value })}
                            />

                        </div>


                        <div className='field'>
                            <label className='label'>Target influencers' gender*:</label>
                            <div className="checkboxContainer mr-6">
                                <input
                                    type="checkbox"
                                    name="male"
                                    checked={male}
                                    onChange={handleCheckboxgenderchange}
                                />
                                <label className="checkboxLabel mr-6">Male</label>
                                <input
                                    type="checkbox"
                                    name="female"
                                    checked={female}
                                    onChange={handleCheckboxgenderchange}

                                />
                                <label className="checkboxLabel mr-6">Female</label>
                                <input
                                    type="checkbox"
                                    name="transgender"
                                    checked={transgender}
                                    onChange={handleCheckboxgenderchange}
                                    className="checkbox"
                                />
                                <label className="checkboxLabel mr-6">Transgender</label>
                            </div>
                        </div>
                        <div className='field'>
                            <label className='label'> Followers criteria:</label>
                            <div>
                                <label className="checkbox mr-4">
                                    <input
                                        type="checkbox"
                                        value="1-2000"
                                        checked={editingCampaign.followersCriteria.includes("1-2000")}
                                        onChange={(e) => handleFollowersCriteriaChange(e.target.value)}
                                    />
                                    Less than 2K
                                </label>
                                <label className="checkbox mr-4">
                                    <input
                                        type="checkbox"
                                        value="2000-10000"
                                        checked={editingCampaign.followersCriteria.includes("2000-10000")}
                                        onChange={(e) => handleFollowersCriteriaChange(e.target.value)}
                                    />
                                    2k-10k
                                </label>
                                <label className="checkbox mr-4">
                                    <input
                                        type="checkbox"
                                        value="10000-50000"
                                        checked={editingCampaign.followersCriteria.includes("10000-50000")}
                                        onChange={(e) => handleFollowersCriteriaChange(e.target.value)}
                                    />
                                    10k-50k
                                </label>
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        value="50000-100000"
                                        checked={editingCampaign.followersCriteria.includes("50000-100000")}
                                        onChange={(e) => handleFollowersCriteriaChange(e.target.value)}
                                    />
                                    50k-100k
                                </label>
                            </div>
                        </div>
                        <div className='field'>
                            <label className='label'> What is your target audience age group?:</label>
                            <div className='control'>
                                <input className='input is-rounded'
                                    type="text"
                                    id="deliverables"
                                    value={editingCampaign.ageGroup}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, ageGroup: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='field'>
                            <div className="control">
                                <div className='colomn'>
                                    <div className='my-2 has-text-left'>
                                        <strong className='label'>Influencers Categories You Want to Target *</strong>
                                    </div>
                                    <div className="checkboxContainer mr-6">
                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='skincare'
                                            checked={editingCampaign.skincare}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Skincare </label>


                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='cosmetics'
                                            checked={editingCampaign.cosmetics}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Cosmetics </label>


                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='lifeStyle'
                                            checked={editingCampaign.lifeStyle}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Lifestyle </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='haircare'
                                            checked={editingCampaign.haircare}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Haircare </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='beauty'
                                            checked={editingCampaign.beauty}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Beauty </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='food'
                                            checked={editingCampaign.food}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Food </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='fashion'
                                            checked={editingCampaign.fashion}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Fashion </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='HealthFitness'
                                            checked={editingCampaign.HealthFitness}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Health & Fitness </label>


                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='travel'
                                            checked={editingCampaign.travel}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Travel </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='student'
                                            checked={editingCampaign.student}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Student </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='houseWife'
                                            checked={editingCampaign.houseWife}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> HouseWife </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='gaming'
                                            checked={editingCampaign.gaming}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Gaming </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='dance'
                                            checked={editingCampaign.dance}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Dance </label>
                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='yoga'
                                            checked={editingCampaign.yoga}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Yoga </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='gadgets'
                                            checked={editingCampaign.gadgets}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Gadgets </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='tech'
                                            checked={editingCampaign.tech}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Tech </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='cooking'
                                            checked={editingCampaign.cooking}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Cooking </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='workingClass'
                                            checked={editingCampaign.workingClass}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Working Class </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='smallEntrepreneurs'
                                            checked={editingCampaign.smallEntrepreneurs}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4">Small Entrepreneurs </label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='diy'
                                            checked={editingCampaign.diy}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Diy</label>

                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            name='electronics'
                                            checked={editingCampaign.electronics}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label className="checkboxLabel mr-4"> Electronics </label>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div className='field'>
                            <label className='label'>Product Link:</label>
                            <div className='control'>
                                <input className='input is-rounded'
                                    type="text"
                                    id="productLink"
                                    value={editingCampaign.productLink}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, productLink: e.target.value })}
                                />
                            </div>
                        </div>

                        <div className='field'>
                            <label className='label'> For how many weeks are you planning to run the campaign?:</label>
                            <div className="select">
                                <select style={{ borderRadius: '20px', width: "900px" }}
                                    value={editingCampaign.campainTime}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, campainTime: e.target.value })}
                                >
                                    <option value="">Select </option>
                                    <option value="1">1 weeks</option>
                                    <option value="2">2 weeks</option>
                                    <option value="3">3 weeks</option>
                                    <option value="4">4 weeks</option>
                                    <option value="5">5 weeks</option>
                                    <option value="6">6 weeks</option>
                                    <option value="7">7 weeks</option>
                                    <option value="8">8 weeks</option>
                                    <option value="9">9 weeks</option>
                                    <option value="10">10 weeks</option>
                                    <option value="11">11 weeks</option>
                                    <option value="12">12 weeks</option>
                                    <option value="13">13 weeks</option>
                                    <option value="14">14 weeks</option>
                                    <option value="15">15 weeks</option>
                                    <option value="16">16 weeks</option>
                                    <option value="17">17 weeks</option>
                                    <option value="18">18 weeks</option>
                                    <option value="19">19 weeks</option>
                                    <option value="20">20 weeks</option>
                                    <option value="21">21 weeks</option>
                                    <option value="22">22 weeks</option>
                                    <option value="23">23 weeks</option>
                                    <option value="24">24 weeks</option>
                                    <option value="25">25 weeks</option>
                                    <option value="26">26 weeks</option>
                                    <option value="27">27 weeks</option>
                                    <option value="28">28 weeks</option>
                                    <option value="29">29 weeks</option>
                                    <option value="30">30 weeks</option>
                                    <option value="31">31 weeks</option>
                                    <option value="32">32 weeks</option>
                                    <option value="33">33 weeks</option>
                                    <option value="34">34 weeks</option>
                                    <option value="35">35 weeks</option>
                                    <option value="36">36 weeks</option>
                                    <option value="37">37 weeks</option>
                                    <option value="38">38 weeks</option>
                                    <option value="39">39 weeks</option>
                                    <option value="40">40 weeks</option>
                                    <option value="41">41 weeks</option>
                                    <option value="42">42 weeks</option>
                                    <option value="43">43 weeks</option>
                                    <option value="44">44 weeks</option>
                                    <option value="45">45 weeks</option>
                                    <option value="46">46 weeks</option>
                                    <option value="47">47 weeks</option>
                                    <option value="48">48 weeks</option>
                                    <option value="49">49 weeks</option>
                                    <option value="50">50 weeks</option>
                                    <option value="51">51 weeks</option>
                                    <option value="52">52 weeks</option>
                                </select>
                            </div>
                        </div>

                        <div className='field'>
                            <label className='label'> Social Media Platform For Influencer Marketing</label>
                            <div className="select">
                                <select style={{ borderRadius: '20px', width: "900px" }}
                                    value={editingCampaign.SocialmediaPlatform}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, SocialmediaPlatform: e.target.value })}
                                >
                                    <option value="">Select </option>
                                    <option value="Youtuble">You tuble</option>
                                    <option value="Moj">Moj</option>
                                    <option value="Chingari">Chingari</option>
                                    <option value="Facebook"> Facebook</option>
                                    <option value=" TikTok"> TikTok</option>
                                    <option value="MxTakaTak">MxTakaTak</option>
                                    <option value="Linkedin">Linkedin</option>
                                    <option value="Quora">Quora</option>
                                    <option value="Snapchat">Snapchat</option>
                                    <option value="Koo">Koo</option>
                                    <option value="Tumblr">Tumblr</option>
                                    <option value="Trell">Trell</option>
                                    <option value="Rizzle">Rizzle</option>
                                    <option value="Lumi">Lumi</option>
                                    <option value="Kutumb">Kutumb</option>
                                    <option value="Roposo">Roposo</option>
                                    <option value=" 9GAG"> 9GAG</option>
                                    <option value="Whatsapp">Whatsapp</option>
                                    <option value="Offline">Offline</option>
                                    <option value="X">X</option>
                                    <option value=" Own"> Own</option>

                                </select>
                            </div>
                        </div>
                        {/* <div className='field'>
                            <label className='label'>For how many weeks are you planning to run the campaign?:</label>
                            <div className='control'>
                                <input className='input'
                                    type="text"
                                    id="campainTime"
                                    value={editingCampaign.campainTime}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, campainTime: e.target.value })}
                                />
                            </div>
                        </div> */}
                        <div className='field'>
                            <label className='label'>Campaign Name:</label>
                            <div className='control'>
                                <input className='input is-rounded'
                                    type="text"
                                    id="campaignName"
                                    value={editingCampaign.campaignName}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, campaignName: e.target.value })}
                                />
                            </div>
                        </div>

                        <div className='field'>
                            <label className='label'>Cover Image For The Campaign:</label>
                            <div className='control'>
                                <input className='input is-rounded'
                                    type="text"
                                    id="uploadBrandLogo"
                                    value={editingCampaign.uploadBrandLogo}
                                    readOnly
                                    onChange={e => setEditingCampaign({ ...editingCampaign, uploadBrandLogo: e.target.value })}
                                />
                            </div>

                        </div>
                        <div>
                            <label className='label'>Reupload Brand Photo:</label>
                            <input
                                type="file"
                                id="uploadBrandLogo"
                                name='uploadBrandLogo'

                                onChange={handleImageChange}
                            />
                        </div>

                        <div className='field'>
                            <label className='label'>Upload Campaign Brief:</label>
                            <div className='control'>
                                <input style={{ borderRadius: '20px' }} className='input'
                                    type="text"
                                    id="uploadBrief"
                                    value={editingCampaign.uploadBrief}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, uploadBrief: e.target.value })}
                                    readOnly
                                />
                            </div>

                        </div>
                        <div>
                            <label className='label'>Upload the Campaign Brief Again:</label>
                            <input style={{ borderRadius: '20px' }}
                                type="file"
                                id="uploadBrief"
                                name='uploadBrief'
                                onChange={handleReuploadBrief}
                            />
                        </div>
                        <div className="label has-text-left ">
                            {/* <p>Example PDF</p> */}
                            <a href={examplePdfUrl} target="_blank" rel="noopener noreferrer">
                                Example PDF
                            </a>
                        </div>

                        <div className='field'>
                            <label className='label'> Write location/city/state :</label>
                            <div className='control'>
                                <input className='input is-rounded'
                                    type="text"
                                    id="location1"
                                    value={editingCampaign.location1}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, location1: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='field'>
                            <label className='label'>Any other eligibility criteria to select Influencers?:</label>
                            <div className='control'>
                                <textarea className='textarea' style={{ borderRadius: '20px' }}
                                    type="text "
                                    id="selectionCriteria"
                                    value={editingCampaign.selectionCriteria}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, selectionCriteria: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='field'>
                            <label className='label'>Any message for us?:</label>
                            <div className='control'>
                                <textarea
                                    className="textarea" style={{ borderRadius: '20px' }}
                                    type="text"
                                    id="message"
                                    value={editingCampaign.message}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, message: e.target.value })}
                                />
                            </div>
                        </div>


                        <label className='label'>who's approvel required for influencer selection</label>

                        <div className="field has-text-left">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="executive"
                                    checked={isexecutive}
                                    onChange={handleCheckboxChange}
                                />
                                Executive
                            </label>

                        </div>
                        <div className="field has-text-left">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="lead"
                                    checked={islead}
                                    onChange={handleCheckboxChange}
                                />
                                Lead
                            </label>
                        </div>
                        <div className="field has-text-left">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="admin"
                                    checked={isadmin}
                                    onChange={handleCheckboxChange}
                                />
                                Admin
                            </label>
                        </div>
                        <div className="field has-text-left">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="brand"
                                    checked={isbrand}
                                    onChange={handleCheckboxChange}
                                />
                                Brand
                            </label>
                        </div>

                        <label className='label'>Whose Approval Required For Influencer Content</label>
                        <div className="field">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="executiveContent"
                                    checked={isexecutiveContent}
                                    onChange={handleCheckboxChange1}
                                />
                                Executive
                            </label>
                        </div>
                        <div className="field">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="leadContent"
                                    checked={isleadContent}
                                    onChange={handleCheckboxChange1}
                                />
                                Lead
                            </label>
                        </div>
                        <div className="field">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="adminContent"
                                    checked={isadminContent}
                                    onChange={handleCheckboxChange1}
                                />
                                Admin
                            </label>
                        </div>

                        <div className="field">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="brandContent"
                                    checked={isbrandContent}
                                    onChange={handleCheckboxChange1}
                                />
                                Brand
                            </label>
                        </div>

                        {/* Add similar input fields for other campaign attributes */}

                        {/* Buttons for updating and canceling */}
                        <div className='field is-grouped'>
                            <div className='control'>
                                <button onClick={handleUpdate}>Update</button>
                            </div>
                            <div className='control'>
                                <button onClick={handleCancelEdit}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }


        </div >


    );
};

export default BrandTable1
