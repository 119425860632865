import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signup } from '../components/api2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo192.png';

const SignUp = () => {
    const navigate = useNavigate();
    const [yourName, setYourName] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [phone, setPhone] = useState("");
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");

    const hasValNumber = /^[0-9]{1,10}$/.test(phone);
    const hasValEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u.test(Email);
    const [loading, setLoading] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        try {
            const res = await signup({ yourName, email: Email, password: Password, phone });
            if (res.error) {
                toast.error(res.error);
            } else {
                toast.warning("Enter your OTP");
                navigate(`/otp/${Email}`, { replace: true });
            }
        } catch (error) {
            // Handle other errors
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='imgfull'>
            <div className='container1'>
                <div className='logo-card'>
                    <figure className="image is-64x64">
                        <img src={logo} alt="Logo" />
                    </figure>
                </div>
                <h1 className='title has-text-white'>Sign Up as a Brand</h1>
                <div className='form-container'>
                    <form>
                        <div className=' has-text-left my-input'>
                            <label className='label has-text-white ml-4'>Your Name</label>
                        </div>
                        <div className='field'>
                            <div className="control has-icons-left ">
                                <div className='column'>
                                    <input
                                        className="input is-rounded"
                                        type="text"
                                        placeholder="e.g. Naresh kumar"
                                        value={yourName}
                                        onChange={(e) => setYourName(e.target.value)}
                                    />
                                    <span className='icon is-small is-left my-3 ml-3'>
                                        <i className='fas fa-rocket'></i>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className=' has-text-left '>
                            <label className='label has-text-white ml-4'>Mobile Number</label>
                        </div>
                        <div className='field'>
                            <div className="control has-icons-left my-input">
                                <div className='column'>
                                    <input
                                        className="input is-rounded"
                                        type="text"
                                        placeholder="9898989898"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    {phone && !hasValNumber && (
                                        <div>
                                            <small className={`ml-1 mt-1 ${!hasValNumber && phone !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                                {!hasValNumber && phone !== '' ? 'Please Enter valid 10 digit indian Mobile number without 0/+91' : ''}
                                            </small>
                                        </div>
                                    )}
                                    <span className='icon is-small is-left  my-3 ml-3'>
                                        <i className='fas fa-phone'></i>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='has-text-left '>
                            <label className='label has-text-white ml-4'>Your Business Email</label>
                        </div>
                        <div className='field'>
                            <div className="control has-icons-left my-input">
                                <div className='column'>
                                    <input
                                        className="input is-rounded"
                                        type="text"
                                        placeholder="naresh.kumar@company.com"
                                        value={Email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {Email && !hasValEmail && (
                                        <small className={`ml-1 mt-1 ${!hasValEmail && Email !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                            {!hasValEmail && Email !== '' ? 'Enter a valid email address' : ''}
                                        </small>
                                    )}
                                    <span className="icon is-small is-left my-3 ml-3">
                                        <i className="fas fa-envelope"></i>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className=' has-text-left '>
                            <label className='label has-text-white ml-4'>Password</label>
                        </div>
                        <div className='field'>
                    <div className="control has-icons-left has-icons-right is-flex my-input">
                        <input
                            className="input is-rounded"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            value={Password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-key"></i>
                        </span>
                        <span className="icon is-small is-right" onClick={togglePasswordVisibility} style={{ cursor: 'pointer','pointer-events': 'all'}}>
                            <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
                        </span>
                    </div>
                </div>

                        <div className="field">
                            <div className="control my-input">
                                <Link to="/otp">
                                    <button
                                        className="button is-primary is-rounded is-focused is-medium is-fullwidth"
                                        disabled={!yourName || !phone || !Email || !Password || loading || !hasValNumber || !hasValEmail}
                                        onClick={handleSignup}
                                    >
                                        {loading ? 'Loading...' : 'SIGN UP'}
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div className='is-flex justify-content:flex-start text has-text-left ml-5' >
                            <p className='has-text-white ' style={{marginLeft:"184px"}}>Have an account?</p>
                            <Link to='/signinbrand'>
                                <span className='has-text-weight-bold ml-2'>Sign in</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignUp;

