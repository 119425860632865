import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Executivesignup } from "../Dashboard/user";
import logo from '../logo192.png';

const SignupExecutive = () => {
    const navigate = useNavigate();
    const [username, setusername] = useState("");
    const [email, setemail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);


    let hasValEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u.test(email);

    // password validations regx

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };



    // const setSignedUp = (isSignedUp) => {
    //     localStorage.setItem('isSignedUpExecutive', isSignedUp);
    // };

    // const getSignedUp = () => {
    //     const isSignedUp = localStorage.getItem('isSignedUpExecutive');
    //     return isSignedUp === 'true';
    // };

    // useEffect(() => {
    //     const isSignedUp = getSignedUp();
    //     if (isSignedUp) {
    //         navigate("/executive-signin", { replace: true });
    //     }
    // }, [navigate]);

    const handleExecutivesignup = async (e) => {
        e.preventDefault();
        try {
            const res = await Executivesignup({ username, email, password });
            if (res.error) {
                toast.error(res.error);
            }
            else {
                toast.success(res.message);
                // Redirect to the admin signin page
                navigate(`/otpexecutivesignup/${email}`, { replace: true });
            }
        } catch (err) {
            // alert(err)
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <div className='imgfull'>
            <div className='container1 '>
                <div className='logo-card'>
                    <figure className="image is-64x64">
                        <img src={logo} alt="Logo" />
                    </figure>
                </div>
                <h1 className='title has-text-white'>Sign Up
                </h1>
                <div className='my-2 has-text-left my-input'>
                    <label className='label has-text-white'>User Name</label>
                </div>
                <div className='form'>
                    <div className='field '>
                        <div className="control has-icons-left my-input">
                            <div className='colomn'>
                                <input className="input  is-rounded "
                                    type="text"
                                    placeholder="User Name"
                                    value={username}
                                    onChange={(e) => setusername(e.target.value)}
                                />
                                <span className='icon is-small is-left'>
                                    <i className='fas fa-user'></i>
                                </span>
                            </div>

                        </div>
                    </div>
                    <form>
                        <div className='my-2 has-text-left my-input'>
                            <label className='label has-text-white'>Email Id</label>
                        </div>
                        <div className='field '>
                            <div className="control has-icons-left my-input">
                                <div className='colomn'>
                                    <input
                                        className="input is-rounded"
                                        type="text"
                                        placeholder="Email ID"
                                        value={email}
                                        onChange={(e) => setemail(e.target.value)}
                                    />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-envelope"></i>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </form>
                    {email && !hasValEmail && (
                        <small
                            className={`ml-1 mt-1 ${!hasValEmail && email !== '' ? 'has-text-danger' : 'has-text-success'}`}
                        >
                            {!hasValEmail && email !== '' ? 'Enter a valid email address' : ''}
                        </small>
                    )}
                    <form>
                        <div className='my-2 has-text-left my-input'>
                            <label className='label has-text-white'>Password</label>
                        </div>
                        <div className='field'>
                    <div className="control has-icons-left has-icons-right is-flex my-input">
                        <input
                            className="input is-rounded"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-key"></i>
                        </span>
                        <span className="icon is-small is-right" onClick={togglePasswordVisibility} style={{ cursor: 'pointer','pointer-events': 'all'}}>
                            <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
                        </span>
                    </div>
                </div>
                    </form>
                    {/* password validation */}

                    <div className="field mt-4">
                        <div className="control my-input">
                            <Link to="/otp">
                                <button className="button is-primary is-rounded  is-focused is-medium is-fullwidth " disabled={!username || !email || !password || loading}
                                    onClick={handleExecutivesignup} >
                                    {loading ? 'Loading...' : 'SIGN UP'}
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div className='is-flex justify-content:flex-start text has-text-left ml-5' >
                        <p className='has-text-white'  style={{marginLeft:"184px"}}>Have an account ? </p>
                        <Link to='/executive-signin'>
                            <span className='has-text-weight-bold ml-2'>Sign in</span>
                        </Link>

                    </div>


                </div>
            </div >
        </div>


    )
}

export default SignupExecutive
