import React, { useState, useEffect } from 'react';
import { getInfluencerCampaigns, approveCampaign, approveCampaignBrand, rejectCampaignBrand } from '../components/infApi';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
const InfluencerTableBrand = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [selectedInfluencers, setSelectedInfluencers] = useState([]);
    const [error, setError] = useState(null);
    const location = useLocation();
    const { brandName } = useParams();
    console.log(brandName);
    useEffect(() => {
        fetchInfluencerCampaigns();
    }, [brandName]);

    const fetchInfluencerCampaigns = async () => {
        try {
            // Call your API function to fetch brand campaigns
            const response = await getInfluencerCampaigns();
            const data = await response.json();
            if (response.ok) {

                // Filter campaigns where isExecutive is true
                let brandCampaigns = data.filter(campaign => campaign.isBrand && brandName.includes(campaign.brandName));

                brandCampaigns = brandCampaigns.filter(campaign => {
                    if (campaign.isExecutive) {
                        if (!campaign.approvedbyExecutive) {
                            return false;
                        }
                    }
                    if (campaign.isLead) {
                        if (!campaign.approvedbyLead) {
                            return false;
                        }
                    }
                    if (campaign.isAmin) {
                        if (!campaign.approvedbyAdmin) {
                            return false;
                        }
                    }
                    return true;
                });
                // Update state with the filtered campaigns
                setCampaigns(brandCampaigns);
                console.log('Campaigns state after update:', brandCampaigns);
            } else {
                // Handle non-200 response status (e.g., 404, 500)
                setError(data.message || 'Failed to fetch brand campaigns');
            }
        } catch (error) {
            // Handle network errors or other exceptions
            setError('Error fetching brand campaigns: ' + error.message);
        }
    };

    const handleSelect = async (campaignId) => {
        try {
            console.log('Campaign approved:', campaignId);
            // Call the API function to approve the campaign
            const response = await approveCampaignBrand(campaignId);
            const data = await response.json();

            if (response.ok) {
                // Display the message "Campaign approved"
                alert('Campaign selected successfully');
                // Update the campaigns state to reflect the change
                const updatedCampaigns = campaigns.map(campaign => {
                    if (campaign._id === campaignId) {
                        return { ...campaign, approvedbybrand: true };
                    }
                    return campaign;
                });
                setCampaigns(updatedCampaigns);
            } else {
                console.error('Failed to approve campaign:', data.message);
            }
        } catch (error) {
            console.error('Error approving campaign:', error.message);
        }
    };

    // const handleSelect = async (campaignId) => {
    //     try {
    //         const selectedCampaign = campaigns.find(campaign => campaign._id === campaignId);
    //           setSelectedInfluencers([...selectedInfluencers,selectedCampaign])
    //         const updatedSelectedInfluencers = [...selectedInfluencers, selectedCampaign];

    //         // const updatedCampaigns = campaigns.filter(campaign => campaign._id !== campaignId);
    //         // setCampaigns(updatedCampaigns);

    //         const response = await fetch('http://localhost:5000/selected-influencers', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(selectedCampaign),
    //         });
    //         const responseData = await response.json();
    //         console.log('Response from Backend:', responseData);
    //         console.log('Selected Influencers:', updatedSelectedInfluencers);

    //         // Use updatedSelectedInfluencers directly when navigating
    //     } catch (error) {
    //         setError('Error selecting campaign: ' + error.message);
    //     }
    // };

    const handleReject = async (campaignId, setError) => {
        try {
            console.log('Campaign rejected:', campaignId);
            // Prompt user for remarks
            const remarks = prompt('Campaign rejected. Please provide remarks:');
            if (remarks === null) {
                // If user cancels, do nothing
                return;
            }

            // Call the rejectCampaignBrand API function and pass remarks
            const response = await rejectCampaignBrand(campaignId, remarks);

            // Check if the response is successful
            if (response && response.success) {
                // If successful, show success message
                console.log('Campaign rejected successfully');
                console.log('Remarks:', remarks); // Display remarks
            } else {
                // If not successful, log error message
                console.error('Failed to reject campaign:', response.message);
            }
        } catch (error) {
            // Handle any errors that occur during the rejection process
            setError('Error rejecting campaign: ' + error.message);
        }
    };


    // Call your API function to reject the campaign
    // This functionality is similar to handleApprove

    const handleRemarks = async (campaignId) => {
        try {
            console.log('Remarks added for campaign:', campaignId);
            // Call your API function to add remarks for the rejected campaign
            // This functionality is similar to handleApprove
        } catch (error) {
            setError('Error adding remarks: ' + error.message);
        }
    };


    return (
        <div className="container " >
             <h2>Influencer Applications</h2>

            {error && <p>Error: {error}</p>} {/* Display error message if there's an error */}

            <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable is-responsive">
                <thead>
                    <tr>
                        <th className="customStyle">Instagram Username</th>
                        <th className="customStyle">Instagram Followers Range</th>
                        <th className="customStyle">Exact Followers</th>
                        <th className="customStyle">Selected Category</th>
                        {/* <th>PhoneNumber</th>
                        <th>WhatsappNumber</th> */}
                        {/* <th className="customStyle">User Name</th> */}
                        {/* <th>Email</th> */}
                        <th className="customStyle">Gender</th>
                        <th className="customStyle">Age</th>
                        <th className="customStyle">Campaign Type</th>
                        <th className="customStyle">Deal Type</th>
                        <th className="customStyle">Languages</th>
                        <th className="customStyle">Selected Platform</th>
                        <th className="customStyle">YouTube Channel Link</th>
                        <th className="customStyle">YouTube Subscribers Range</th>
                        <th className="customStyle">Action</th>
                        <th className="customStyle">Remarks(If Rejected)</th> {/* Add column for action buttons */}
                        {/* Add more table headers as needed */}
                    </tr>
                </thead>
                <tbody>
                    {campaigns.map(campaign => (
                        <tr key={campaign._id}>
                            <td className="customStyle">{campaign.InstagramUsername}</td>
                            <td className="customStyle">{campaign.InstagramFollowersRange}</td>
                            <td className="customStyle">{campaign.ExactFollowers}</td>
                            <td className="customStyle">{campaign.selectedCategory}</td>
                            {/* <td>{campaign.PhoneNumber}</td>
                            <td>{campaign.WhatsappNumber}</td> */}
                            {/* <td className="customStyle">{campaign.UserName}</td> */}
                            {/* <td>{campaign.Email}</td> */}
                            <td className="customStyle">{campaign.Gender}</td>
                            <td className="customStyle">{campaign.Age}</td>
                            <td className="customStyle">{campaign.CampaignType}</td>
                            <td className="customStyle">{campaign.DealTyper}</td>
                            <td className="customStyle">{campaign.Languages}</td>
                            <td className="customStyle">{campaign.selectedPlatform}</td>
                            <td className="customStyle">{campaign.YouTubeChannelLink}</td>
                            <td className="customStyle">{campaign.YouTubeSubscribersRange}</td>

                            <td className="customStyle">
                                <button onClick={() => handleSelect(campaign._id)} className="button is-primary"
                                    title={
                                        campaign.approvedbyExecutive && campaign.approvedbyLead && campaign.approvedbyAdmin
                                            ? "Approved by Executive,Lead and Admin"
                                            : campaign.approvedbyExecutive
                                                ? "Approved by Executive"
                                                : campaign.approvedbyLead
                                                    ? "Approved by Lead"
                                                    : campaign.approvedbyAdmin
                                                        ? "Approved by Admin"
                                                        : ""
                                    }>Select</button>
                                <button onClick={() => handleReject(campaign._id)} className="button is-primary">Reject</button>
                            </td>
                            <td className="customStyle">
                                <button onClick={() => handleRemarks(campaign._id)} className="button is-primary">Remarks(If Rejected)</button>
                            </td>
                            {/* Pass selectedInfluencers as a prop to SuccessPage */}

                        </tr>
                    ))}
                </tbody>
            </table>
            {/* <ShortlistedInfluencers selectedInfluencers={selectedInfluencers}/> */}
        </div>

    );
};

export default InfluencerTableBrand
