import React, { useState, useEffect } from 'react';
import ApplicationPage from './AppicationPage';
import InfluencerconfirmationPage from './confirmationPage';
import InfDelverablesForm from './influencrDeliverables';
import ViewMore from './ViewMore';
import { Config } from '../.env.js';
import { useNavigate } from 'react-router-dom';
import ViewCampaign from './viewCampaign.js';
import logo from '../logo192.png';
const CampaignLiveExecutive = ({ campaign }) => {
    const navigate = useNavigate();
    const [isApplicationPageOpen, setIsApplicationPageOpen] = useState(false);
    const [isConfirmationPageOpen, setIsConfirmationPageOpen] = useState(false);
    const [isInfDelverablesFormOpen, setIsInfDelverablesFormOpen] = useState(false);
    const [isviewMoreOpen, setisViewMoreopen] = useState(false);
    const [isviewCampaignOpen, setisviewCampaignopen] = useState(false);
    const [isViewMoreHovered, setIsViewMoreHovered] = useState(false);
    const [isViewCampaignHovered, setIsViewCampaignHovered] = useState(false);
    const [zoomedIn, setZoomedIn] = useState(false);

    useEffect(() => {
        // Function to detect zoom level or screen width
        const detectZoom = () => {
            const isZoomedIn = window.devicePixelRatio > 1;
            setZoomedIn(isZoomedIn);
        };

        // Initial detection
        detectZoom();

        // Event listener for window resize
        window.addEventListener('resize', detectZoom);

        // Cleanup function
        return () => {
            window.removeEventListener('resize', detectZoom);
        };
    }, []);
    const handleApply = () => {
        setIsApplicationPageOpen(true);
    };

    const handleCloseApplication = () => {
        setIsApplicationPageOpen(false);
    };
    const handleApply1 = () => {
        setIsInfDelverablesFormOpen(true);
    }

    const handleviewCampaign = () => {
        const { campaignName } = campaign;
        navigate(`/view-campaignBrand/${campaignName}`)
        // Handle this case, such as displaying a message to the user

    };

    const handlecloseviewCampaign = () => {
        setisviewCampaignopen(false);
    }
    const handleCloseDelverable = () => {
        setIsInfDelverablesFormOpen(false);
    }
    const handleConfirmation = () => {
        setIsConfirmationPageOpen(true);
    };
    const handleCloseConfirmation = () => {
        setIsConfirmationPageOpen(false);
    };
    // const handleviewMore = () => {
    //     setisViewMoreopen(true);
    // };
    const handlecloseviewMore = () => {
        setisViewMoreopen(false);
    }
    if (!campaign) {
        return <div>No campaign data available</div>;
    }

    const { brandName, followersCriteria, infDeliverables, cashValue, campaignName, uploadBrandLogo, approve, rejected } = campaign;

    let statusLabel = '';
    let statusColor = '';
    if (approve) {
        statusLabel = 'Approved Successfully';
        statusColor = 'green';
    } else if (rejected) {
        statusLabel = 'This campaign is Rejected';
        statusColor = 'red';
    } else {
        statusLabel = 'Pending for Approval';
        statusColor = 'orange';
    }
    const handleviewMore = () => {
        const { campaignName } = campaign; // Extract brandName from campaign
        navigate(`/tblexecutive/${campaignName}`);
        // Navigate to a new route
    };

    const backgroundImageStyle = {
        backgroundImage: `url(${Config.API_URL}/uploads/${encodeURIComponent(uploadBrandLogo)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 500,
        width: 550,
        display: 'flex',
        flex: 1,
    };

    return (
        <div className='container' style={{ position: "relative" }}>
            <div className="card mt-6" style={backgroundImageStyle}>
                <div className="card-content has-text-left" style={{ flex: 1, height: 500, width: 100 }}>
                    <div className="box" style={{ marginLeft: '25%', width: '80%', height: '100%' }}>
                        <br />
                        <div className='mb-2'>
                            <figure className="image is-32x32"> {/* Adjust size as needed */}
                                <img src={logo} alt="Logo" />
                            </figure>
                        </div>
                        <h1 className='subtitle'><b>Good Influencer Campaign</b></h1>
                        <p>Campaign Title: {campaignName}</p>
                        <p>Brand Name: {brandName} </p>
                        <p>Followers Criteria: {followersCriteria}</p>
                        {/* <p>Campaign Type: {}</p>     */}
                        <p>Deliverables: {infDeliverables}</p>
                        <p>Give Product Worth Rs: {cashValue}</p>
                        <div>
                            {isConfirmationPageOpen && (
                                <div className="modal is-active">
                                    <div className="modal-background" style={{ backgroundColor: 'white' }}></div>
                                    <div className="modal-content">
                                        <InfluencerconfirmationPage onClose={handleCloseConfirmation} campaign={campaign} />
                                    </div>
                                    <button className="modal-close is-large" aria-label="close" onClick={handleCloseConfirmation}></button>
                                </div>
                            )}
                            {/* <button onClick={handleApply} className="button is-medium mt-3 ml-1 has-text-info">Apply Now</button> */}
                            {/* <button onClick={handleApply1} className="button is-medium mt-3 ml-1 has-text-info">Deliverables</button> */}
                            {/* <button onClick={handleConfirmation} className="button is-medium mt-2 ml-1 has-text-info">Confirmation</button> */}
                            {/* <button onClick={handleviewMore} className="button is-medium mt-2 ml-1 has-text-info" > view Influencer</button>
                            <button onClick={handleviewCampaign} className="button is-medium mt-3 ml-1 has-text-info" > view Campaign </button> */}


<div style={{ gap: '4%', marginTop: '60%', position: "relative", display: 'flex', justifyContent: 'center', }}>

                                <button
                                    onClick={handleviewMore}
                                    className={`button is-small-custom is-rounded  ${isViewMoreHovered ? 'is-primary' : ''}`}
                                    onMouseEnter={() => setIsViewMoreHovered(true)}
                                    onMouseLeave={() => setIsViewMoreHovered(false)}
                                >
                                    View Influencer
                                </button>
                                <button
                                    onClick={handleviewCampaign}
                                    className={`button is-small-custom is-rounded ${isViewCampaignHovered ? 'is-primary' : ''}`}
                                    onMouseEnter={() => setIsViewCampaignHovered(true)}
                                    onMouseLeave={() => setIsViewCampaignHovered(false)}
                                >
                                    View Campaign
                                </button>
                            </div>

                            {isviewCampaignOpen && (
                                <div className="modal is-active" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                    <div className="modal-content" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '80%', maxWidth: '800px' }}>
                                        <button className="delete" aria-label="close" onClick={handlecloseviewCampaign}></button>
                                        <h2 className="title is-4">View Campaign</h2>
                                        <ViewCampaign onClose={handlecloseviewCampaign} campaign={campaign} brandName={brandName} />
                                    </div>
                                </div>
                            )}

                            {/* <div style={{ color: statusColor }}>{statusLabel}</div> */}
                            <div className="status-label has-border-primary  " style={{ position: 'absolute', top: '39px', right: '20px', color: statusColor, borderRadius: "20px" }}>
                                {statusLabel}
                            </div>

                            {isApplicationPageOpen && (
                                <div className="modal is-active">
                                    <div className="modal-background" style={{ backgroundColor: 'white' }}></div>
                                    <div className="modal-content">
                                        <ApplicationPage onClose={handleCloseApplication} campaign={campaign} />
                                    </div>
                                    <button className="modal-close is-large" aria-label="close" onClick={handleCloseApplication}></button>
                                </div>
                            )}
                            {isConfirmationPageOpen && (
                                <div className="modal is-active">
                                    <div className="modal-background" style={{ backgroundColor: 'white' }}></div>
                                    <div className="modal-content">
                                        <InfluencerconfirmationPage onClose={handleCloseConfirmation} campaign={campaign} />
                                    </div>
                                    <button className="modal-close is-large" aria-label="close" onClick={handleCloseConfirmation}></button>
                                </div>
                            )}
                        </div>

                        <div>

                            {isInfDelverablesFormOpen && (
                                <div className="modal is-active">
                                    <div className="modal-background" style={{ backgroundColor: 'white' }}></div>
                                    <div className="modal-content">
                                        <InfDelverablesForm onClose={handleCloseDelverable} campaign={campaign} />
                                    </div>
                                    <button className="modal-close is-large" aria-label="close" onClick={handleCloseDelverable}></button>
                                </div>
                            )}
                            {/* {isviewMoreOpen && (
                                <div className="modal is-active" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                    <div className="modal-content" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '80%', maxWidth: '800px' }}>
                                        <button className="delete" aria-label="close" onClick={handlecloseviewMore}></button>
                                        <h2 className="title is-4">View Influencer</h2>
                                        <ViewMore onClose={handlecloseviewMore} campaign={campaign} brandName={brandName} />

                                    </div>
                                </div>


                            )} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CampaignLiveExecutive;
