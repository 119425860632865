import React, { useState, useEffect } from 'react';
import { getInfluencerDeliverables } from '../components/api'; // Import the API function for approving influencers
import { approvedLead } from '../components/infApi';
import { Config } from '../.env.js';
import { useParams } from 'react-router-dom';
const InfluencerDeliverablesTableLead = ({campaign}) => {
  const [deliverables, setDeliverables] = useState([]);
  const [error, setError] = useState(null);
  const { brandName } = useParams();
  const API_URL = `${Config.API_URL}`;

  useEffect(() => {
    fetchData();
  }, [brandName]);

  const fetchData = async () => {
    try {
      const response = await getInfluencerDeliverables();
      if (response.ok) {
        const data = await response.json();
        const leadDeliverables = data.filter(deliverables =>(deliverables.approvedbyExecutive && deliverables.isleadContent) || (!deliverables.isexecutiveContent && deliverables.isleadContent  && brandName.includes(deliverables.brandName)));
        // Update state with the filtered campaigns
        console.log('Campaigns state after update:', leadDeliverables);
        if (Array.isArray(leadDeliverables)) {
          setDeliverables(leadDeliverables);
        } else {
          setError('Invalid data received from server');
        }
      } else {
        setError('Failed to fetch influencer deliverables');
      }
    } catch (error) {
      setError('Error fetching influencer deliverables: ' + error.message);
    }
  };

  const handleApprove = async (influencerId ) => { // Update handleApprove to accept email address as parameter
    try {
      await approvedLead(influencerId); // Call the API function to approve the influencer
      const response = await approvedLead(influencerId);
      const data = await response.json();

      if (response.ok) {
          // Display the message "Campaign approved"
          alert('Approved successfully');
          // Update the campaigns state to reflect the change
          const updatedCampaigns = deliverables.map(deliverables => {
              if (deliverables._id === influencerId) {
                  return { ...deliverables, approvedbyLead: true };
              }
              return deliverables;
          });
          setDeliverables(updatedCampaigns);
      } else {
          console.error('Failed to approve campaign:', data.message);
      }
  } catch (error) {
      console.error('Error approving campaign:', error.message);
  }
};

      // Show a success message
      // Send email notification
  //     sendApprovalEmail(email);
  //     // You may want to refetch the influencer deliverables after approval to update the UI
  //     fetchData();
  //   } catch (error) {
  //     setError('Error approving influencer: ' + error.message);
  //   }
  // };

  // const sendApprovalEmail = async (email) => {
  //   try {
  //     const sendGridData = {
  //       personalizations: [
  //         {
  //           to: [{ email: email }],
  //           subject: 'Influencer Approved'
  //         }
  //       ],
  //       from: {
  //         email: 'your-email@example.com', // Replace with your email
  //         name: 'Your Sender Name'
  //       },
  //       content: [
  //         {
  //           type: 'text/plain',
  //           value: 'Your message here' // Replace with your message
  //         }
  //       ]
  //     };

  //     const response = await fetch('https://api.sendgrid.com/v3/mail/send', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer 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'
  //       },
  //       body: JSON.stringify(sendGridData)
  //     });

  //     if (response.ok) {
  //       console.log('Email sent successfully');
  //     } else {
  //       console.error('Failed to send email');
  //     }
  //   } catch (error) {
  //     console.error('Error sending email:', error);
  //   }
  // };

  return (
    <div>
      <h2>Influencer Deliverables Data</h2>
      {error && <p>{error}</p>}
      <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable is-responsive">
        <thead>
          <tr>
            {/* <th className="customStyle">Instagram Username</th> */}
            <th className="customStyle">Email</th>
            {/* <th className="customStyle">Phone</th> */}
            <th className="customStyle">Full Name</th>
            <th className="customStyle">Uploaded Videos</th>
            <th className="customStyle">Uploaded Raw Videos</th>
            <th className="customStyle">Action</th>
          </tr>
        </thead>
        <tbody>
          {deliverables.map(influencer => (
            <tr key={influencer._id}>
              {/* <td className="customStyle">{influencer.instagramUsername}</td> */}
              <td className="customStyle">{influencer.email}</td>
              {/* <td className="customStyle">{influencer.phone}</td> */}
              <td className="customStyle">{influencer.fullName}</td>
              <td className="customStyle">
                {influencer.uploadVideoPath.map((video, index) => (
                  <span key={index}>
                    <a href={`${API_URL}/${video}`} target="_blank" rel="noopener noreferrer">Video {index + 1}</a>
                    {index !== influencer.uploadVideoPath.length - 1 && ', '}
                  </span>
                ))}
              </td>
              <td className="customStyle">
                {influencer.uploadRawVideoPath.map((video, index) => (
                  <span key={index}>
                    <a href={`${API_URL}/${video}`} target="_blank" rel="noopener noreferrer">Video {index + 1}</a>
                    {index !== influencer.uploadRawVideoPath.length - 1 && ', '}
                  </span>
                ))}
              </td>
              <td className="customStyle">
                <button onClick={() => handleApprove(influencer._id, influencer.email)} className="button is-primary">Approve</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InfluencerDeliverablesTableLead;
