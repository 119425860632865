// SuccessPage.js

import React from 'react';

const SuccessPage = ({ selectedInfluencers }) => {
    return (
        <div className="container">
            <h2>Form submitted successful</h2>
           
        </div>
    );
};

export default SuccessPage;
