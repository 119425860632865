import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Config } from '../.env.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo192.png';
import { Link } from 'react-router-dom';
const ForgetPassword = () => {
    const navigate = useNavigate()
    // const [email, setEmail] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const handleResetPassword = async () => {
        try {
            setLoading(true);
            setError(null);

            const response = await fetch(`${Config.API_URL}/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }),
            });

            if (response.ok) {
                navigate(`/otp1/${email}`, { replace: true });
                toast.success('Password reset initiated successfully');
                // setTimeout(() => {
                //     navigate(/update-password/${mobileNumber}, { replace: true });
                //   }, 10000);
            } else if (response.status === 429) {
                setError('Too Many Attempts of Forgot Password');
                toast.error(
                    <div>
                        Too Many Attempts of Forgot Password.
                        <br />
                        Please try after some time or contact us at contact@hobo.video for assistance.
                    </div>
                );
            } else {
                const errorMessage = await response.text();
                setError(errorMessage || 'Failed to initiate password reset');
                toast.error(
                    <div>
                        Email ID is not Registered.
                        <br />
                        The email address you entered is not registered with us. Please try again or sign up for a new account. For further assistance, contact us at contact@hobo.video
                    </div>
                );
            }
        } catch (error) {
            setError('Failed to connect to the server');
            toast.error(
                <div> Unable to Process Request <br />An unexpected error occurred. Please contact us at contact@hobo.video for assistance.</div>);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='imgfull'>
            <div className='container1 has-text-centered mt-5'>

                <div className='logo-card'>
                    <figure className="image is-64x64">
                        <img src={logo} alt="Logo" />
                    </figure>
                </div>
                <div className='container'>
                    <h1 className='title  has-text-white'>Forget your Password?</h1>
                    <div className='field'>
                    <level className="label has-text-left has-text-white ">Your Registred Email ID</level>
                        <div className='control has-icons-left my-input'>
                       
                            <input
                                className='input is-rounded '
                                type='text'
                                placeholder='e.g. aa@brand.com'
                                name='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                            </span>
                        </div>
                    </div>
                    <div className='field'>
                        <div className='control mt-4'>
                            <button
                                className='button is-primary is-rounded is-focused is-medium is-fullwidth'
                                disabled={!email || loading}
                                onClick={handleResetPassword}
                            >
                                Reset Password
                            </button>
                        </div>
                    </div>

                    <p className='has-text-white ' style={{ marginLeft: "99px" }}>Back to SignIn Page?
                        <Link to='/signinbrand'>
                            <span className='has-text-weight-bold ml-2'>Sign in</span>
                        </Link>
                    </p>
                    
                    {/* {error && (
                    <div className='notification is-danger'>
                        {error}
                    </div>
                )} */}
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;