import React, { useState, useEffect } from 'react';
import { getBrandCampaigns1 } from '../components/api';
import { useNavigate } from 'react-router-dom';
import { approveCampaign, createCampaign, updateApprove } from '../components/campaignApi';
import { updateCampaign } from "../components/apiEdit";
import { approve, rejectedCampaignBrand } from '../components/infApi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';

const ViewCampaignBrand = (campaign) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isViewMoreHovered, setIsViewMoreHovered] = useState(false);
    const [isViewCampaignHovered, setIsViewCampaignHovered] = useState(false);
    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState([]);
    const [editingCampaign, setEditingCampaign] = useState(null);
    const [error, setError] = useState()

    const { campaignName } = useParams();


    // Function to handle modal open
    const handleModalOpen = () => {
        setIsModalOpen(true);
        // Add the class to the body element to prevent scrolling
        document.body.classList.add('modal-open');
    };

    // Function to handle modal close
    const handleModalClose = () => {
        setIsModalOpen(false);
        // Remove the class from the body element to allow scrolling
        document.body.classList.remove('modal-open');
    };


    useEffect(() => {
        fetchBrandCampaigns();
        // fetchAllCampaigns();

    }, [campaignName]);

    const fetchBrandCampaigns = async () => {
        try {
            const response = await getBrandCampaigns1();
            const data = await response.json();
            const campaigndata = data.filter(campaign => campaign.campaignName === campaignName);
            if (response.ok) {
                setCampaigns(campaigndata);
            } else {
                setError(data.message || 'Failed to fetch brand campaigns');
            }
        } catch (error) {
            console.error('Error fetching campaigns:', error);
            setError('Error fetching campaigns: ' + error.message);
        }
    };


    const constructViewPhotoLink = (filename) => {
        return `/uploads/${encodeURIComponent(filename)}`;
    };

    const [isadmin, setisAdmin] = useState(false);
    const [islead, setisLead] = useState(false);
    const [isexecutive, setisExecutive] = useState(false);
    const [isbrand, setisBrand] = useState(false);

    const [isadminContent, setisAdminContent] = useState(false);
    const [isleadContent, setisLeadContent] = useState(false);
    const [isexecutiveContent, setisExecutiveContent] = useState(false);
    const [isbrandContent, setisBrandContent] = useState(false);

    const [products, setProducts] = useState(false);
    const [other, setOther] = useState(false);
    const [CashMoney, setCashMoney] = useState(false);

    const [male, setMale] = useState(false);
    const [female, setfemale] = useState(false);
    const [transgender, setTransgender] = useState(false);
    const [uploadBrandLogo, setUploadBrandLogo] = useState(null);
    const [newBriefFile, setNewBriefFile] = useState(null);

    const handleEdit = (campaign) => {

        // Copy the campaign object to avoid mutating the original data
        const editedCampaign = { ...campaign };
        setEditingCampaign(editedCampaign);

        // Set the checkbox states based on the selected campaign's data
        setisAdmin(editedCampaign.isAdmin);
        setisLead(editedCampaign.isLead);
        setisExecutive(editedCampaign.isExecutive);
        setisBrand(editedCampaign.isBrand);
        setisAdminContent(editedCampaign.isadminContent);
        setisLeadContent(editedCampaign.isleadContent);
        setisExecutiveContent(editedCampaign.isexecutiveContent);
        setisBrandContent(editedCampaign.isbrandContent);

        setProducts(editedCampaign.products);
        setCashMoney(editedCampaign.CashMoney);
        setOther(editedCampaign.other);

        setMale(editedCampaign.male);
        setfemale(editedCampaign.female);
        setTransgender(editedCampaign.transgender);
        handleModalOpen();
    };

    const handleCheckboxChange2 = (e) => {
        const { name, checked } = e.target;
        switch (name) {
            case 'products':
                setProducts(checked);

                break;
            case 'CashMoney':
                setCashMoney(checked);

                break;
            case 'other':
                setOther(checked);

                break;
            default:
                break;
        }
    };
    const handleUpdate = async () => {
        try {
            const { _id, ...updatedData } = editingCampaign;
            // Remove _id field before updating
            const updatedCampaign = {
                ...updatedData,
                isAdmin: isadmin,
                isLead: islead,
                isExecutive: isexecutive,
                isBrand: isbrand,
                isadminContent: isadminContent,
                isleadContent: isleadContent,
                isexecutiveContent: isexecutiveContent,
                isbrandContent: isbrandContent,

                products: products,
                CashMoney: CashMoney,
                other: other,
                male: male,
                female: female,
                transgender: transgender
            };
            await updateCampaign(_id, updatedCampaign); // Send updated data to backend
            // If update successful, reset editingCampaign state and fetch updated campaigns
            setEditingCampaign(null);
            fetchBrandCampaigns();
            toast.success('Campaign updated successfully');
        } catch (error) {
            setError('Error updating campaign: ' + error.message);
            console.error('Error updating campaign:', error);
        }
    };

    const handleCancelEdit = () => {
        setEditingCampaign(null); // Reset editingCampaign state on cancel
        handleModalClose();
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/jpg'];

        // Check if the selected file type is allowed
        if (!allowedTypes.includes(file.type)) {
            toast.error('Only JPG and JPEG files are allowed for the cover image');
            return;
        }

        // Extracting just the file name
        const fileName = file.name;
        setUploadBrandLogo(file);
        setEditingCampaign({ ...editingCampaign, uploadBrandLogo: fileName });
    };
    const handleCheckboxgenderchange = (e) => {
        const { name } = e.target;
        switch (name) {
            case 'male':
                setMale(prevState => !prevState);
                break;
            case 'female':
                setfemale(prevState => !prevState);
                break;
            case 'transgender':
                setTransgender(prevState => !prevState);
                break;
            default:
                break;
        }
    };
    const handleFollowersCriteriaChange = (value) => {
        let updatedCriteria = [...editingCampaign.followersCriteria];

        if (updatedCriteria.includes(value)) {
            updatedCriteria = updatedCriteria.filter(criteria => criteria !== value);
        } else {
            updatedCriteria.push(value);
        }

        setEditingCampaign(prevState => ({
            ...prevState,
            followersCriteria: updatedCriteria
        }));
    };


    const handlechackboxesChange = (e) => {
        const { name, checked } = e.target;
        setEditingCampaign(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };
    const examplePdfUrl = 'https://docs.google.com/presentation/d/1Yf2C23GdKI1w4XxiM8MdxNGk7EBWB6LOAHjGitV_IOQ/edit#slide=id.gf9808550a8_0_6';

    const handleReuploadBrief = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        // Check if the selected file type is allowed
        if (!allowedTypes.includes(file.type)) {
            toast.error('Only PDF, DOC, and DOCX files are allowed please upload Other file');
            return;
        }

        setEditingCampaign(prevState => ({
            ...prevState,
            uploadBrief: file.name // Update the file name
        }));
    };



    return (

        <div className="container mt-3 " >
            <form >
                {campaigns.map(campaign => (
                    <div key={campaign._id}>
                        <div className='has-text-right ml-5' style={{ position: "fixed" }} >
                            <button style={{ borderRadius: '20px',marginLeft:"528px" }} onClick={(e) => { e.preventDefault(); handleEdit(campaign); }} disabled={campaign.approve || campaign.rejected} className="button is-primary">Edit</button>
                        </div>
                        <div className='field'>
                            <label className='label has-text-left ' >Brand Name :</label>
                            <div className='control '>
                                <input type=" text" className="input" readOnly value={campaign.brandName} style={{ borderRadius: '20px' }} />
                            </div>

                        </div>

                        <div className='field'>
                            <label className='label has-text-left'> How many influencers are you looking forward to work with?:</label>
                            <div className='control'>
                                <input type=" text" className="input" readOnly value={campaign.influencer} style={{ borderRadius: '20px' }} />
                            </div>
                        </div>

                        <div className='field'>
                            <label className='label has-text-left'>Please specify the exact deliverables you want from Influencers:</label>
                            <div className='control'>
                                <input type=" text" className="input" readOnly value={campaign.infDeliverables} style={{ borderRadius: '20px' }} />
                            </div>
                        </div>
                        <div className='field'>
                            <label className="label has-text-left">What would You be Giving to Influencers in Exchange for Deliverables?</label>
                            <div className='control'>
                                <input style={{ borderRadius: '20px' }} className="input" type=" text" readOnly value={campaign.products ? 'Products' : campaign.cashMoney ? 'Cash/Money' : campaign.other ? 'Other' : 'Not Specified'}
                                />
                            </div>
                        </div>

                        <div className='field'>
                            <label className="label has-text-left">
                                Value (in Rs.) of the cash/products being Given to Influencers? *
                            </label>
                            <div className='control'>
                                <input type=" text" className="input" readOnly value={campaign.cashValue} style={{ borderRadius: '20px' }} />
                            </div>

                        </div>
                        <div className='field'>
                            <label className='label has-text-left'>Target Influencers' Gender*:</label>
                            <div className='control'>
                                <input style={{ borderRadius: '20px' }} className="input" type=" text" readOnly value={campaign.male ? 'Male' : campaign.female ? 'Female' : campaign.transgender ? 'Transgender' : 'Not specified'} />
                            </div>
                        </div>
                        <div className='field'>
                            <label className='label has-text-left'> Followers Criteria:</label>
                            <div className='control'>
                                <input type=" text" className="input" readOnly value={campaign.followersCriteria} style={{ borderRadius: '20px' }} />
                            </div>
                        </div>

                        <div className='field'>
                            <label className='label has-text-left'> What is Your Target Audience Age Group?:</label>
                            <div className='control'>
                                <input type=" text" className="input" readOnly value={campaign.ageGroup} style={{ borderRadius: '20px' }} />
                            </div>
                        </div>

                        <div className="field">
                            <strong className="has-text-left">Influencers Categories You Want to Target:</strong>
                            <div className="control">
                                <textarea
                                    className='textarea ' type="text"
                                    readOnly
                                    value={
                                        [
                                            campaign.cosmetics && 'Cosmetics',
                                            campaign.skincare && 'Skincare',
                                            campaign.haircare && 'Haircare',
                                            campaign.beauty && 'Beauty',
                                            campaign.travel && 'Travel',
                                            campaign.student && 'Student',
                                            campaign.houseWife && 'HouseWife',
                                            campaign.gaming && 'Gaming',
                                            campaign.dance && 'Dance',
                                            campaign.yoga && 'Yoga',
                                            campaign.gadgets && 'Gadgets',
                                            campaign.tech && 'Tech',
                                            campaign.cooking && 'Cooking',
                                            campaign.workingClass && 'Working Class',
                                            campaign.smallEntrepreneurs && 'Small Entrepeneurs',
                                            campaign.diy && "diy",
                                            campaign.electronics && 'Electronics',
                                            campaign.lifestyle && 'Lifestyle',
                                            campaign.fashion && 'Fashion',
                                            campaign.food && 'Food',
                                            campaign.healthFitness && 'Health & Fitness'
                                        ].filter(Boolean).join(', ') || 'Not specified'
                                    }
                                    style={{ borderRadius: '20px' }}
                                ></textarea>
                            </div>
                        </div>

                        <div className='field'>
                            <label className="label has-text-left ">   Please Mention the Links of the Products, Influencers shall be Getting
                            </label>
                            <div className="control">
                                <input type=" text" className="input" readOnly value={campaign.productLink} style={{ borderRadius: '20px' }} />
                            </div>
                        </div>

                        <div className='field'>
                            <label className="label has-text-left"> For how many weeks are You Planning to run the Campaign?
                            </label>
                            <div className="control">
                                <input type=" text" className="input" readOnly value={campaign.campainTime} style={{ borderRadius: '20px' }} />

                            </div>
                        </div>

                        <div className='field'>
                            <label className='label has-text-left'> Social Media Platform For Influencer Marketing:</label>
                            <div className='control'>
                                <input type=" text" className="input" readOnly value={campaign.SocialmediaPlatform} style={{ borderRadius: '20px' }} />
                            </div>
                        </div>

                        <div className='field'>
                            <label className="label has-text-left "> Campaign Name
                            </label>
                            <div className="control">
                                <input type=" text" readOnly value={campaign.campaignName} className="input" style={{ borderRadius: '20px' }} />
                            </div>
                        </div>
                        <div className='field'>
                            <label className="label has-text-left">Upload Cover Image For The Campaign </label>
                            <div className="control">
                                <input style={{ borderRadius: '20px' }} className="input" type=" text" readOnly value={campaign.uploadBrandLogo ? campaign.uploadBrandLogo : 'N/A'} />
                            </div>
                        </div>

                        <div className='field'>
                            <label className="label has-text-left">Upload Brief pdf</label>
                            <div className="control">
                                <input style={{ borderRadius: '20px' }} className="input" type=" text" readOnly value={campaign.uploadBrief} />
                            </div>
                        </div>
                        <div className="label has-text-left ">
                            {/* <p>Example PDF</p> */}
                            <a href={examplePdfUrl} target="_blank" rel="noopener noreferrer">
                                Example PDF
                            </a>
                        </div>

                        {/* <div className='field'>
                            <label className="label has-text-left">Is this campaign for a specific location?</label>
                            <div className="control">
                                <input style={{ borderRadius: '20px' }} type=" text" readOnly value={campaign.isSpecificLocation ? campaign.isSpecificLocation : 'N/A'} />
                            </div>
                        </div> */}
                        <div className='field'>
                            <label className="label has-text-left">Write location/city/state</label>
                            <div className="control">
                                <input style={{ borderRadius: '20px' }} className="input" type=" text" readOnly value={campaign.location1 ? campaign.location1 : 'N/A'} />
                            </div>
                        </div>

                        <div className='field'>
                            <label className="label has-text-left">Any other eligibility criteria to select Influencers?
                            </label>
                            <div className="control">
                                <textarea style={{ borderRadius: '20px' }} className="textarea" type=" text" readOnly value={campaign.selectionCriteria} ></textarea>
                            </div>
                        </div>
                        <div className='field'>
                            <label className="label has-text-left">Any message for us?</label>
                            <div className="control">
                                <textarea style={{ borderRadius: '20px' }} type=" text" className='textarea' readOnly value={campaign.message} ></textarea>
                            </div>
                        </div>

                        <div className=' has-text-blue has-text-left my-4'>
                            <a className="has-text-left" href="javascript:history.back()">Back to Previous Page</a>
                        </div>

                    </div>
                ))}

            </form>

            {editingCampaign && (
                <div className="modal is-active" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-content" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '80%', maxWidth: '800px' }}>
                        <button className="delete" aria-label="close" onClick={handleCancelEdit}></button>
                        <h2 className="title is-4">Edit Campaign</h2>
                        {/* Input fields for editing campaign attributes */}
                        <div className='field'>
                            <label className='label'>Brand Name:</label>
                            <div className='control'>
                                <input style={{ borderRadius: '20px' }} className='input'
                                    type="text"
                                    id="brandName"
                                    placeholder="Brand Name"
                                    value={editingCampaign.brandName}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, brandName: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='field'>
                            <label className='label'> How many influencers are you looking forward to work with?:</label>
                            <div className='control'>
                                <input style={{ borderRadius: '20px' }} className='input'
                                    type="text"
                                    id="influencer"
                                    placeholder="Your Answer"
                                    value={editingCampaign.influencer}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, influencer: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='field'>
                            <label className='label'>Please specify the exact deliverables you want from Influencers:</label>
                            <div className='control'>
                                <input style={{ borderRadius: '20px' }} className='input'
                                    type="text"
                                    id="infDeliverables"
                                    placeholder="e.g.-1 Reel + 1 Story"
                                    value={editingCampaign.infDeliverables}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, infDeliverables: e.target.value })}
                                />
                            </div>
                        </div>

                        <div className="field mr-6">
                            <label className="label has-text-left ml-3">What would you be giving to influencers in exchange for deliverables?</label>

                            <div className="field has-text-left">
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        name='products'
                                        checked={products}
                                        onChange={handleCheckboxChange2}
                                    />
                                    Products
                                </label>
                            </div>
                            <div className="field has-text-left">
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        name='CashMoney'
                                        checked={CashMoney}
                                        onChange={handleCheckboxChange2}
                                    />
                                    Cash/Money
                                </label>
                            </div>
                            <div className="field has-text-left">
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        name='other'
                                        checked={other}
                                        onChange={handleCheckboxChange2}
                                    />
                                    Other
                                </label>
                            </div>
                        </div>


                        <div className="additionalQuestionContainer">
                            <label className="label has-text-left">
                                Value (in Rs.) of the cash/products being given to influencers? *
                            </label>
                            <input
                                style={{ borderRadius: '20px' }}
                                type="text"
                                className="input"
                                placeholder="e.g. Rs. 1000 worth products + Rs. 500 Cash"
                                placeholderTextColor="#999"
                                value={editingCampaign.cashValue}
                                onChange={e => setEditingCampaign({ ...editingCampaign, cashValue: e.target.value })}
                            />

                        </div>


                        <div className='field'>
                            <label className='label'>Target influencers' gender:</label>
                            <div className="checkboxContainer mr-6">
                                <label htmlFor="male" className="checkboxLabel mr-6">
                                    <input
                                        id="male"
                                        type="checkbox"
                                        name="male"
                                        checked={male}
                                        onChange={handleCheckboxgenderchange}
                                    />
                                    Male
                                </label>
                                <label htmlFor="female" className="checkboxLabel mr-6">
                                    <input
                                        id="female"
                                        type="checkbox"
                                        name="female"
                                        checked={female}
                                        onChange={handleCheckboxgenderchange}
                                    />
                                    Female
                                </label>
                                <label htmlFor="transgender" className="checkboxLabel mr-6">
                                    <input
                                        id="transgender"
                                        type="checkbox"
                                        name="transgender"
                                        checked={transgender}
                                        onChange={handleCheckboxgenderchange}
                                        className="checkbox"
                                    />
                                    Transgender
                                </label>
                            </div>
                        </div>

                        <div className='field'>
                            <label className='label'> Followers criteria:</label>
                            <div>
                                <label className="checkbox mr-4">
                                    <input
                                        type="checkbox"
                                        value="1-2000"
                                        checked={editingCampaign.followersCriteria.includes("1-2000")}
                                        onChange={(e) => handleFollowersCriteriaChange(e.target.value)}
                                    />
                                    Less than 2K
                                </label>
                                <label className="checkbox mr-4">
                                    <input
                                        type="checkbox"
                                        value="2000-10000"
                                        checked={editingCampaign.followersCriteria.includes("2000-10000")}
                                        onChange={(e) => handleFollowersCriteriaChange(e.target.value)}
                                    />
                                    2k-10k
                                </label>
                                <label className="checkbox mr-4">
                                    <input
                                        type="checkbox"
                                        value="10000-50000"
                                        checked={editingCampaign.followersCriteria.includes("10000-50000")}
                                        onChange={(e) => handleFollowersCriteriaChange(e.target.value)}
                                    />
                                    10k-50k
                                </label>
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        value="50000-100000"
                                        checked={editingCampaign.followersCriteria.includes("50000-100000")}
                                        onChange={(e) => handleFollowersCriteriaChange(e.target.value)}
                                    />
                                    50k-100k
                                </label>
                            </div>
                        </div>

                        <div className='field'>
                            <label className='label'> What is your target audience age group?:</label>
                            <div className='control'>
                                <input style={{ borderRadius: '20px' }} className='input'
                                    type="text"
                                    id="deliverables"
                                    placeholder="e.g. Loreal has target audience age group of 20-45 years *"
                                    value={editingCampaign.ageGroup}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, ageGroup: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='field'>
                            <div className="control">
                                <div className='colomn'>
                                    <div className='my-2 has-text-left'>
                                        <strong>Influencers Categories You Want to Target </strong>
                                    </div>
                                    <div className="checkboxContainer mr-6">
                                        <input
                                            id='skincare'
                                            type="checkbox"
                                            disabled={false}
                                            name='skincare'
                                            checked={editingCampaign.skincare}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="skincare" className="checkboxLabel mr-4"> Skincare </label>


                                        <input
                                            id='cosmetics'
                                            type="checkbox"
                                            disabled={false}
                                            name='cosmetics'
                                            checked={editingCampaign.cosmetics}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="cosmetics" className="checkboxLabel mr-4"> Cosmetics </label>


                                        <input
                                            id='lifeStyle'
                                            type="checkbox"
                                            disabled={false}
                                            name='lifeStyle'
                                            checked={editingCampaign.lifeStyle}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="lifeStyle" className="checkboxLabel mr-4"> Lifestyle </label>

                                        <input
                                            id='haircare'
                                            type="checkbox"
                                            disabled={false}
                                            name='haircare'
                                            checked={editingCampaign.haircare}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="haircare" className="checkboxLabel mr-4"> Haircare </label>

                                        <input
                                            id='beauty'
                                            type="checkbox"
                                            disabled={false}
                                            name='beauty'
                                            checked={editingCampaign.beauty}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="beauty" className="checkboxLabel mr-4"> Beauty </label>

                                        <input
                                            id='food'
                                            type="checkbox"
                                            disabled={false}
                                            name='food'
                                            checked={editingCampaign.food}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="food" className="checkboxLabel mr-4"> Food </label>

                                        <input
                                            id='fashion'
                                            type="checkbox"
                                            disabled={false}
                                            name='fashion'
                                            checked={editingCampaign.fashion}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="fashion" className="checkboxLabel mr-4"> Fashion </label>

                                        <input
                                            id='HealthFitness'
                                            type="checkbox"
                                            disabled={false}
                                            name='HealthFitness'
                                            checked={editingCampaign.HealthFitness}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="HealthFitness" className="checkboxLabel mr-4"> Health & Fitness </label>


                                        <input
                                            id='travel'
                                            type="checkbox"
                                            disabled={false}
                                            name='travel'
                                            checked={editingCampaign.travel}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="travel" className="checkboxLabel mr-4"> Travel </label>

                                        <input
                                            id='student'
                                            type="checkbox"
                                            disabled={false}
                                            name='student'
                                            checked={editingCampaign.student}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="student" className="checkboxLabel mr-4"> Student </label>

                                        <input
                                            id='houseWife'
                                            type="checkbox"
                                            disabled={false}
                                            name='houseWife'
                                            checked={editingCampaign.houseWife}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="houseWife" className="checkboxLabel mr-4"> HouseWife </label>

                                        <input
                                            id='gaming'
                                            type="checkbox"
                                            disabled={false}
                                            name='gaming'
                                            checked={editingCampaign.gaming}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="gaming" className="checkboxLabel mr-4"> Gaming </label>

                                        <input
                                            id='dance'
                                            type="checkbox"
                                            disabled={false}
                                            name='dance'
                                            checked={editingCampaign.dance}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="dance" className="checkboxLabel mr-4"> Dance </label>
                                        <input
                                            id='yoga'
                                            type="checkbox"
                                            disabled={false}
                                            name='yoga'
                                            checked={editingCampaign.yoga}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="yoga" className="checkboxLabel mr-4"> Yoga </label>

                                        <input
                                            id='gadgets'
                                            type="checkbox"
                                            disabled={false}
                                            name='gadgets'
                                            checked={editingCampaign.gadgets}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="gadgets" className="checkboxLabel mr-4"> Gadgets </label>

                                        <input
                                            id='tech'
                                            type="checkbox"
                                            disabled={false}
                                            name='tech'
                                            checked={editingCampaign.tech}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="tech" className="checkboxLabel mr-4"> Tech </label>

                                        <input
                                            id='cooking'
                                            type="checkbox"
                                            disabled={false}
                                            name='cooking'
                                            checked={editingCampaign.cooking}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="cooking" className="checkboxLabel mr-4"> Cooking </label>

                                        <input
                                            id='workingClass'
                                            type="checkbox"
                                            disabled={false}
                                            name='workingClass'
                                            checked={editingCampaign.workingClass}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="workingClass" className="checkboxLabel mr-4"> Working Class </label>

                                        <input
                                            id='smallEntrepreneurs'
                                            type="checkbox"
                                            disabled={false}
                                            name='smallEntrepreneurs'
                                            checked={editingCampaign.smallEntrepreneurs}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="smallEntrepreneurs" className="checkboxLabel mr-4">Small Entrepreneurs </label>

                                        <input
                                            id='diy'
                                            type="checkbox"
                                            disabled={false}
                                            name='diy'
                                            checked={editingCampaign.diy}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="diy" className="checkboxLabel mr-4"> Diy</label>

                                        <input
                                            id='electronics'
                                            type="checkbox"
                                            disabled={false}
                                            name='electronics'
                                            checked={editingCampaign.electronics}
                                            onChange={handlechackboxesChange}
                                            className="checkbox"
                                        />
                                        <label htmlFor="electronics" className="checkboxLabel mr-4"> Electronics </label>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div className='field'>
                            <label className='label'> Please Mention the Links of the Products, Influencers shall be Getting </label>
                            <div className='control'>
                                <input style={{ borderRadius: '20px' }} className='input'
                                    type="text"
                                    id="productLink"
                                    placeholder="Your Answer"
                                    value={editingCampaign.productLink}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, productLink: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='field'>
                            <label className='label'> For how many weeks are you planning to run the campaign?:</label>
                            <div className="select">
                                <select style={{ borderRadius: '20px', width: "900px" }}
                                    value={editingCampaign.campainTime}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, campainTime: e.target.value })}

                                >
                                    <option value="">Select </option>
                                    <option value="1">1 weeks</option>
                                    <option value="2">2 weeks</option>
                                    <option value="3">3 weeks</option>
                                    <option value="4">4 weeks</option>
                                    <option value="5">5 weeks</option>
                                    <option value="6">6 weeks</option>
                                    <option value="7">7 weeks</option>
                                    <option value="8">8 weeks</option>
                                    <option value="9">9 weeks</option>
                                    <option value="10">10 weeks</option>
                                    <option value="11">11 weeks</option>
                                    <option value="12">12 weeks</option>
                                    <option value="13">13 weeks</option>
                                    <option value="14">14 weeks</option>
                                    <option value="15">15 weeks</option>
                                    <option value="16">16 weeks</option>
                                    <option value="17">17 weeks</option>
                                    <option value="18">18 weeks</option>
                                    <option value="19">19 weeks</option>
                                    <option value="20">20 weeks</option>
                                    <option value="21">21 weeks</option>
                                    <option value="22">22 weeks</option>
                                    <option value="23">23 weeks</option>
                                    <option value="24">24 weeks</option>
                                    <option value="25">25 weeks</option>
                                    <option value="26">26 weeks</option>
                                    <option value="27">27 weeks</option>
                                    <option value="28">28 weeks</option>
                                    <option value="29">29 weeks</option>
                                    <option value="30">30 weeks</option>
                                    <option value="31">31 weeks</option>
                                    <option value="32">32 weeks</option>
                                    <option value="33">33 weeks</option>
                                    <option value="34">34 weeks</option>
                                    <option value="35">35 weeks</option>
                                    <option value="36">36 weeks</option>
                                    <option value="37">37 weeks</option>
                                    <option value="38">38 weeks</option>
                                    <option value="39">39 weeks</option>
                                    <option value="40">40 weeks</option>
                                    <option value="41">41 weeks</option>
                                    <option value="42">42 weeks</option>
                                    <option value="43">43 weeks</option>
                                    <option value="44">44 weeks</option>
                                    <option value="45">45 weeks</option>
                                    <option value="46">46 weeks</option>
                                    <option value="47">47 weeks</option>
                                    <option value="48">48 weeks</option>
                                    <option value="49">49 weeks</option>
                                    <option value="50">50 weeks</option>
                                    <option value="51">51 weeks</option>
                                    <option value="52">52 weeks</option>
                                </select>
                            </div>
                        </div>

                        <div className='field'>
                            <label className='label'> Social Media Platform For Influencer Marketing</label>
                            <div className="select">
                                <select style={{ borderRadius: '20px', width: "900px" }}
                                    value={editingCampaign.SocialmediaPlatform}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, SocialmediaPlatform: e.target.value })}
                                >
                                    <option value="">Select </option>
                                    <option value="Youtuble">You tuble</option>
                                    <option value="Moj">Moj</option>
                                    <option value="Chingari">Chingari</option>
                                    <option value="Facebook"> Facebook</option>
                                    <option value=" TikTok"> TikTok</option>
                                    <option value="MxTakaTak">MxTakaTak</option>
                                    <option value="Linkedin">Linkedin</option>
                                    <option value="Quora">Quora</option>
                                    <option value="Snapchat">Snapchat</option>
                                    <option value="Koo">Koo</option>
                                    <option value="Tumblr">Tumblr</option>
                                    <option value="Trell">Trell</option>
                                    <option value="Rizzle">Rizzle</option>
                                    <option value="Lumi">Lumi</option>
                                    <option value="Kutumb">Kutumb</option>
                                    <option value="Roposo">Roposo</option>
                                    <option value=" 9GAG"> 9GAG</option>
                                    <option value="Whatsapp">Whatsapp</option>
                                    <option value="Offline">Offline</option>
                                    <option value="X">X</option>
                                    <option value=" Own"> Own</option>

                                </select>
                            </div>
                        </div>

                        <div className='field'>
                            <label className='label'>Campaign Name:</label>
                            <div className='control'>
                                <input style={{ borderRadius: '20px' }} className='input'
                                    type="text"
                                    id="campaignName"
                                    placeholder="Your Answer"
                                    value={editingCampaign.campaignName}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, campaignName: e.target.value })}
                                />
                            </div>
                        </div>

                        <div className='field'>
                            <label className='field'>Cover Image For The Campaign:</label>
                            <div className='control'>
                                <input style={{ borderRadius: '20px' }} className='input'
                                    type="text"
                                    id="uploadBrandLogo"
                                    value={editingCampaign.uploadBrandLogo}
                                    readOnly
                                    onChange={e => setEditingCampaign({ ...editingCampaign, uploadBrandLogo: e.target.value })}
                                />
                            </div>

                        </div>
                        <div>
                            <label className='label'>Upload the Cover Image Again:</label>
                            <input style={{ borderRadius: '20px' }}
                                type="file"
                                id="uploadBrandLogo"
                                name='uploadBrandLogo'
                                onChange={handleImageChange}
                            />
                        </div>

                        <div className='field'>
                            <label className='field'>Upload Campaign Brief:</label>
                            <div className='control'>
                                <input style={{ borderRadius: '20px' }} className='input'
                                    type="text"
                                    id="uploadBrief"
                                    value={editingCampaign.uploadBrief}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, uploadBrief: e.target.value })}
                                    readOnly
                                />
                            </div>

                        </div>
                        <div>
                            <label className='label'>Upload the Campaign Brief Again:</label>
                            <input style={{ borderRadius: '20px' }}
                                type="file"
                                id="uploadBrief"
                                name='uploadBrief'
                                onChange={handleReuploadBrief}
                            />
                        </div>

                        <div className='field'>
                            <label className='label'>Write location/city/state:</label>
                            <div className='control'>
                                <input
                                    style={{ borderRadius: '20px' }}
                                    className='input'
                                    type="text"
                                    id="location1"
                                    placeholder="Location"
                                    value={editingCampaign.location1}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, location1: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='field'>
                            <label className='label'>Any other eligibility criteria to select Influencers?:</label>
                            <div className='control'>
                                <textarea style={{ borderRadius: '20px' }} className='textarea'
                                    type="text"
                                    id="selectionCriteria"
                                    placeholder="Your Answer"
                                    value={editingCampaign.selectionCriteria}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, selectionCriteria: e.target.value })}
                                ></textarea>
                            </div>
                        </div>
                        <div className='field'>
                            <label className='label'>Any message for us?:</label>
                            <div className='control'>
                                <textarea style={{ borderRadius: '20px' }}
                                    className="textarea"
                                    type="text"
                                    id="message"
                                    placeholder="Your Answer"
                                    value={editingCampaign.message}
                                    onChange={e => setEditingCampaign({ ...editingCampaign, message: e.target.value })}
                                />
                            </div>
                        </div>


                        {/* <label className='label'>who's approvel required for influencer selection</label> */}

                        {/* <div className="field has-text-left">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="executive"
                                    checked={isexecutive}
                                    onChange={handleCheckboxChange}
                                />
                                Executive
                            </label>

                        </div>
                        <div className="field has-text-left">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="lead"
                                    checked={islead}
                                    onChange={handleCheckboxChange}
                                />
                                Lead
                            </label>
                        </div>
                        <div className="field has-text-left">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="admin"
                                    checked={isadmin}
                                    onChange={handleCheckboxChange}
                                />
                                Admin
                            </label>
                        </div>
                        <div className="field has-text-left">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="brand"
                                    checked={isbrand}
                                    onChange={handleCheckboxChange}
                                />
                                Brand
                            </label>
                        </div>

                        <label className='label'>Whose Approval Required For Influencer Content</label>
                        <div className="field">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="executiveContent"
                                    checked={isexecutiveContent}
                                    onChange={handleCheckboxChange1}
                                />
                                Executive
                            </label>
                        </div>
                        <div className="field">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="leadContent"
                                    checked={isleadContent}
                                    onChange={handleCheckboxChange1}
                                />
                                Lead
                            </label>
                        </div>
                        <div className="field">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="adminContent"
                                    checked={isadminContent}
                                    onChange={handleCheckboxChange1}
                                />
                                Admin
                            </label>
                        </div>

                        <div className="field">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="brandContent"
                                    checked={isbrandContent}
                                    onChange={handleCheckboxChange1}
                                />
                                Brand
                            </label>
                        </div> */}

                        {/* Add similar input fields for other campaign attributes */}

                        {/* Buttons for updating and canceling */}
                        <div className='field is-grouped '>
                            <div className='control'>
                                <button
                                    onClick={handleUpdate}
                                    className={`button is-small is-rounded ${isViewMoreHovered ? 'is-primary' : ''}`} style={{marginLeft:"21px"}}
                                    onMouseEnter={() => setIsViewMoreHovered(true)}
                                    onMouseLeave={() => setIsViewMoreHovered(false)}
                                >
                                    Update
                                </button>
                            </div>
                            <div className='control'>
                                {/* <button onClick={handleCancelEdit}>Cancel</button> */}
                                <button
                                    onClick={handleCancelEdit}
                                    className={`button is-small is-rounded ${isViewCampaignHovered ? 'is-primary' : ''}`}
                                    onMouseEnter={() => setIsViewCampaignHovered(true)}
                                    onMouseLeave={() => setIsViewCampaignHovered(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }

        </div>

    )
}

export default ViewCampaignBrand; 