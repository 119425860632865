// api.js
import { Config } from '../.env.js';
// Define the URL of your backend API endpoint
const API_URL = `${Config.API_URL}/submit`; // Update this with your actual API URL

// Function to fetch brand campaigns from the backend
export const getInfluencerCampaigns = async () => {
    try {
        // Make a GET request to the backend API endpoint that returns brand campaigns
        const response = await fetch(`${API_URL}/influencer-campaigns`);

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to fetch influencer campaigns: ${error.message}`);
    }
};
export const approveCampaignExecutive = async (campaignId) => {
    try {
        // Make a POST request to the backend API endpoint to approve the campaign
        const response = await fetch(`${API_URL}/influencer-campaigns/${campaignId}/approvedexecutive`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to approve campaign: ${error.message}`);
    }
};

export const approveCampaignLead = async (campaignId) => {
    try {
        // Make a POST request to the backend API endpoint to approve the campaign
        const response = await fetch(`${API_URL}/influencer-campaigns/${campaignId}/approvedlead`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to approve campaign: ${error.message}`);
    }
};

export const approveCampaignAdmin = async (campaignId) => {
    try {
        // Make a POST request to the backend API endpoint to approve the campaign
        const response = await fetch(`${API_URL}/influencer-campaigns/${campaignId}/approvedadmin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to approve campaign: ${error.message}`);
    }
};

export const approveCampaignBrand = async (campaignId) => {
    try {
        // Make a POST request to the backend API endpoint to approve the campaign
        const response = await fetch(`${API_URL}/influencer-campaigns/${campaignId}/approvedbrand`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to approve campaign: ${error.message}`);
    }
};

export const rejectCampaignBrand = async (campaignId, remarks) => {
    try {
        // Make a POST request to the backend endpoint
        const response = await fetch(`${Config.API_URL}/reject-campaign`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                campaignId: campaignId,
                remarks: remarks
            })
        });

        // Check if the response is successful
        if (!response.ok) {
            // If response is not OK, throw an error with the status text
            throw new Error(`Failed to reject campaign: ${response.statusText}`);
        }

        // Extract and return the response data
        return await response.json();
    } catch (error) {
        // Handle any errors that occur during the request
        throw new Error(`Failed to reject campaign: ${error.message}`);
    }
};


// for InfDeliverables form create api

export const approvedExecutive = async (influencerId) => {
    try {
        // Make a POST request to the backend API endpoint to approve the campaign
        const response = await fetch(`${API_URL}/influencer-campaigns/${influencerId}/approvedbyexecutive`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to approve campaign: ${error.message}`);
    }
};

export const approvedLead = async (influencerId) => {
    try {
        // Make a POST request to the backend API endpoint to approve the campaign
        const response = await fetch(`${API_URL}/influencer-campaigns/${influencerId}/approvedbylead`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to approve campaign: ${error.message}`);
    }
};

export const approvedAdmin = async (influencerId) => {
    try {
        // Make a POST request to the backend API endpoint to approve the campaign
        const response = await fetch(`${API_URL}/influencer-campaigns/${influencerId}/approvedbyadmin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to approve campaign: ${error.message}`);
    }
};

export const approvedBrand = async (influencerId) => {
    try {
        // Make a POST request to the backend API endpoint to approve the campaign
        const response = await fetch(`${API_URL}/influencer-campaigns/${influencerId}/approvedbybrand`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to approve campaign: ${error.message}`);
    }
};

//  for Influencer conformation page
export const approvelExecutive = async (influencerId) => {
    try {
        // Make a POST request to the backend API endpoint to approve the campaign
        const response = await fetch(`${API_URL}/influencer-campaigns/${influencerId}/approvelbyexecutive`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to approve campaign: ${error.message}`);
    }
};

export const approvelLead = async (influencerId) => {
    try {
        // Make a POST request to the backend API endpoint to approve the campaign
        const response = await fetch(`${API_URL}/influencer-campaigns/${influencerId}/approvelbylead`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to approve campaign: ${error.message}`);
    }
};

export const approvelAdmin = async (influencerId) => {
    try {
        // Make a POST request to the backend API endpoint to approve the campaign
        const response = await fetch(`${API_URL}/influencer-campaigns/${influencerId}/approvelbyadmin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to approve campaign: ${error.message}`);
    }
};

export const approvelBrand = async (influencerId) => {
    try {
        // Make a POST request to the backend API endpoint to approve the campaign
        const response = await fetch(`${API_URL}/influencer-campaigns/${influencerId}/approvelbybrand`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to approve campaign: ${error.message}`);
    }
};


// for brand Application
export const rejectedCampaignBrand = async (campaignId, remarks) => {
    try {
        // Make a POST request to the backend endpoint
        const response = await fetch(`${Config.API_URL}/rejected-campaign`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                campaignId: campaignId,
                remarks: remarks
            })
        });

        // Check if the response is successful
        if (!response.ok) {
            // If response is not OK, throw an error with the status text
            throw new Error(`Failed to reject campaign: ${response.statusText}`);
        }

        // Extract and return the response data
        return await response.json();
    } catch (error) {
        // Handle any errors that occur during the request
        throw new Error(`Failed to reject campaign: ${error.message}`);
    }
};

export const approve = async (campaignId ) => {
    try {
        // Make a POST request to the backend API endpoint to approve the campaign
        const response = await fetch(`${API_URL}/influencer-campaigns/${campaignId }/approve`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
            // If successful, return the response
            return response;
        } else {
            // If not successful, throw an error with the response status text
            throw new Error(response.statusText);
        }
    } catch (error) {
        // If an error occurs during the fetch operation, throw an error with the error message
        throw new Error(`Failed to approve campaign: ${error.message}`);
    }
};