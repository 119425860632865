import React, { useState } from 'react'
import { CSVLink } from 'react-csv';
import AppicationPage from '../pages/AppicationPage';
import BrandDashboard2 from '../pages/brandTable';
import BrandDashboard1 from '../pages/influencerTable';
// import BrandPage from '../pages/BrandForm';
import BrandFormInternal from '../pages/BrandFormInternal';
import InfluencerDeliverblesTable from '../pages/infDeliverables';
// import InfluencerconfirmationPage from '../pages/confirmationPage';
import InfluencerConformationTable from '../pages/infConfTable';
import BrandTable1 from '../pages/brandTable1';
import ApproveCampaignPageInternal from '../pages/ApprovecampaignInternal';
const InternalTeamDashboard = () => {

    // menu visiblity set
    const [menuVisible, setMenuVisible] = useState(false);

    const handleToggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    //create new campaign 



    const [PhoneNumber, setPhoneNumber] = useState("");
    // const [WhatsappNumber, setWhatsappNumber] = useState("");
    // const [UserName, setUserName] = useState("")





    //mobile number validation regx




    //const [MobileNumber, setmobilenumber] = useState("");
    const [Email, setemail] = useState();
    const [MobileNumber, setmobilenumber] = useState("");
    //mobile number validation regx
    let hasValNumber = /^[0-9]{1,10}$/.test(PhoneNumber || MobileNumber);

    //email validatin regx
    let hasValEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(Email);


    // create new campaignForm
    const [showCreateCampaignForm, setShowCreateCampaignForm] = useState(false);
    // const [selectedCampaign, setSelectedCampaign] = useState(null);
    const handleCreateNewCampaignClick = () => {
        setShowCreateCampaignForm(true);
        setShowUserRoles(false);
        setShowInfluencerApplications(false);
        setshowShortlistedInfluencers(false);
    };

    // Influencer Applications 
    const [showInfluencerApplications, setShowInfluencerApplications] = useState(false);
    const handleInfluencerApplicationsClick = () => {
        setShowInfluencerApplications(true);
        setShowCreateCampaignForm(false);
        setshowShortlistedInfluencers(false);
        setShowUserRoles(false);
    }
    const [showBrandApplications, setShowBrandApplications] = useState(false);
    const handleBrandApplicationsClick = () => {
        setShowBrandApplications(true);
        setShowCreateCampaignForm(false);
        setshowShortlistedInfluencers(false);
        setShowUserRoles(false);
    }


    // Shortlisted Influencers
    const [showShortlistedInfluencers, setshowShortlistedInfluencers] = useState(false);
    const handleShortlistedInfluencersClick = () => {
        setshowShortlistedInfluencers(true);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setShowUserRoles(false);
    }

    const [showDeliverables, setshowDeliverables] = useState(false);
    const handleDeliverbralesClick = () => {
        setshowDeliverables(true);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setShowUserRoles(false);
    }
    const [showConformation, setshowConformation] = useState(false);
    const handleConformationClick = () => {
        setshowConformation(true);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
    }

    const [selectedOption, setSelectedOption] = useState('');
    const [showC1Table, setShowC1Table] = useState(false);
    const [showC2Table, setShowC2Table] = useState(false);
    const [showC3Table, setShowC3Table] = useState(false);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);

        switch (option) {
            case 'option1':
                setShowC1Table(true);
                setShowC2Table(false);
                setShowC3Table(false);
                break;
            case 'option2':
                setShowC1Table(false);
                setShowC2Table(true);
                setShowC3Table(false);
                break;
            case 'option3':
                setShowC1Table(false);
                setShowC2Table(false);
                setShowC3Table(true);
                break;
            default:
                setShowC1Table(false);
                setShowC2Table(false);
                setShowC3Table(false);
        }
    };

    const handleC1Click = () => {
        console.log('C1 clicked');
        handleOptionSelect('option1');
    };

    const handleC2Click = () => {
        console.log('C2 clicked');
        handleOptionSelect('option2');
    };

    const handleC3Click = () => {
        console.log('C3 clicked');
        handleOptionSelect('option3');
    };

    // table
    // Sample table data
    const tableData = [
        ["Data 1", "Data 2", "data3"],

        // Add more rows and data
    ];

    // Function to generate CSV data from the tableData
    const generateCSVData = () => {
        const headers = Array.from(document.querySelectorAll('.table th')).map(th => th.innerText);// Add more headers if needed
        const csvData = [headers, ...tableData];
        return csvData;
    };
    // status set 
    const statusOptions = ["Selected", "Rejected", "On Hold"];
    // add shipment
    // const [selectedRows, setSelectedRows] = useState([]);
    const [shipmentStatus, setShipmentStatus] = useState({});

    const handleCheckboxChange = (index, status) => {
        setShipmentStatus((prevShipmentStatus) => ({
            ...prevShipmentStatus,
            [index]: status,
        }))
    };

    // Add Tracking ID
    const [trackingIds, setTrackingIds] = useState({});
    const handleTrackingIdChange = (index, trackingId) => {
        setTrackingIds((prevTrackingIds) => ({
            ...prevTrackingIds,
            [index]: trackingId,
        }));
    };

    // Handler for status change
    const handleStatusChange = (index, selectedStatus) => {
        // Add logic to handle the status change (e.g., update the data or make an API call)
        console.log(`Status changed for row ${index + 1}: ${selectedStatus}`);
    };

    // Option to approve deliverables
    const handleApproval = (index) => {
        // Add logic to handle the approval ( update the data or make an API call)
        console.log(`Deliverables approved for row ${index + 1}`);
    };
    // filters

    const [filterStatus, setFilterStatus] = useState('all');
    const data = generateCSVData();
    const filteredData = data.filter(item => {
        if (filterStatus === 'all') return true;
        return item.status === filterStatus; // Replace 'status' with the actual property that indicates the status
    });

    const [showUserRoles, setShowUserRoles] = useState(false);


    // Handle User Roles link click
    const handleUserRolesClick = () => {
        setShowUserRoles(true);

    };
    //invite form  
    const [showInviteForm, setShowInviteForm] = useState(false);
    const handleInviteTeamMembersClick = () => {
        setShowInviteForm(true);
        setShowUserRoles(false);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setshowShortlistedInfluencers(false);
    };

    const [showAllCampaign, setShowAllCampaign] = useState(false);
    const handleShowAllCampignClick = () => {
        setShowAllCampaign(true);  // Set the state to show the "Approved Campaigns" page
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setshowShortlistedInfluencers(false);
    };


    return (
        <div className="columns ">
            {/* Sidebar */}
            <button className={`button is-hidden-desktop ${menuVisible ? 'is-active' : ''}`} onClick={handleToggleMenu}>
                <span className="icon">
                    <i className="fas fa-ellipsis-v"></i>
                </span>
            </button>

            <div className="column is-one-fifth">


                <div className={menuVisible ? '' : 'is-hidden-mobile'}>
                    <div className='box my-5'>
                        <aside className="menu mt-5">

                            <p className="menu-label"> Internal Team Dashboard</p>
                            <ul className="menu-list">


                                {/* create campaingn form */}
                                < li className="ml-2 " onClick={handleCreateNewCampaignClick}>
                                    <span className="icon is-small has-text-success">
                                        <i className="fas fa-plus"></i>
                                    </span>
                                    Create new Campaign
                                </li>
                                <div className="control ">
                                    <li className="ml-2 my-2" onClick={handleShowAllCampignClick}>
                                        <span className="icon is-small has-text-success mr-1">
                                            <i className="fas fa-check-circle"></i>
                                        </span>
                                        All Campaigns
                                    </li>
                                    <div />

                                    <div className="select ml-4 ">
                                        <select value={selectedOption} onChange={(e) => handleOptionSelect(e.target.value)}>

                                            <option value="">select</option>
                                            <option onClick={handleC1Click} value="option1">C1</option>
                                            <option onClick={handleC2Click} value="option2">C2</option>
                                            <option onClick={handleC3Click} value="option3">C3</option>
                                        </select>
                                    </div>

                                </div>
                                <li className="ml-2 my-2 " onClick={handleInfluencerApplicationsClick}>
                                    <span className="icon is-small has-text-success mr-1">
                                        <i className="fas fa-users"></i>
                                    </span>
                                    Influencer Applications

                                </li>
                                <li className="ml-2 my-2 " onClick={handleBrandApplicationsClick}>
                                    <span className="icon is-small has-text-success mr-1">
                                        <i className="fas fa-users"></i>
                                    </span>
                                    Brand Application

                                </li>

                                <li className="ml-2 my-2" onClick={handleShortlistedInfluencersClick}>
                                    <span className="icon is-small has-text-success mr-1">
                                        <i className="fas fa-check-circle"></i>
                                    </span>
                                    Shortlisted Influencers

                                </li>
                                <li className="ml-2 my-2" onClick={handleDeliverbralesClick}>
                                    <span className="icon is-small has-text-success mr-1">
                                        <i className="fas fa-check-circle"></i>
                                    </span>
                                    Influencer Deliverables

                                </li>
                                <li className="ml-2 my-2" onClick={handleConformationClick}>
                                    <span className="icon is-small has-text-success mr-1">
                                        <i className="fas fa-check-circle"></i>
                                    </span>
                                    Influencer Conformation
                                </li>
                                <li className="is-size-6 ml-2 " style={{ marginTop: "11.2rem" }} onClick={handleUserRolesClick}>
                                    <span className="icon is-small has-text-info">
                                        <i className="fas fa-users mr-3"></i>
                                    </span>
                                    User Roles
                                </li>

                                <div className=''>
                                    <li className=" is-size-6 ml-2 mt-2 " >
                                        <span className="icon is-small has-text-grey">
                                            <i className="fas fa-cog mr-3"></i>
                                        </span>
                                        Settings
                                    </li>
                                </div>

                            </ul>
                        </aside>
                    </div>

                </div >
            </div >


            {/* Main content */}
            < div className="column" >
                <div className="columns is-ancestor">
                    <div className="column  mt-5">

                        {showCreateCampaignForm ? (
                            // Render the Create Campaign form
                            <div className='is-child box has-text-centered' style={{ maxHeight: '550px', overflowX: 'auto' }}>
                                <h1 className='subtitle mt-5'>Create New Campaign</h1>
                                <BrandFormInternal />
                            </div>
                        ) : showAllCampaign ? (

                            <div className='subtitle' style={{ fontSize: '15px' }}>
                                <ApproveCampaignPageInternal />
                            </div>
                        ) : showC1Table ? (
                            <div className='is-child box' style={{ overflowX: 'auto', maxHeight: '500px' }}>
                                {/* filters */}
                                <div className="filter-buttons my-4 ml-2">
                                    <button className="mr-2" onClick={() => setFilterStatus('all')}>All</button>
                                    <button className="mr-2" onClick={() => setFilterStatus('dispatch')}>Pending for Dispatch</button>
                                    <button className="mr-2" onClick={() => setFilterStatus('selection')}>Pending for Selection</button>
                                    <button className="mr-2" onClick={() => setFilterStatus('approval')}>Pending for Approval</button>
                                </div>

                                <div className='subtitle '>
                                    <h1>c1</h1>

                                </div>
                                {/*  C1 Table code */}
                                {/* ... */}
                            </div>


                        ) : showC2Table ? (
                            <div className='is-child box' style={{ overflowX: 'auto', maxHeight: '500px' }}>
                                {/* filters */}
                                <div className="filter-buttons my-4 ml-2">
                                    <button className="mr-2" onClick={() => setFilterStatus('all')}>All</button>
                                    <button className="mr-2" onClick={() => setFilterStatus('dispatch')}>Pending for Dispatch</button>
                                    <button className="mr-2" onClick={() => setFilterStatus('selection')}>Pending for Selection</button>
                                    <button className="mr-2" onClick={() => setFilterStatus('approval')}>Pending for Approval</button>
                                </div>
                                <div className='subtitle'>
                                    <h1>c2</h1>
                                    {/* c2 table */}
                                </div>
                            </div>
                        ) : showC3Table ? (
                            <div className='is-child box' style={{ overflowX: 'auto', maxHeight: '500px' }}>
                                {/* filters */}
                                <div className="filter-buttons my-4 ml-2">
                                    <button className="mr-2" onClick={() => setFilterStatus('all')}>All</button>
                                    <button className="mr-2" onClick={() => setFilterStatus('dispatch')}>Pending for Dispatch</button>
                                    <button className="mr-2" onClick={() => setFilterStatus('selection')}>Pending for Selection</button>
                                    <button className="mr-2" onClick={() => setFilterStatus('approval')}>Pending for Approval</button>
                                </div>
                                <div className='subtitle'>
                                    <h1>C3</h1>
                                    {/* c3 table */}
                                </div>
                            </div>
                            //1o651InfluencerApplications
                        ) : showInfluencerApplications ? (
                            <div className='is-child box' style={{ overflowX: 'auto', overflowY: 'auto', width: '1060px' }}>
                                {/* filters */}
                                <div className="filter-buttons my-4 mr-3">
                                    <button className="mr-2" onClick={() => setFilterStatus('all')}>All</button>
                                    <button className='mr-2' onClick={() => setFilterStatus('Pending Deliverables')}>Pending Deliverables</button>
                                </div>
                                <div className='subtitle' style={{ fontSize: '15px' }}>
                                    <h1 className='underline'>Influencer Applications</h1>
                                    <BrandDashboard1 />
                                </div>

                            </div>
                        ) : showBrandApplications ? (
                            <div className='is-child box' style={{ overflowX: 'auto', overflowY: 'auto', width: '1060px' }}>
                                {/* filters */}
                                <div className="filter-buttons my-4 mr-3">
                                    <button className="mr-2" onClick={() => setFilterStatus('all')}>All</button>
                                    <button className='mr-2' onClick={() => setFilterStatus('Pending Deliverables')}>Pending Deliverables</button>
                                </div>
                                <div className='subtitle' style={{ fontSize: '15px' }}>
                                    <h1 className='underline'>Brand Application</h1>
                                    {/* <BrandDashboard2 /> */}
                                    <BrandTable1/>
                                </div>

                            </div>

                        ) : showShortlistedInfluencers ? (
                            <div className='is-child box has-text-centered' style={{ maxHeight: '550px', overflowX: 'auto' }}>
                                <h1 className='subtitle mt-5 underline'>Shortlisted Influencers
                                </h1>
                                <div></div>
                            </div>
                        ) : showDeliverables ? (
                            <div className='is-child box has-text-centered' style={{ maxHeight: '550px', overflowX: 'auto' }}>
                                <h1 className='subtitle mt-5 underline'>
                                </h1>
                                <InfluencerDeliverblesTable />
                                <div></div>
                            </div>
                            // showuserRoles
                        ) : showConformation ? (
                            <div className='is-child box has-text-centered' style={{ maxHeight: '550px', overflowX: 'auto' }}>
                                <h1 className='subtitle mt-5 underline'>
                                </h1>
                                <InfluencerConformationTable />
                                <div>Influencer Conformation</div>
                            </div>
                            // showuserRoles
                        ) : showUserRoles ? (
                            <div className='is-child box has-text-centered'>
                                <div className='field'>
                                    <div className="control my-3 ">
                                        <button className="button is-primary is-rounded  is-focused is-medium is-fullwidth " onClick={handleInviteTeamMembersClick} >
                                            Invite Team Mambers
                                        </button>

                                    </div>
                                </div>
                                <h1 className='subtitle mt-5'>Choose Campaigns
                                </h1>
                                {/* Add your User Roles content here */}

                                <div className='form'>
                                    <div className='field'>
                                        <label className="C1 mr-5">
                                            <input type="radio" name="campaigns" className='is-rounded mr-2'
                                            /> C-1
                                        </label>
                                        <label className="C2 ml-5">
                                            <input type="radio" name="campaigns" className='is-rounded mr-2'
                                            /> C-2
                                        </label>
                                    </div>
                                    <div className='field'>
                                        <label className="C-3 mr-5">
                                            <input type="radio" name="campaigns" className='is-rounded mr-2'
                                            /> C-3
                                        </label>
                                        <label className="C4 ml-5">
                                            <input type="radio" name="campaigns" className='is-rounded mr-2'
                                            /> C-4
                                        </label>

                                    </div>
                                </div>


                            </div>
                        ) : showInviteForm ? (
                            // Display the invite form
                            <div className='is-child box has-text-centered'>
                                <h1 className='subtitle mt-5'>Invite form campaign
                                </h1>
                                <div className='form'>

                                    <div className='field '>
                                        <div className="control has-icons-left">
                                            <div className='colomn'>
                                                <input className="input is-rounded "
                                                    type="text"
                                                    placeholder="Email ID"
                                                    value={Email}
                                                    onChange={(e) => setemail(e.target.value)}
                                                />
                                                {Email && !hasValEmail && (
                                                    <small
                                                        className={`ml-1 mt-1  ${!hasValEmail && Email !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                                        {!hasValEmail && Email !== '' ? ('Enter a valid email address') : ''}
                                                    </small>
                                                )}
                                                <span className="icon is-small is-left">
                                                    <i className="fas fa-envelope"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* mobile number */}
                                    <div className='field '>
                                        <div className="control has-icons-left ">
                                            <div className='colomn'>
                                                <input className="input  is-rounded "
                                                    type="text"
                                                    placeholder="Mobile Number"
                                                    value={MobileNumber}
                                                    onChange={(e) => setmobilenumber(e.target.value)}
                                                />

                                                {/* mobile number validation */}
                                                {MobileNumber && !hasValNumber && (
                                                    <div>
                                                        <small
                                                            className={`ml-1 mt-1  ${!hasValNumber && MobileNumber !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                                            {!hasValNumber && MobileNumber !== '' ? 'Please Enter valid Mobile number' : ''}
                                                        </small>
                                                    </div>
                                                )}

                                                <span className='icon is-small is-left'>
                                                    <i className='fas fa-phone'></i>
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="field">
                                        <div className="control">

                                            <button className="button is-primary is-rounded  is-focused is-medium is-fullwidth " disabled={!MobileNumber || !Email || !hasValNumber || !hasValEmail}>
                                                SIGN UP
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        ) : (
                            <div className="is-child box " style={{ overflowX: 'auto', width: "1000px", maxHeight: '500px' }} >
                                {/* filter buttons */}
                                <div className="filter-buttons my-4 ml-2">
                                    <button className="mr-2" onClick={() => setFilterStatus('all')}>All</button>
                                    <button className="mr-2" onClick={() => setFilterStatus('dispatch')}>Pending for Dispatch</button>
                                    <button className="mr-2" onClick={() => setFilterStatus('selection')}>Pending for Selection</button>
                                    <button className="mr-2" onClick={() => setFilterStatus('approval')}>Pending for Approval</button>

                                </div>
                                {/* Your fixed table component goes here */}
                                <h1 className='subtitle'>Influencer/Campaign Table
                                </h1>

                                <table className="table is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Username</th>
                                            <th>User ID</th>
                                            <th>other</th>
                                            <th >status</th>
                                            <th>Shipment Status</th>
                                            <th>Tracking ID</th>
                                            <th >Approval</th>

                                            {/* Add more headers  */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item}</td>
                                                <td>{item}</td>
                                                <td>{item}</td>
                                                <td>{item}</td>
                                                <td>
                                                    <div className="select">
                                                        <select onChange={(e) => handleStatusChange(index, e.target.value)}>
                                                            {statusOptions.map((status, statusIndex) => (
                                                                <option key={statusIndex} value={status}>
                                                                    {status}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <label>
                                                        <input
                                                            type="checkbox" className='ml-3'
                                                            checked={shipmentStatus[index] === "Shipped"}
                                                            onChange={() => handleCheckboxChange(index, "Shipped")}
                                                        />
                                                        Shipped
                                                    </label>
                                                    <label>

                                                        <input
                                                            type="checkbox" className='ml-3'
                                                            checked={shipmentStatus[index] === "Delivered"}
                                                            onChange={() => handleCheckboxChange(index, "Delivered")}
                                                        />

                                                        Delivered
                                                    </label>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        placeholder='Enter TrackingId '
                                                        value={trackingIds[index] || ''}
                                                        onChange={(e) => handleTrackingIdChange(index, e.target.value)}
                                                    />
                                                </td>
                                                {/* Add more columns based on your data structure */}
                                                <td >
                                                    <button className="button" onClick={() => handleApproval(index)}>Approve</button>
                                                    {/* You can customize the approval button as needed */}
                                                </td>
                                                <td>

                                                </td>
                                                {/* Add more columns based on your data structure */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <CSVLink
                                    data={generateCSVData()}
                                    filename={"Campaign_table_data.csv"}
                                    className="button is-primary my-2 is-rounded"
                                    separator=","
                                >
                                    Download CSV
                                </CSVLink>
                            </div>)}
                    </div>
                </div>
            </ div>

        </div >
    )
}

export default InternalTeamDashboard
